import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  loadModules,
  setDefaultOptions,
  loadCss,
  loadScript,
} from 'esri-loader';
import { onError } from 'utils/handlers';
import { createEmptyAxiosInstance } from 'utils/api';
import { logger } from 'utils/debug';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Col,
  Row,
  Space,
} from 'antd';
import { CameraOutlined, LinkOutlined } from '@ant-design/icons';

const { Title, Text, Link } = Typography;

const HTTP = createEmptyAxiosInstance();

setDefaultOptions({ css: true });
loadCss('4.25');
loadScript({ version: '4.25' });

const coloniasConfig = {
  url: 'https://services7.arcgis.com/Xmm2kRiMsztfCefg/arcgis/rest/services/Cd_Juarez/FeatureServer/1',
  title: 'Colonias',
};

const municipiosConfig = {
  url: 'https://services7.arcgis.com/Xmm2kRiMsztfCefg/arcgis/rest/services/Cd_Juarez/FeatureServer/3',
  title: 'Municipios',
};

const prediosRender = {
  type: 'simple',
  symbol: {
    type: 'simple-fill',
    color: [0, 0, 255, 0.1],
    outline: {
      width: 0.5,
      color: 'white',
    },
  },
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
  return result ? result.splice(1).map((e) => parseInt(e, 16)).filter((e) => e) : null;
};

const prediosConfig = {
  url: 'https://services7.arcgis.com/Xmm2kRiMsztfCefg/arcgis/rest/services/pract/FeatureServer/0',
  title: 'Predios',
  renderer: prediosRender,
  visible: true,
};

const generateQuery = (
  clave_catastral,
  format = 'geojson',
) => {
  if (clave_catastral) {
    return `${prediosConfig.url}/query?where=(CLAVECATA%20LIKE%20%27${clave_catastral}%25%27)&f=${format}`;
  }
  return `${prediosConfig.url}/query?f=${format}`;
};

const getModules = async () => {
  try {
    const modules = await loadModules([
      'esri/views/MapView',
      'esri/Map',
      'esri/layers/FeatureLayer',
      'esri/widgets/LayerList',
      'esri/layers/GraphicsLayer',
      'esri/Graphic',
      'esri/widgets/Fullscreen',
    ]);
    return [null, modules];
  } catch {
    return ['Ha ocurrido un error al cargar los módulos', null];
  }
};

const validate = async (clave_catastral) => {
  try {
    const response = await HTTP.get(`${generateQuery(clave_catastral)}&returnUniqueIdsOnly=true`);
    if (response.status === 200 && response.data.uniqueIds?.length >= 1) {
      return [true, response.data.uniqueIds[0]];
    }
  } catch (error) {
    logger(error);
  }
  return [false];
};

const getAttributes = async (uniqueIds) => {
  try {
    const params = '&outFields=*&returnGeometry=false';
    const response = await HTTP
      .get(generateQuery(null, 'json').concat(params).concat(`&objectIds=${uniqueIds}`));
    if (response.status === 200) {
      const attributes = response.data?.features[0]?.attributes;
      if (attributes) {
        return attributes;
      }
    }
  } catch (error) {
    logger('error', error);
  }
  return null;
};

const getRings = async (clave_catastral) => {
  try {
    const [isValid, uniqueIds] = await validate(clave_catastral);
    if (isValid) {
      const response = await HTTP.get(generateQuery(clave_catastral));
      if (response.status === 200) {
        const rings = response.data.features?.[0]?.geometry?.coordinates;
        const attributes = await getAttributes(uniqueIds);
        if (rings && attributes) {
          return [null, rings, attributes];
        }
      }
    }
  } catch (error) {
    logger('error', error);
  }
  return [`No fue posible encontrar las coordenadas para clave catastral "${clave_catastral}"`, null];
};

const ArcGisMap = ({
  clave_catastral_municipal: _clave_catastral,
  setLoading,
  paso,
  callback,
}) => {
  const theme = useSelector(({ app }) => app.theme);
  const [error, setError] = useState();
  const [attributes, setAttributes] = useState();
  const takeScreenshotButtonRef = useRef();

  const idxOfHypen = _clave_catastral.indexOf('-');
  const placesToRemove = idxOfHypen !== -1 ? idxOfHypen + 1 : 0;
  const clave_catastral = _clave_catastral.substring(placesToRemove).replace(/-/g, '');

  const loadMap = async () => {
    const [modulesError, modules] = await getModules();
    if (modulesError) {
      throw new Error(modulesError);
    }
    const [
      MapView,
      Map,
      FeatureLayer,
      LayerList,
      GraphicsLayer,
      Graphic,
      Fullscreen,
    ] = modules;

    const [ringsError, rings, _attributes] = await getRings(clave_catastral);
    if (ringsError) {
      throw new Error(ringsError);
    }
    const map = new Map({ basemap: 'hybrid' });
    const lats = rings[0].map((e) => e[0]);
    const longs = rings[0].map((e) => e[1]);
    const latMax = Math.max(...lats);
    const latMin = Math.min(...lats);
    const latDiff = latMax - latMin;
    const longMax = Math.max(...longs);
    const longMin = Math.min(...longs);
    const longDiff = longMax - longMin;
    const lat = latMax - (latDiff / 2);
    const long = longMax - (longDiff / 2);
    const view = new MapView({
      container: 'mapDiv',
      map,
      center: [lat, long],
      zoom: 19,
      constraints: {
        minZoom: 16,
        maxZoom: 21,
      },
    });

    // Preventing drag
    view.on('drag', (event) => {
      event.stopPropagation();
    });
    view.on('drag', ['Shift'], (event) => {
      event.stopPropagation();
    });

    view.on('drag', ['Shift', 'Control'], (event) => {
      event.stopPropagation();
    });

    // Preventing zoom
    view.on('key-down', (event) => {
      const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
      const keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
    });
    view.on('mouse-wheel', (event) => {
      event.stopPropagation();
    });
    view.on('double-click', (event) => {
      event.stopPropagation();
    });
    view.on('double-click', ['Control'], (event) => {
      event.stopPropagation();
    });

    // Layers
    const municipios = new FeatureLayer(municipiosConfig);
    const colonias = new FeatureLayer(coloniasConfig);
    const predios = new FeatureLayer(prediosConfig);
    await municipios.load();
    await colonias.load();
    await predios.load();

    map.addMany([municipios, colonias, predios]);

    const listaCapas = new LayerList({ view });
    const fullscreen = new Fullscreen({ view });
    view.ui.add(listaCapas, 'top-right');
    view.ui.add(fullscreen, 'top-left');

    // Polygon para predio de clave catastral brindada
    const graphicsLayer = new GraphicsLayer();
    map.add(graphicsLayer);
    const polygon = { type: 'polygon', rings };

    const color = hexToRgb(theme.activeBackgroundColor);
    const simpleFillSymbol = {
      type: 'simple-fill',
      color: color.length !== 3 ? color.concat(100) : color,
      outline: {
        color: [255, 255, 255],
        width: 1,
      },
    };

    const polygonGraphic = new Graphic({
      geometry: polygon,
      symbol: simpleFillSymbol,
    });

    const polygonGraphicSS = new Graphic({
      geometry: polygon,
      symbol: {
        ...simpleFillSymbol,
        color: [120, 120, 120],
      },
    });

    graphicsLayer.add(polygonGraphic);

    const onClick = async () => {
      setLoading(true);
      const options = {
        // width: 400,
        // height: 350,
        ignoreBackground: true,
        quality: 100,
        format: 'png',
      };
      const screenshot = await view.takeScreenshot(options);
      if (screenshot?.dataUrl) {
        const w = window.open('about:blank');
        if (w) {
          setTimeout(() => {
            w.document.body.innerHTML = `
              <h2>
                La siguiente imagen deberá aparecer en el documento emitido por el trámite
              </h2>
              <span>
                Si hay inconsistencias notables en la imagen tome la captura de nuevo
              </span>
              <br />
              <span>
                Mantenga esta pestaña abierta, continue con el trámite y anexe la imagen al documento
              </span>
              <br />
              <br />
              <img src="${screenshot.dataUrl}" />
            `;
            const css = w.document.createElement('style');
            const styles = `
              body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 30px;
              }
              img {
                width: 70%;
              }
            `;
            if (css.styleSheet) {
              css.styleSheet.cssText = styles;
            } else {
              css.appendChild(document.createTextNode(styles));
            }
            w.document.getElementsByTagName('head')[0].append(css);
          });
          setTimeout(() => {
            graphicsLayer.remove(polygonGraphicSS);
            graphicsLayer.add(polygonGraphic);
            map.basemap = 'hybrid';
          }, 3000);
          callback({
            usosuelo: _attributes.Nombre_USO,
            claveus: _attributes.Clave_USO,
            descripcionus: _attributes.descripcio,
            base64_croquis: screenshot?.dataUrl,
          });
        }
      }
      setLoading(false);
    };

    const handleClick = () => {
      map.basemap = 'gray-vector';
      graphicsLayer.remove(polygonGraphic);
      graphicsLayer.add(polygonGraphicSS);
      setTimeout(onClick, 2000);
    };
    if (paso) {
      takeScreenshotButtonRef.current.addEventListener('click', handleClick);
    }
    view.when(() => {
      setError();
      setLoading(false);
      setAttributes(_attributes);
    });
  };

  useEffect(() => {
    const tryLoadMap = async () => {
      setLoading(true);
      try {
        if (clave_catastral) {
          await loadMap();
        } else {
          setError('Clave catastral no brindada');
        }
      } catch (err) {
        onError(err);
        setError(err.message);
        setLoading(false);
      }
    };
    tryLoadMap(clave_catastral);
  }, [clave_catastral]);

  if (error) {
    return error;
  }

  return (
    <>
      {paso?.instrucciones && (
        <Col span={24}>
          <Title level={3}>
            Instrucciones:
          </Title>
          <Text>
            {paso?.instrucciones}
          </Text>
        </Col>
      )}
      <Row style={{ width: '100%', marginTop: 15 }} gutter={[10, 10]}>
        {!!attributes && (
          <>
            <Col span={24}>
              <Title level={3}>
                Información del predio:
              </Title>
            </Col>
            <Col span={8}>
              <Space>
                <Text>
                  CLAVE CATASTRAL:
                </Text>
                <Text>
                  {attributes.CLAVECATA}
                </Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text>
                  USO DE SUELO:
                </Text>
                <Text>
                  {attributes.Nombre_USO}
                </Text>
              </Space>
            </Col>
            <Col span={8} />
            <Col span={8}>
              <Space>
                <Text>
                  CLAVE USO DE SUELO:
                </Text>
                <Text>
                  {attributes.Clave_USO}
                </Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text>
                  LIGA PDF USO:
                </Text>
                {attributes.url_uso ? (
                  <Link href={attributes.url_uso} target="_blank">
                    Visitar
                    <LinkOutlined />
                  </Link>
                ) : (
                  <Text>
                    Sin liga
                  </Text>
                )}
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <Text>
                  DESCRIPCIÓN USO DE SUELO:
                </Text>
                <Text>
                  {attributes.descripcio}
                </Text>
              </Space>
            </Col>
          </>
        )}
        { !!paso && (
        <Col span={8} offset={16}>
          <Button
            ref={takeScreenshotButtonRef}
            style={{ marginBottom: 15, float: attributes ? 'right' : 'left' }}
          >
            Tomar captura
            <CameraOutlined />
          </Button>
        </Col>
        )}
      </Row>
      <MapDiv />
    </>
  );
};

const MapDiv = styled.div.attrs({ id: 'mapDiv' })`
  height: calc((100vw - 100px) / 2);
  width: calc(100vw - 100px);
  margin-top: 30px;
  .esri-layer-list__item:first-of-type {
    display: none;
  }
`;

ArcGisMap.propTypes = {
  clave_catastral_municipal: PropTypes.string,
  setLoading: PropTypes.func,
  paso: PropTypes.shape({
    instrucciones: PropTypes.string,
  }),
  callback: PropTypes.func,
};

ArcGisMap.defaultProps = {
  clave_catastral_municipal: '',
  setLoading: () => {},
  paso: null,
  callback: () => { },
};

export default ArcGisMap;
