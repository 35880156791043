/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Transfer,
  Col,
  Select,
  Form,
  Typography,
} from 'antd';

const { Text } = Typography;

const PlainTransfer = ({
  dataSource,
  formItemName,
  label,
  rules,
  form,
  nested,
  filterProp,
  ...restProps
}) => {
  const [targetKeys, setTargetKeys] = useState([]);

  // eslint-disable-next-line no-param-reassign
  dataSource = dataSource.map((e) => ({
    ...e,
    key: e.id,
    title: `${e.clave ? `${e.clave} - ` : ''}${e[filterProp]}`,
  }));

  useEffect(() => {
    setTimeout(() => {
      const keys = form.getFieldValue(formItemName) || [];
      setTargetKeys(keys);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Col xs={0} sm={0} md={24}>
        <Text style={{ color: '#000000DA' }}>
          {label}
        </Text>
        <Transfer
          titles={['Disponibles', 'Seleccionados']}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
          targetKeys={targetKeys}
          dataSource={dataSource}
          className="plain-transfer"
          render={(item) => item.title}
          showSelectAll={false}
          filterOption={(input, opt) => opt.title?.toLowerCase().trim()
            .indexOf(input?.toLowerCase().trim()) >= 0}
          onChange={(keys) => {
            setTargetKeys(keys);
            const validKeys = keys
              .filter((k) => typeof k === 'number')
              .map((k) => parseInt(k, 10));
            form.setFieldsValue({
              [`${formItemName}`]: validKeys,
            });
          }}
          pagination
          showSearch
          oneWay
        />
        <br />
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Form.Item
          name={formItemName}
          label={label}
          rules={rules}
          hasFeedback
        >
          <Select
            showSearch
            mode="multiple"
            showArrow
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, opt) => opt.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            onChange={(keys) => {
              setTargetKeys(keys.map((k) => k.toString()));
            }}
          >
            {dataSource.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </div>
  );
};

PlainTransfer.propTypes = {
  dataSource: PropTypes.array.isRequired,
  rules: PropTypes.array,
  formItemName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.any.isRequired,
  nested: PropTypes.bool,
  filterProp: PropTypes.string,
};

PlainTransfer.defaultProps = {
  rules: [],
  nested: false,
  filterProp: null,
};

export default PlainTransfer;
