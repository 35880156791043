const regexpUpper = new RegExp(/[A-ZÁÉÍÓÚÑ]/);
const regexpLower = new RegExp(/[a-záéíóúñ]/);
const regexpNumber = new RegExp(/[0-9]/);

export const defaultOptions = [
  {
    label: 'Mínimo 8 caracteres',
    checked: false,
    key: 1,
  },
  {
    label: 'Mínimo una mayúscula',
    checked: false,
    key: 2,
  },
  {
    label: 'Mínimo una minúscula',
    checked: false,
    key: 3,
  },
  {
    label: 'Mínimo un carácter especial',
    checked: false,
    key: 4,
  },
  {
    label: 'Mínimo un número',
    checked: false,
    key: 5,
  },
];

export const validatePasswordRequirements = (rawPassword = undefined) => {
  const password = rawPassword?.trim();
  return [
    {
      label: 'Mínimo 8 caracteres',
      checked: password?.length >= 8,
      key: 1,
    },
    {
      label: 'Mínimo una mayúscula',
      checked: regexpUpper.test(password),
      key: 2,
    },
    {
      label: 'Mínimo una minúscula',
      checked: regexpLower.test(password),
      key: 3,
    },
    {
      label: 'Mínimo un carácter especial',
      checked: password && !/^[A-ZÁÉÍÓÚÑa-záéíóúñ0-9]+$/.test(password),
      key: 4,
    },
    {
      label: 'Mínimo un número',
      checked: regexpNumber.test(password),
      key: 5,
    },
  ];
};

export const allRequirementsPassed = (
  password = undefined,
) => validatePasswordRequirements(password).every((e) => e.checked);

export default { validatePasswordRequirements, defaultOptions };
