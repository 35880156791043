/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';

import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import {
  onError,
} from 'utils/handlers';

const baseURI = '/catastro/uso-de-predio/';

const UsosDePredios = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();

    return () => API.source.cancel();
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({ ...record });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 90,
    },

  ];

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickExpand: () => setVisible(true),
            }}
            allowAdd={false}
            allowDelete={false}
            allowEdit={false}
            allowExpand
            rowKey="id"
            mobileColIndex={0}
          />
          <Modal
            visible={visible}
            title={(
              <Row justify="space-between">
                Vista Previa
                <FormSubmitControls onCancel={onCancel} />
              </Row>
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            forceRender
          >
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="descripcion"
                      label="Descripción"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Modal>
        </Spin>
      </Col>
    </Row>
  );
};
export default UsosDePredios;
