/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Grid,
} from 'antd';
import {
  ArrowLeftOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import API from '../utils/api';
import ModalChangeStatus from './ModalChangeStatus';

const FormSubmitControls = (props) => {
  const {
    onCancel,
    onFinish,
    onFinishText = 'Guardar',
    onCancelText = 'Regresar',
    URI,
    onError,
    allowAuthorize = false,
    allowCancel = false,
    mutable = true,
    selectedRowKeys,
    callback,
    allowSaveAndContinue = false,
  } = props;
  const screens = Grid.useBreakpoint();
  const [visibleAuthorize, setVisibleAuthorize] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);

  const onCancelStatus = async (values) => {
    try {
      const [key] = selectedRowKeys;
      const { observacion_de_la_cancelacion } = values;
      const res = await API.patch(`${URI + key}/`, {
        estados_globales: 5,
        observacion_de_la_cancelacion,
      });
      if (res?.status === 200) {
        setVisibleCancel(false);
        callback(5, observacion_de_la_cancelacion);
      }
    } catch (err) {
      onError(err);
    }
  };

  const onAuthorizeStatus = async () => {
    try {
      const [key] = selectedRowKeys;
      const res = await API.patch(`${URI + key}/`, {
        estados_globales: 4,
      });
      if (res?.status === 200) {
        setVisibleAuthorize(false);
        callback(4);
      }
    } catch (err) {
      onError(err);
    }
  };

  return (
    <>
      <Row className="form-submit-controls">
        {!screens.md ? (
          <Col span={24}>
            <Row>
              {onCancel && (
              <Button
                type="link"
                onClick={onCancel}
              >
                <ArrowLeftOutlined />
              </Button>
              )}
              {(allowCancel && mutable) && (
              <Button
                type="link"
                onClick={() => setVisibleCancel(true)}
                className="no-color danger"
              >
                <CloseCircleOutlined />
              </Button>
              )}
              {(allowAuthorize && mutable) && (
              <Button
                type="link"
                onClick={() => setVisibleAuthorize(true)}
                className="no-color success"
              >
                <CheckCircleOutlined />
              </Button>
              )}
              {onFinish && (
              <Button
                type="link"
                onClick={onFinish}
              >
                <SaveOutlined />
              </Button>
              )}
              {(onFinish && allowSaveAndContinue && mutable) && (
              <Button
                type="link"
                onClick={() => {
                  onFinish(null, true);
                }}
              >
                <EditOutlined />
              </Button>
              )}
            </Row>
          </Col>
        ) : (
          <Col span={24}>
            <Row>
              {onCancel && (
              <Button
                type="link"
                onClick={onCancel}
              >
                <ArrowLeftOutlined />
                {onCancelText}
              </Button>
              )}
              {(allowCancel && mutable) && (
              <Button
                type="link"
                onClick={() => setVisibleCancel(true)}
                className="no-color danger"
              >
                <CloseCircleOutlined />
                Cancelar
              </Button>
              )}
              {(allowAuthorize && mutable) && (
              <Button
                type="link"
                onClick={() => setVisibleAuthorize(true)}
                className="no-color success"
              >
                <CheckCircleOutlined />
                Autorizar
              </Button>
              )}
              {(onFinish && mutable) && (
              <Button
                type="link"
                htmlType="submit"
                onClick={onFinish}
                style={{ marginLeft: 10 }}
              >
                <SaveOutlined />
                {onFinishText}
              </Button>
              )}
              {(onFinish && mutable && allowSaveAndContinue) && (
              <Button
                type="link"
                htmlType="submit"
                onClick={() => {
                  onFinish(null, true);
                }}
              >
                <EditOutlined />
                {`${onFinishText} y continuar`}
              </Button>
              )}
            </Row>
          </Col>
        )}

      </Row>
      <ModalChangeStatus
        onSubmit={visibleAuthorize ? onAuthorizeStatus : onCancelStatus}
        onCancel={() => {
          setVisibleAuthorize(false);
          setVisibleCancel(false);
        }}
        visible={visibleAuthorize || visibleCancel}
        onCancelText={onCancelText}
        authorize={visibleAuthorize}
        onError={onError}
      />
    </>
  );
};
export default FormSubmitControls;
