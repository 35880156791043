module.exports = (value, withoutCurrencySymbol = false) => {
  let val = value;
  if (typeof val !== 'number') {
    val = 0;
  }

  const output = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  if (withoutCurrencySymbol) {
    return output.replace('$', '');
  }

  return output;
};
