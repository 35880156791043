import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Spin,
  Row,
  Col,
  Button,
} from 'antd';
import NumericInput from 'components/NumericInput';
import API from '../../utils/api';
import { onError, onSuccess } from '../../utils/handlers';

const baseURI = '/catastro/factores-especiales/';

const FactoresEspeciales = ({
  selectedRowKey,
  setFormFactoresEspeciales,
  unidades,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const res = await API.get(baseURI, {
          params: {
            predio: selectedRowKey,
          },
        });
        if (res?.status === 200) {
          form.setFieldsValue(res.data[res.data.length - 1]);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, handleError) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.predio = selectedRowKey;
      if (!values?.id) {
        const res = await API.post(baseURI, values);
        if (res.status === 201) {
          onSuccess(res, 'Actualizado correctamente');
          setTimeout(async () => {
            if (handleError) {
              await fetchData();
            }
          });
        }
      } else {
        const res = await API.patch(`${baseURI}${values.id}`, values);
        if (res.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          setTimeout(async () => {
            if (!handleError) {
              await fetchData();
            }
          });
        }
      }
      setLoading(false);
    } catch (err) {
      if (handleError) {
        onError(err, setLoading);
      }
    }
  };

  const onFinishHandler = async (_, _continue = true) => {
    try {
      await onFinish(null, _continue);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      form.onFinishHandler = onFinishHandler;
      setFormFactoresEspeciales(form);
    });
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const tooltipSuperficie = {
    title: `En ${unidades.unidad_de_medida_de_la_superficie}`,
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            name="factores_especiales"
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  decimal
                  name="factor_de_fondo"
                  label="Factor de Fondo"
                  tooltip={tooltipSuperficie}
                />
              </Col>

              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  decimal
                  name="factor_de_irregularidad"
                  label="Factor de Irregularidad"
                  tooltip={tooltipSuperficie}
                />
              </Col>

              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  decimal
                  name="factor_de_area"
                  label="Factor de Área"
                  tooltip={tooltipSuperficie}
                />
              </Col>

              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  decimal
                  name="factor_de_topografia"
                  label="Factor de Topografía"
                  tooltip={tooltipSuperficie}
                />
              </Col>

              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  decimal
                  name="factor_de_descripcion"
                  label="Factor de Descripción"
                  tooltip={tooltipSuperficie}
                />
              </Col>

              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  decimal
                  name="factor_de_frente"
                  label="Factor de Frente"
                  tooltip={tooltipSuperficie}
                />
              </Col>
            </Row>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Spin>
    </>
  );
};

FactoresEspeciales.propTypes = {
  selectedRowKey: PropTypes.number,
  setFormFactoresEspeciales: PropTypes.func.isRequired,
  unidades: PropTypes.shape({
    unidad_de_medida: PropTypes.string,
    unidad_de_medida_de_la_superficie: PropTypes.string,
  }),
};

FactoresEspeciales.defaultProps = {
  selectedRowKey: undefined,
  unidades: {},
};

export default FactoresEspeciales;
