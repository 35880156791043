/* eslint-disable no-param-reassign */
import axios from 'axios';
import { store } from '../store/index';
import { REFRESH_TOKEN, LOGOUT } from '../store/reducers/auth';
import { CLEAR } from '../store/reducers/catalogs';
import { CLEAN } from '../store/reducers/app';

const {
  REACT_APP_SCHEME: SCHEME,
  REACT_APP_APIHOST: APIHOST,
  REACT_APP_PATH: PATH,
} = process.env;
export const baseURL = `${SCHEME}://${APIHOST}${PATH}`;

const defaultOptions = {
  baseURL,
  timeout: 60 * 60 * 10,
};

const http = axios.create(defaultOptions);
export const createEmptyAxiosInstance = (withBaseConfig = true) => axios
  .create(withBaseConfig ? defaultOptions : null);

http.interceptors.request.use((options) => {
  const { token } = store.getState().auth;
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  if (options.url[options.url.length - 1] !== '/') {
    options.url = `${options.url}/`;
  }
  const source = axios.CancelToken.source();
  options.cancelToken = source.token;
  http.source = source;
  return options;
});

const refresh = async () => {
  try {
    const { refreshToken } = store.getState().auth;
    const res = await http.post('usuarios/refresh-token/', {
      refresh: refreshToken,
    });
    if (res && res.status === 200) {
      return res.data.access;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const logout = () => {
  setTimeout(() => {
    store.dispatch({ type: CLEAN });
    store.dispatch({ type: CLEAR });
    store.dispatch({ type: LOGOUT });
  });
};

http.interceptors.response.use(null, async (error) => {
  const inRefreshToken = error?.config?.url === 'usuarios/refresh-token/';
  if (error?.response && !inRefreshToken) {
    const { response: { data, status } } = error;
    if (data && status === 401) {
      const errorDetail = data.code === 'token_not_valid';
      if (errorDetail) {
        const token = await refresh();
        if (token) {
          const config = { ...error.config };
          config.headers.Authorization = `Bearer ${token}`;
          store.dispatch({
            type: REFRESH_TOKEN,
            payload: token,
          });
          return axios.request(config);
        }
        // TODO: Ask for the password to relogin the user if he wants to keep using the app.
        // Otherwise redirect to login
        logout();
      }
    }
  } else if (inRefreshToken) {
    logout();
  }
  return Promise.reject(error);
});

export default http;
