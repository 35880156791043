/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Modal,
  Row,
  Button,
  Col,
  message,
  Grid,
  Form,
  Input,
  Spin,
} from 'antd';

import {
  ArrowLeftOutlined,
  SendOutlined,
} from '@ant-design/icons';

import API from '../utils/api';

const { Text } = Typography;

const Previewer = ({
  setVisible,
  visible,
  iFrameUrl,
  isImage,
  base64,
  allowSend,
  onError,
  baseURL,
  id,
  cuentaUnicaID,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [sendToEmail, setSendToEmail] = React.useState(false);
  const [visibleConfirm, setVisibleConfirm] = React.useState(false);
  const [visibleInput, setVisibleInput] = React.useState(false);
  const [cuentaUnica, setCuentaUnica] = React.useState();
  const screens = Grid.useBreakpoint();

  const sendTo = async () => {
    try {
      setLoading(true);
      if (sendToEmail) {
        await form.validateFields();
        let values;
        if (visibleInput) {
          values = form.getFieldsValue();
        }
        const res = await API.post(`${baseURL}${id}/enviar-correo/`, values);
        if (res?.status === 200) {
          message.info('El documento ha sido enviado revise su bandeja de recepción');
          setVisibleConfirm(false);
          setVisibleInput(false);
        }
      } else {
        const res = await API.post(`${baseURL}${id}/enviar-micuentamx/`);
        if (res?.status === 200) {
          message.info('El documento ha sido enviado revise su bandeja de recepción');
          setVisibleConfirm(false);
          setVisibleInput(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  React.useEffect(() => {
    const fetchCuenta = async () => {
      try {
        if (cuentaUnicaID) {
          const res = await API.get(`cuentaunicasir/ciudadano/${cuentaUnicaID}/`);
          setCuentaUnica(res.data);
        }
      } catch (err) {
        onError(err);
      }
    };

    fetchCuenta();

    return () => {
      API.source.cancel();
    };
    // eslint-disable-next-line
  }, [cuentaUnicaID]);

  const onClickSendToEmail = () => {
    setVisibleConfirm(true);
    setSendToEmail(true);
  };

  const onClickSendToMX = () => {
    setVisibleConfirm(true);
    setSendToEmail(false);
  };

  const onCancel = () => {
    if (visibleInput) {
      setVisibleInput(false);
    } else if (visibleConfirm) {
      setVisibleConfirm(false);
    } else {
      setVisible(false);
    }
  };

  const SubmitControls = () => (
    <Row className="form-submit-controls">
      <Col xs={24} sm={24} md={0}>
        <Row>
          <Button
            type="link"
            onClick={onCancel}
          >
            <ArrowLeftOutlined />
          </Button>
          {(allowSend && !visibleConfirm) && (
            <>
              <Button
                type="link"
                onClick={onClickSendToEmail}
              >
                <SendOutlined />
              </Button>
              <Button
                type="link"
                onClick={onClickSendToMX}
              >
                <SendOutlined />
              </Button>
            </>
          )}
          {visibleConfirm && (
            <Button
              type="link"
              onClick={sendTo}
            >
              <SendOutlined />
            </Button>
          )}
        </Row>
      </Col>
      <Col xs={0} sm={0} md={24}>
        <Row>
          <Button
            type="link"
            onClick={onCancel}
          >
            <ArrowLeftOutlined />
            Regresar
          </Button>
          {(allowSend && !visibleConfirm) && (
            <>
              <Button
                type="link"
                onClick={onClickSendToEmail}
              >
                <SendOutlined />
                Enviar a Correo
              </Button>
              <Button
                type="link"
                onClick={onClickSendToMX}
              >
                <SendOutlined />
                Enviar a MiCuentaMX
              </Button>
            </>
          )}
          {visibleConfirm && (
            <Button
              type="link"
              onClick={sendTo}
            >
              <SendOutlined />
              {sendToEmail ? 'Enviar a Correo' : 'Enviar a MiCuentaMX'}
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );

  return (
    <>
      <Modal
        title={(
          <Row justify="space-between">
            <Text>
              Preview
            </Text>
            <SubmitControls />
          </Row>
        )}
        className={`modal-preview ${visibleConfirm ? 'padding' : ''}`}
        visible={visible}
        footer={null}
        closable={false}
        onCancel={onCancel}
        // eslint-disable-next-line no-nested-ternary
        width={visibleConfirm ? screens?.md ? '50%' : '90%' : '90%'}
        centered
      >
        {!visibleConfirm ? (
          <>
            {isImage ? (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img src={iFrameUrl} />
            ) : (
              <iframe
                src={base64
                  || `https://docs.google.com/viewer?url=${encodeURIComponent(iFrameUrl)}&embedded=true`}
                title="preview"
              />
            )}
          </>
        ) : (
          <Row align="center" justify="middle">
            {visibleInput ? (
              <>
                <Spin
                  tip="Cargando..."
                  spinning={loading}
                  style={{ width: '100%', height: '100%' }}
                >
                  <Form
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    onFinish={sendTo}
                  >
                    <Row gutter={10}>
                      <Col span={24}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'El campo es requerido',
                            },
                            {
                              type: 'email',
                              message: 'Ingrese un correo electrónico válido',
                            },
                          ]}
                          label="Correo electrónico"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={0}>
                        <Form.Item>
                          <Button htmlType="submit" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Spin>
              </>
            ) : (
              <>
                <Text style={{ fontSize: 16, textAlign: 'center' }}>
                  ¿Desea enviar el archivo a
                  {' '}
                  {`${cuentaUnica?.clave_ciudadana} - ${cuentaUnica?.email}`}
                  ?
                  {sendToEmail && (
                  <>
                    <br />
                    o
                    <br />
                    <Button type="link" onClick={() => setVisibleInput(true)}>
                      Enviar a otro correo
                    </Button>
                  </>
                  )}
                </Text>
                <br />
                <br />
                <Text style={{ textAlign: 'center' }}>
                  Si la información no es correcta actualicela en el módulo de ciudadanos.
                </Text>
              </>
            )}
          </Row>
        )}
      </Modal>
    </>
  );
};
Previewer.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  isImage: PropTypes.bool,
  iFrameUrl: PropTypes.string,
  base64: PropTypes.string,
  allowSend: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  id: PropTypes.any,
  baseURL: PropTypes.string,
  cuentaUnicaID: PropTypes.number,
};
Previewer.defaultProps = {
  iFrameUrl: null,
  isImage: false,
  base64: null,
  allowSend: false,
  id: null,
  baseURL: null,
  cuentaUnicaID: null,
};
export default Previewer;
