import { store } from 'store';

export const toUpper = (val) => val?.toUpperCase();
export const toInteger = (val) => val.replace(/[^0-9]+/g, '') || null;
export const nullify = (val) => val || null;
export const capitalize = (sentence) => {
  const { solo_mayusculas } = store.getState()?.auth?.entidad || false;
  return solo_mayusculas && sentence ? sentence.toUpperCase() : sentence;
};

/**
 * List Formatter
 * @param {string[]} list
 * @param {'conjunction'|'disjunction'} type
 * @returns
 */
export const listToString = (
  list,
  type = 'conjunction',
) => new Intl.ListFormat('es-MX', { type }).format(list);
