/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  message,
  Input,
} from 'antd';

import API from '../../../utils/api';
import LadaNumero from '../../LadaNumero';
import Select from '../../Select';
import PrediosKeys from './PrediosKeys';
import getFormattedValues, { hasFiles } from '../../../utils/formatValues';

const currentURI = '/catalogos/entidades/configuracion/';

const Configurations = ({
  onError,
  setLoading,
  callback,
  setCurrentTabKey,
  setForm,
  disabled,
}) => {
  const [form] = Form.useForm();
  const [monedas, setMonedas] = React.useState([]);
  const config = useSelector(({ auth }) => auth.entidad);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (config) {
        const {
          lada,
          moneda,
          utilizar_redondeo,
        } = config;
        const data = {
          lada,
          moneda: moneda.id,
          utilizar_redondeo,
        };
        form.setFieldsValue({ ...data });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const onFinish = async (_values, _continue = true) => {
  //   try {
  //     setLoading(true);
  //     await form.validateFields();
  //     const values = form.getFieldsValue();
  //     if (values.id) {
  //       const res = await API.patch(`${currentURI}${values.id}/`, values);
  //       if (res?.status === 200) {
  //         message.info('Configuraciones actualizadas correctamente');
  //         setTimeout(async () => {
  //           callback(res.data, _continue);
  //           if (_continue) {
  //             await fetchData();
  //           }
  //         });
  //       }
  //     } else {
  //       const res = await API.post(currentURI, values);
  //       if (res?.status === 201) {
  //         message.info('Configuraciones agregadas correctamente');
  //         setTimeout(async () => {
  //           callback(res.data, _continue);
  //           if (_continue) {
  //             await fetchData();
  //           }
  //         });
  //       }
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };
  const onFinish = async (_values, _continue = true) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['logotipo_vertical'];
      const configuration = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      const res = await API.post(currentURI, formattedValues, configuration);
      if (res?.status === 200) {
        message.info('Información actualizada correctamente');
        setTimeout(async () => {
          callback(res.data, _continue);
          if (_continue) {
            await fetchData();
          }
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showAddressMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                fetchData();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
    });
  };
  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showAddressMsg(tabKey);
      } else {
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err);
    }
  };
  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resMonedas = await API.get('/configuracion/monedas/');
        setMonedas(resMonedas.data.filter((e) => !e.calculable));
        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    setTimeout(() => {
      form.onFinishHandler = onFinishHandler;
      setForm(form);
    });
    // eslint-disable-next-line
  }, []);
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
    lada: [requiredRule],
  };
  return (
    <>
      <Col span={24}>
        <Form
          form={form}
          name="configurations"
          layout="vertical"
          onFinish={() => onFinish()}
        >
          <Row gutter={10}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Col xs={24} sm={24} md={8}>
              <LadaNumero rules={rules} disabled={disabled} ladaOnly form={form} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
            >
              <Form.Item
                rules={rules.required}
                name="moneda"
                label="Moneda"
                hasFeedback
              >
                <Select
                  disabled={disabled}
                  dataSource={monedas}
                  keyLabelRender
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="utilizar_redondeo"
                hasFeedback
                rules={rules.required}
                label="¿Usar Redondeo?"
              >
                <Select disabled={disabled} trueFalse />
              </Form.Item>
            </Col>
            <PrediosKeys onError={onError} disabled={disabled} />
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Col>
    </>
  );
};
Configurations.propTypes = {
  onError: PropTypes.func.isRequired,
  callback: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setCurrentTabKey: PropTypes.func.isRequired,
};
Configurations.defaultProps = {
  callback: () => {},
  disabled: false,
};
export default Configurations;
