import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  Typography,
  message,
  Input,
} from 'antd';

import API from '../../../utils/api';
import { isMutable, isAuthorized } from '../../../utils/estadosGlobales';
import { onError } from '../../../utils/handlers';
import { toInteger } from '../../../utils/normalizers';
import FormSubmitControls from '../../FormSubmitControls';
import Table from '../../Table';
import ModalDelete from '../../ModalDelete';
import Select from '../../Select';

const { Title } = Typography;
const currentURI = 'catastro/configuracion-de-la-clave/';

const PrediosKeys = ({
  disabled,
}) => {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [disabledRequerido, setDisabledRequerido] = useState(false);
  const [disabledOpcional, setDisabledOpcional] = useState(false);
  const [disabledPrincipal, setDisabledPrincipal] = useState(false);
  const [disabledLongitudes, setDisabledLongitudes] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [tiposDeLlaves, setTiposDeLlaves] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(currentURI);
      if (res.status === 200) {
        setTimeout(() => {
          setData(res.data.map((e) => ({ ...e, disabled: false })));
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resTipos = await API.get('catastro/clave-de-predios/');
        setTiposDeLlaves(resTipos.data);
        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setDisabledRequerido(false);
    setDisabledOpcional(false);
    setSelectedRowKeys([]);
    setMutable(true);
    setDisabledPrincipal(data.some((e) => e.es_principal));
    setDisabledLongitudes(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      if (!selectedRowKeys.length) {
        const res = await API.post(currentURI, values);
        if (res?.status === 201) {
          onCancel();
          await fetchData();
        }
      } else {
        const res = await API.put(`${currentURI}${key}/`, values);
        if (res?.status === 200) {
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record.estados_globales));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    setDisabledRequerido(match.es_principal);
    setDisabledOpcional(match.es_principal || match.es_requerido);
    setDisabledPrincipal(data.some((e) => e.es_principal));
    setDisabledLongitudes(match.clave_de_predio === 2);
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      if (mutable) {
        const response = await API.delete(`${currentURI}${key}`);
        if (response?.status === 204) {
          await fetchData();
          onCancel();
          setVisibleAlert(false);
        }
      } else if (isAuthorized(match)) {
        message.info('No se pueden eliminar Configuraciones autorizadas');
      } else {
        message.info('No se pueden eliminar Configuraciones canceladas');
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    longitud_maxima: [
      requiredRule,
      {
        validator: async (rule, value) => {
          const longitud_minima = form.getFieldValue('longitud_minima');
          if (value) {
            if (!/[1-9]/.test(value)) {
              throw new Error('El campo debe ser un número entero mayor a 0');
            } else if (parseInt(value, 10) < parseInt(longitud_minima, 10)) {
              throw new Error('El campo debe ser mayor a Longitud Mínima');
            }
          }
        },
      },
    ],
    longitud_minima: [
      requiredRule,
      {
        validator: async (rule, value) => {
          const longitud_maxima = form.getFieldValue('longitud_maxima');
          if (value) {
            if (!/[1-9]/.test(value)) {
              throw new Error('El campo debe ser un número entero mayor a 0');
            } else if (parseInt(value, 10) > parseInt(longitud_maxima, 10)) {
              throw new Error('El campo debe ser menor a Longitud Máxima');
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Tipo',
      key: 'clave_de_predio',
      dataIndex: 'clave_de_predio',
      width: 250,
      render: (id) => tiposDeLlaves.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Longitud Mínima',
      key: 'longitud_minima',
      dataIndex: 'longitud_minima',
      width: 150,
    },
    {
      titleText: 'Longitud Máxima',
      key: 'longitud_maxima',
      dataIndex: 'longitud_maxima',
      width: 150,
    },
    {
      titleText: 'Principal',
      key: 'es_principal',
      dataIndex: 'es_principal',
      width: 90,
      render: (val) => (val ? 'Si' : 'No'),
    },
    {
      titleText: 'Requerido',
      key: 'es_requerido',
      dataIndex: 'es_requerido',
      width: 90,
      render: (val) => (val ? 'Si' : 'No'),
    },
    {
      titleText: 'Opcional',
      key: 'es_opcional',
      dataIndex: 'es_opcional',
      width: 90,
      render: (val) => (val ? 'Si' : 'No'),
    },
    {
      titleText: 'Alfanúmerico',
      key: 'es_alfanumerica',
      dataIndex: 'es_alfanumerica',
      width: 100,
      render: (val) => (val ? 'Si' : 'No'),
    },
  ];

  useEffect(() => {
    if (tiposDeLlaves.length) {
      const clone = [...tiposDeLlaves];
      const alreadyAdded = clone
        .filter((e) => data.some((x) => x.clave_de_predio === e.id && x.estados_globales !== 5))
        .map((e) => e.id);
      const mapped = clone.map((e) => ({ ...e, disabled: alreadyAdded.includes(e.id) }))
        .sort((a, b) => a.disabled.toString().localeCompare(b.disabled.toString()));
      setTiposDeLlaves(mapped);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Col span={24}>
      <Title level={4}>
        Llaves de Predios
      </Title>
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          allowSort={false}
          allowSearch={false}
          disabled={disabled}
          rowKey="id"
        />
        <Modal
          visible={visible}
          closable={false}
          footer={null}
          title={(
            <Row justify="space-between">
              {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Llave de Predio`}
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                mutable={mutable}
                loading={loading}
              />
            </Row>
        )}
          keyboard={!loading}
          maskClosable={!loading}
          onCancel={onCancel}
          forceRender
          centered
          width="80%"
        >
          <Col span={24}>
            <Form
              name="predios_keys"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                estados_globales: 1,
                es_principal: false,
                es_requerido: false,
                es_opcional: true,
                es_alfanumerica: false,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="clave_de_predio"
                    label="Tipo de llave"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      disabled={!mutable}
                      dataSource={tiposDeLlaves}
                      onChange={(val) => {
                        if (val === 2) {
                          form.setFieldsValue({
                            longitud_minima: 30,
                            longitud_maxima: 32,
                          });
                          setDisabledLongitudes(true);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="es_principal"
                    label="¿Es principal?"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      trueFalse
                      disabled={disabledPrincipal || !mutable}
                      onChange={(val) => {
                        const [key] = selectedRowKeys;
                        if (val) {
                          form.setFieldsValue({
                            es_requerido: true,
                            es_opcional: false,
                            estados_globales: 4,
                          });
                          setDisabledRequerido(true);
                          setDisabledOpcional(true);
                        } else if (key) {
                          const match = data.find((e) => e.id === key);
                          form.setFieldsValue({
                            estados_globales: match.estados_globales,
                          });
                          setDisabledRequerido(false);
                        } else {
                          form.resetFields(['estados_globales']);
                          setDisabledRequerido(false);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="longitud_minima"
                    label="Longitud mínima"
                    rules={rules.longitud_minima}
                    disabled={!mutable || disabledLongitudes}
                    normalize={toInteger}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="longitud_maxima"
                    label="Longitud máxima"
                    rules={rules.longitud_maxima}
                    disabled={!mutable || disabledLongitudes}
                    normalize={toInteger}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="es_requerido"
                    label="¿Es requerido?"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      onChange={(val) => {
                        form.setFieldsValue({
                          es_opcional: !val,
                        });
                        setDisabledOpcional(val);
                      }}
                      disabled={disabledRequerido || !mutable}
                      trueFalse
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="es_opcional"
                    label="¿Es opcional?"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select disabled={disabledOpcional || !mutable} trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="es_alfanumerica"
                    label="¿Es alfanúmerico?"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select disabled={!mutable} trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      disabled={!selectedRowKeys.length || !mutable}
                      dataSource={estadosGlobales}
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Configuración de Llave ${tiposDeLlaves
            .find((e) => e.id === form.getFieldValue('clave_de_predio'))?.descripcion}`}
        />
      </Spin>
    </Col>
  );
};

PrediosKeys.propTypes = {
  disabled: PropTypes.bool,
};

PrediosKeys.defaultProps = {
  disabled: false,
};

export default PrediosKeys;
