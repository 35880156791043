/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  UnorderedListOutlined,
} from '@ant-design/icons';
// import TiposDeSuelo from 'views/Catalogos/TiposDeSuelo';
import TiposDeTenencia from 'views/Catalogos/TipoDeTenencia';
import TipoDeDesarrollo from 'views/Catalogos/TipoDeDesarrollo';
import TipoDePredio from 'views/Catalogos/TipoDePredio';
import ZonasHomogeneas from 'components/Catalagos/ZonasHomogeneas';
import CarateristicasDelPredio from 'views/Catalogos/ServiciosPredio';
import ValorDelServicio from 'views/Catalogos/ValorDelServicio';
import EstadoDeLaConstruccion from 'components/Catalagos/EstadoDeLaConstruccion';
import CalidadesDeConstruccion from 'components/Catalagos/CalidadesDeConstrucciones';
import MotivosDeMeritoDemeritoConstruccion from 'components/Catalagos/MotivosDeMeritoDemeritoConstruccion';
import EdadesDeLaConstruccion from 'components/Catalagos/EdadesDeConstruccion';
import MotivosDeMeritoDemeritoTerreno from 'components/Catalagos/MotivosDeMeritoDemeritoTerreno';
import CorredoresDeValor from 'components/Catalagos/CorredoresDeValor';
import TiposDeSuelo from 'views/Catalogos/TiposDeSuelo';
import NivelesDeConstruccion from 'views/Catalogos/NivelesDeConstruccion';
// import MeritosYDemeritos from 'views/Catalogos/MeritosYDemeritos';
// import BandasDeValores from 'views/Catalogos/BandasDeValores';
// import TipoDeDocumentoDeTraslacion from 'views/Catalogos/TipoDeDocumentoDeTraslacion';
// import TipoDeDominio from 'views/Catalogos/TipoDeDominio';
// import CategoriaDeRechazo from 'views/Catalogos/CategoriaDeRechazo';
// import RazonesDeRechazo from 'views/Catalogos/RazonesDeRechazo';
// import Vialidad from 'views/Catalogos/Vialidad';
// import Factibilidad from 'views/Catalogos/Factibilidad';
// import Participe from 'views/Catalogos/Participe';
import blank from './blank';
import TiposDeConstruccion from '../views/Predio/TiposDeConstruccion';
// import ClasificacionDeConstruccion from '../views/Predio/ClasificacionDeConstruccion';
// import TasaDAPRural from '../views/Predio/TasaDAPRural';
// import TasaDePredioRural from '../views/Predio/TasaDePredioRural';
// import TasaDePredioUrbano from '../views/Predio/TasaDePredioUrbano';
// import TasaDAPUrbano from '../views/Predio/TasaDAPUrbano';
// import TasaDeConstruccionDePredioUrbano from '../views/Predio/TasaDeConstruccionDePredioUrbano';
// import TasaDeConstruccionDePredioRural from '../views/Predio/TasaDeConstruccionDePredioRural';
import UsosDePredios from '../views/Predio/UsosDePredios';
// import CategoriasDePredio from '../views/Predio/CategoriasDePredio';
// import RegimenDePropiedad from '../views/Predio/RegimenDePropiedad';

export default {
  title: 'Catálogos',
  icon: () => <UnorderedListOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    {
      title: 'Predios',
      icon: null,
      showInSidebar: true,
      component: blank,
      type: 'private',
      children: [
        {
          title: 'Servicios Públicos',
          icon: null,
          showInSidebar: true,
          component: CarateristicasDelPredio,
          type: 'private',
        },
        {
          title: 'Valores de los Servicios Públicos',
          icon: null,
          showInSidebar: true,
          component: ValorDelServicio,
          type: 'private',
        },
        {
          title: 'Uso de Predio',
          icon: null,
          showInSidebar: true,
          component: UsosDePredios,
          type: 'private',
        },
        // {
        //   title: 'Motivos de mérito/demérito terreno',
        //   icon: null,
        //   showInSidebar: true,
        //   component: UsosDePredios,
        //   type: 'private',
        // },
        {
          title: 'Tipo de Predio',
          icon: null,
          showInSidebar: true,
          component: TipoDePredio,
          type: 'private',
        },
        {
          title: 'Tipos de Suelo',
          icon: null,
          showInSidebar: true,
          component: TiposDeSuelo,
          type: 'private',
        },
        {
          title: 'Tipos de Desarrollo',
          icon: null,
          showInSidebar: true,
          component: TipoDeDesarrollo,
          type: 'private',
        },
        {
          title: 'Tipos de Tenencia',
          icon: null,
          showInSidebar: true,
          component: TiposDeTenencia,
          type: 'private',
        },
        // {
        //   title: 'Regímen de Propiedad',
        //   icon: null,
        //   showInSidebar: true,
        //   component: TiposDeSuelo,
        //   type: 'private',
        // },
        // {
        //   title: 'Giro de Predio',
        //   icon: null,
        //   showInSidebar: true,
        //   component: TiposDeSuelo,
        //   type: 'private',
        // },
        // {
        //   title: 'Corredor de valor',
        //   icon: null,
        //   showInSidebar: true,
        //   component: TiposDeSuelo,
        //   type: 'private',
        // },
        // {
        //   title: 'Méritos y Deméritos',
        //   icon: null,
        //   showInSidebar: true,
        //   component: MeritosYDemeritos,
        //   type: 'private',
        // },
        {
          title: 'Corredores de Valor',
          icon: null,
          showInSidebar: true,
          component: CorredoresDeValor,
          type: 'private',
        },
        {
          title: 'Zona Homogénea',
          icon: null,
          showInSidebar: true,
          component: ZonasHomogeneas,
          type: 'private',
        },
      ],
    },
    {
      title: 'Construcciones',
      icon: null,
      showInSidebar: true,
      component: blank,
      type: 'private',
      children: [
        {
          title: 'Claves De Valuación',
          icon: null,
          showInSidebar: true,
          component: blank,
          type: 'private',
        },
        {
          title: 'Clasificaciones de Construcción',
          icon: null,
          showInSidebar: true,
          component: blank,
          type: 'private',
        },
        {
          title: 'Estados de Construcción',
          icon: null,
          showInSidebar: true,
          component: EstadoDeLaConstruccion,
          type: 'private',
        },
        {
          title: 'Edades de la Construcción',
          icon: null,
          showInSidebar: true,
          component: EdadesDeLaConstruccion,
          type: 'private',
        },
        {
          title: 'Motivos De Merito / Demerito de la Construcción',
          icon: null,
          showInSidebar: true,
          component: MotivosDeMeritoDemeritoConstruccion,
          type: 'private',
        },
        {
          title: 'Motivos De Merito / Demerito del Terreno',
          icon: null,
          showInSidebar: true,
          component: MotivosDeMeritoDemeritoTerreno,
          type: 'private',
        },
        {
          title: 'Calidades de Construcción',
          icon: null,
          showInSidebar: true,
          component: CalidadesDeConstruccion,
          type: 'private',
        },
        {
          title: 'Niveles de Construcción',
          icon: null,
          showInSidebar: true,
          component: NivelesDeConstruccion,
          type: 'private',
        },
        {
          title: 'Tipos de Construcción',
          icon: null,
          showInSidebar: true,
          component: TiposDeConstruccion,
          type: 'private',
        },
      ],
    },
    {
      title: 'Trámites',
      icon: null,
      showInSidebar: true,
      component: blank,
      type: 'private',
      children: [
        {
          title: 'Traslaciones',
          icon: null,
          showInSidebar: true,
          component: blank,
          type: 'private',
        },
      ],
    },
    {
      title: 'Otros',
      icon: null,
      showInSidebar: true,
      component: blank,
      type: 'private',
      children: [
        {
          title: 'Tasas DAP',
          icon: null,
          showInSidebar: true,
          component: blank,
          type: 'private',
        },
      ],
    },
    // {
    //   title: 'Participe',
    //   icon: null,
    //   showInSidebar: true,
    //   component: Participe,
    //   type: 'private',
    // },
    // {
    //   title: 'Bandas de Valores',
    //   icon: null,
    //   showInSidebar: true,
    //   component: BandasDeValores,
    //   type: 'private',
    // },
    // {
    //   title: 'Categoría y Razón de Rechazo',
    //   icon: null,
    //   showInSidebar: true,
    //   component: CategoriaDeRechazo,
    //   type: 'private',
    // },
    // {
    //   title: 'Razones de Rechazo',
    //   icon: null,
    //   showInSidebar: true,
    //   component: RazonesDeRechazo,
    //   type: 'private',
    // },
    // {
    //   title: 'Tipo de Documento de Traslación',
    //   icon: null,
    //   showInSidebar: true,
    //   component: TipoDeDocumentoDeTraslacion,
    //   type: 'private',
    // },
    // {
    //   title: 'Tipos de Dominio',
    //   icon: null,
    //   showInSidebar: true,
    //   component: TipoDeDominio,
    //   type: 'private',
    // },
    // {
    //   title: 'Vialidad',
    //   icon: null,
    //   showInSidebar: true,
    //   component: Vialidad,
    //   type: 'private',
    // },
    // {
    //   title: 'Factibillidad',
    //   icon: null,
    //   showInSidebar: true,
    //   component: Factibilidad,
    //   type: 'private',
    // },
    // {
    //   title: 'Factores Especiales',
    //   icon: null,
    //   showInSidebar: true,
    //   component: blank,
    //   type: 'private',
    // },
  ],
};
