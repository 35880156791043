import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Col,
  Row,
  Form,
  Input,
  Button,
  message,
} from 'antd';
import FormSubmitControls from '../../../components/FormSubmitControls';
import Password from '../../../components/Password';
import API from '../../../utils/api';
import {
  defaultOptions,
  allRequirementsPassed,
  validatePasswordRequirements,
} from '../../../utils/passwordRequirements';
import { onError } from '../../../utils/handlers';

const ModalChangePassword = ({
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState(defaultOptions);

  let timeout = null;
  const onFormValuesChange = (changedValues, allValues) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { password } = changedValues;
      if (password) {
        const newOps = validatePasswordRequirements(password, 33);
        setOptions(newOps);
      } else if (!allValues.password) {
        setOptions(defaultOptions);
      }
      form.validateFields(['password', 'confirmPassword']);
    }, 500);
  };

  const rules = {
    password: [
      {
        validator: async () => {
          const password = form.getFieldValue('password');
          if (!allRequirementsPassed(password)) {
            throw new Error('La contraseña no cumple con los requisitos');
          }
        },
      },
    ],
    confirmPassword: [
      {
        validator: async (rule, value) => {
          const password = form.getFieldValue('password');
          if (value && value !== password) {
            throw new Error('Las contraseñas no coinciden.');
          }
        },
      },
    ],
    currentPassword: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setOptions(defaultOptions);
  };

  const onFinishPwd = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const response = await API.put('usuarios/actualizar-contrasena', {
        contrasena: values.currentPassword,
        contrasena_nueva: values.confirmPassword,
      });
      if (response?.status === 200) {
        message.info(response.data.detail, 2.5);
        onCancel();
      }
    } catch (err) {
      onError(err);
    }
  };

  return (
    <Modal
      visible={visible}
      footer={false}
      title={(
        <Row align="middle" justify="space-between">
          Actualizar Contraseña
          <FormSubmitControls onFinish={onFinishPwd} onCancel={onCancel} />
        </Row>
      )}
      onCancel={onCancel}
      closable={false}
      width={600}
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishPwd}
          onValuesChange={onFormValuesChange}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Contraseña actual"
                name="currentPassword"
                rules={rules.currentPassword}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Password
              rules={rules}
              options={options}
            />
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};

ModalChangePassword.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ModalChangePassword.defaultProps = {
  visible: false,
};

export default ModalChangePassword;
