import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Card,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import fetchSome from 'utils/fetchSome';
import NumericInput from 'components/NumericInput';
import { SET_TIPOS_VIALIDADES } from 'store/reducers/catalogs';

const baseURI = '/catastro/corredores-de-valor/';

const CorredoresDeValor = () => {
  const [loadingPartial, setLoadingPartial] = useState(false);
  const tiposVialidades = useSelector(({ catalogs }) => catalogs.tiposVialidades);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [mutable, setMutable] = useState(true);

  const fetch = async () => {
    try {
      if (!tiposVialidades || !tiposVialidades.length) {
        fetchSome('/usuarios/tipo-vialidad/', SET_TIPOS_VIALIDADES);
      }
    } catch (err) {
      onError(err, setLoadingPartial);
    }
  };

  /*!
    El fetchData se utiliza para recuperar la informacion dentro del endpoint que se usara para
    el llenado de la tabla principal
  */
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  /*
    El useEffect se utilza para que cualquier cosa que se necesite actualizar can cada cambio
    ocurrido
  */
  useEffect(() => {
    fetch();
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  /*
    El onCancel es una funcion utilizada para restaurar todas las variables de estado, y campos
    del formulario a su estado inicial
   */
  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Nombre de vialidad principal',
      dataIndex: 'vialidad_principal',
      key: 'vialidad_principal',
      width: 150,
    },
    {
      titleText: 'Valor Unitario',
      dataIndex: 'valor_unitario',
      key: 'valor_unitario',
      width: 150,
    },
  ];

  // const requiredRule = {
  //   required: true,
  //   message: 'El campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={{ loading, loadingPartial }}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container auto-heigth"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Corredores de Valor`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
                mutable={mutable}
              />
            )}
            bordered={false}
          >
            <Form
              name="general"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Año"
                    name="anio"
                    decimal
                    maxLength={4}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Año Actualizado"
                    name="anio_actualizado"
                    decimal
                    maxLength={4}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Valor Unitario"
                    name="valor_unitario"
                    decimal
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item noStyle>
                    <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                      <Form.Item
                        name="tipo_de_vialidad_principal"
                        // rules={rules.required}
                        label="Tipo"
                      >
                        <Select style={{ width: 130 }} dataSource={tiposVialidades} />
                      </Form.Item>
                      <Form.Item
                        name="vialidad_principal"
                        label="Vialidad Principal"
                        // rules={rules.required}
                      >
                        <Input maxLength={170} allowClear />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item noStyle>
                    <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                      <Form.Item
                        name="tipo_de_vialidad_entre"
                        // rules={rules.required}
                        label="Tipo"
                      >
                        <Select style={{ width: 130 }} dataSource={tiposVialidades} />
                      </Form.Item>
                      <Form.Item
                        name="vialidad_entre"
                        label="Nombre de la 1er vialidad con la que cruza"
                        // rules={rules.required}
                      >
                        <Input maxLength={170} allowClear />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item noStyle>
                    <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                      <Form.Item
                        name="tipo_de_vialidad_y_calle"
                        // rules={rules.required}
                        label="Tipo"
                      >
                        <Select style={{ width: 130 }} dataSource={tiposVialidades} />
                      </Form.Item>
                      <Form.Item
                        name="vialidad_y_calle"
                        label="Nombre de 2da Vialidad de Cruce"
                        // rules={rules.required}
                      >
                        <Input maxLength={170} allowClear />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default CorredoresDeValor;
