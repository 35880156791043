/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  EnvironmentOutlined,
} from '@ant-design/icons';
import Predio from '../views/Predio/Predio';

export default {
  title: 'Predios',
  icon: () => <EnvironmentOutlined />,
  showInSidebar: true,
  type: 'private',
  component: Predio,
  // children: [
  //   {
  //     title: 'Predio',
  //     icon: null,
  //     showInSidebar: true,
  //     component: Predio,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Terreno del Predio',
  //     icon: null,
  //     showInSidebar: true,
  //     component: blank,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Regimen de Propiedad',
  //     icon: null,
  //     showInSidebar: true,
  //     component: RegimenDePropiedad,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Cálculo de Valor',
  //     icon: null,
  //     showInSidebar: true,
  //     component: blank,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Categorías de Predio',
  //     icon: null,
  //     showInSidebar: true,
  //     component: CategoriasDePredio,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Usos de Predios',
  //     icon: null,
  //     showInSidebar: true,
  //     component: UsosDePredios,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Tasas de Construcción de Predios',
  //     icon: null,
  //     showInSidebar: true,
  //     component: blank,
  //     type: 'private',
  //     children: [
  //       {
  //         title: 'Rurales',
  //         icon: null,
  //         showInSidebar: true,
  //         component: TasaDeConstruccionDePredioRural,
  //         type: 'private',
  //       },
  //       {
  //         title: 'Urbanas',
  //         icon: null,
  //         showInSidebar: true,
  //         component: TasaDeConstruccionDePredioUrbano,
  //         type: 'private',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Tasas de Predios',
  //     icon: null,
  //     showInSidebar: true,
  //     type: TasaDePredioUrbano,
  //     children: [
  //       {
  //         title: 'Rurales',
  //         icon: null,
  //         showInSidebar: true,
  //         component: TasaDePredioRural,
  //         type: 'private',
  //       },
  //       {
  //         title: 'Urbanas',
  //         icon: null,
  //         showInSidebar: true,
  //         component: TasaDePredioUrbano,
  //         type: 'private',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Tasas Dap',
  //     icon: null,
  //     showInSidebar: true,
  //     type: 'private',
  //     children: [
  //       {
  //         title: 'Rurales',
  //         icon: null,
  //         showInSidebar: true,
  //         component: TasaDAPRural,
  //         type: 'private',
  //       },
  //       {
  //         title: 'Urbanas',
  //         icon: null,
  //         showInSidebar: true,
  //         component: TasaDAPUrbano,
  //         type: 'private',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Clasificación de Construcción',
  //     icon: null,
  //     showInSidebar: true,
  //     component: ClasificacionDeConstruccion,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Estado de Construcción',
  //     icon: null,
  //     showInSidebar: true,
  //     component: EstadoDeConstruccion,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Calidad de Construcción',
  //     icon: null,
  //     showInSidebar: true,
  //     component: CalidadDeConstruccion,
  //     type: 'private',
  //   },
  //   {
  //     title: 'Tipos de Construcción',
  //     icon: null,
  //     showInSidebar: true,
  //     component: TiposDeConstruccion,
  //     type: 'private',
  //   },
  // ],
};
