import Moment from 'moment';

export const dateRegExps = [
  /^\d{4}-\d{2}-\d{2}$/,
  /^\d{2}-\d{2}-\d{4}$/,
];
export const fullDateRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)-\d{2}:\d{2}$/;
export const dateAmericaMazatlanRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}-\d{2}:\d{2}/;
export const dateTimeArizonaTimeRegexExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}/;
export const dateTimeJuarezRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMATS = ['YYYY-MM-DD', 'DD-MM-YYYY'];
export const TIME_FORMAT = 'hh:mm:ss';
export const DATE_FRONTEND_FORMAT = 'DD/MM/YYYY';

const isJSON = (val) => {
  try {
    const parsed = JSON.parse(val);
    return parsed;
  } catch {
    return false;
  }
};

const formatReceivedValues = (values = {}) => {
  const keys = Object.keys(values);
  const clone = {};
  keys.forEach((prop) => {
    const value = values[prop];
    clone[prop] = value;
    // check to discart falsy values
    if ((value === null || value === undefined || value === '')) {
      delete clone[prop];
    }
    const regexIdx = dateRegExps.findIndex((e) => e.test(value));
    if (regexIdx !== -1) {
      clone[prop] = new Moment(value, [DATE_FORMATS[regexIdx]]);
    }
    if (fullDateRegExp.test(value)) {
      clone[prop] = new Moment(new Moment(value).local().format());
    }
    if (dateAmericaMazatlanRegExp.test(value)) {
      clone[prop] = new Moment(value, [DATE_FORMAT]);
    }
    if (dateTimeArizonaTimeRegexExp.test(value)) {
      clone[prop] = new Moment(value, [DATE_FORMAT]);
    }
    if (dateTimeJuarezRegex.test(value)) {
      clone[prop] = new Moment(value, [DATE_FORMAT]);
    }

    if ((prop === 'direccion' || prop === 'domicilio')) {
      const parsedValue = isJSON(value);
      if (/^[0-9]$/.test(value)) {
        clone[prop] = value;
      } else if (parsedValue) {
        clone[prop] = parsedValue;
      }
    }
  });
  clone.key = clone.id;
  return clone;
};

export const formatReceived = (values) => {
  if (Array.isArray(values)) {
    return values.map((e) => formatReceivedValues(e));
  }
  return formatReceivedValues(values);
};

export default (values, options = {}, fullDate = false) => {
  const {
    files = [],
    formData: asFormData,
    clean = true,
  } = options;
  const keys = Object.keys(values);
  const clone = {};
  keys.forEach((prop) => {
    const value = values[prop];
    clone[prop] = value;
    // check to discart falsy values
    if (clean && (value === null || value === undefined || value === '')) {
      delete clone[prop];
    } else if ((value === null || value === undefined || value === '')) {
      clone[prop] = null;
    }

    // check if the values is a date, only in get
    if (value instanceof Date || dateRegExps.some((e) => e.test(value))) {
      clone[prop] = new Moment(value);
    }

    // check if the value is a Moment instance, only if not get
    if (Moment.isMoment(value)) {
      clone[prop] = value?.format(DATE_FORMAT);
    }
    if (Moment.isMoment(value) && fullDate) {
      // eslint-disable-next-line prefer-template
      clone[prop] = value?.format(DATE_FORMAT + '[T]HH:mm:ss[Z]');
    }

    // checking if the value is a instance of file
    if (files.includes(prop)) {
      if (value?.file instanceof File) {
        clone[prop] = value.file;
      } else if (!asFormData && !clean) {
        clone[prop] = null;
      } else {
        delete clone[prop];
      }
    }

    // checking if the value is a address object in JSON
    if (['direccion', 'domicilio', 'domicilio_fiscal'].includes(prop)) {
      const parsedValue = isJSON(value);
      if (/^[0-9]$/.test(value)) {
        clone[prop] = value;
      } else if (parsedValue) {
        clone[prop] = parsedValue;
      }
    }
  });
  if (asFormData) {
    const formData = new FormData();
    const valuesKeys = Object.keys(clone);
    valuesKeys.forEach((key) => {
      formData.append(`${key}`, clone[key]);
    });
    return formData;
  }
  return clone;
};

export const discartFalsyValues = (values = {}, config = {}) => {
  const {
    numbers = false,
  } = config;
  const keys = Object.keys(values);
  const clone = {};
  keys.forEach((prop) => {
    const value = values[prop];
    if (value !== null && value !== 'N/A' && value !== '') {
      clone[prop] = value;
    }
    if (numbers) {
      if (/^(\d+)[.]?(\d+)?$/.test(value)) {
        clone[prop] = parseFloat(value) >= 0 ? parseFloat(value) : null;
      } else {
        clone[prop] = null;
      }
    }
  });
  return clone;
};

export const hasFiles = (values = {}, files = []) => files
  .some((prop) => values[prop] && typeof values[prop] !== 'string');
