const percentajeRegExp = /(\d+)(.[\d+]{1,2})?/;

export const percentaje = async (rule, val) => {
  const value = parseFloat(val) || 0;
  if (value) {
    if (percentajeRegExp.test(value)) {
      if (value <= 0) {
        throw new Error('El campo debe ser mayor a 0');
      } else if (value > 100) {
        throw new Error('El campo debe ser menor a 100');
      }
    } else {
      throw new Error('Ingrese un número válido');
    }
  }
};

export default {
  percentaje,
};
