/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Typography,
  // message,
} from 'antd';
import { connect } from 'react-redux';
import {
  MailOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import '../index.scss';
import logoEntity from '../../../assets/logo.png';
import API from '../../../utils/api';
import fetchSome from '../../../utils/fetchSome';
import { defSigob, themesAsArray } from '../../App/components/themes';
import { SET_THEME } from '../../../store/reducers/app';
import { LOGIN } from '../../../store/reducers/auth';
import { SET_ESTADOS_GLOBALES } from '../../../store/reducers/catalogs';

const { Title, Text } = Typography;

const Login = ({ dispatch, onError }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { email, password } = values;
      const response = await API.post('usuarios/login/', {
        email,
        password,
      });
      if (response?.status === 200) {
        const userInfo = await API.get('usuarios/id', {
          headers: {
            authorization: `Bearer ${response.data.access}`,
          },
        });
        if (userInfo?.status === 200) {
          const { data } = userInfo;
          const { entidad, roles } = data;
          delete data.entidad;
          delete data.roles;
          delete data.corte;
          dispatch({
            type: LOGIN,
            payload: {
              token: response.data.access,
              refreshToken: response.data.refresh,
              user: { ...data },
              lastLogin: response.data.last_login,
              roles,
              entidad,
              periodoFiscalSelected: entidad.periodo_fiscal_vigente,
            },
          });
          const theme = themesAsArray.find((t) => t.name === data.tema) || defSigob;
          dispatch({
            type: SET_THEME,
            payload: theme,
          });
          await fetchSome('catalogos/estados-globales/', SET_ESTADOS_GLOBALES);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    email: [
      {
        type: 'email',
      },
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    password: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="end" className="container-login">
      <Col className="bgContainer" />
      <Col className="footer">
        <Title style={{ fontWeight: 'bold', marginBottom: 15 }} level={2}>
          Sistema Integral de Catastro
        </Title>
      </Col>
      <Col xs={24} sm={24} md={6} id="login-wrapper">
        <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
          <Col md={24} sm={12} id="login-container">
            <Row style={{ marginBottom: 25 }}>
              <img src={logoEntity} width="150" alt="" />
            </Row>
            <br />
            <Title
              style={{
                fontWeight: 'bold',
                color: 'gray',
              }}
              level={2}
            >
              Bienvenido
            </Title>
            <br />
            <Form
              name="login-form"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              hideRequiredMark
              scrollToFirstError
            >
              <Form.Item
                name="email"
                rules={rules.email}
                label="Correo"
                hasFeedback
              >
                <Input prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={rules.password}
                label="Contraseña"
                hasFeedback
              >
                <Input.Password prefix={<LockOutlined />} />
              </Form.Item>

              <Form.Item style={{ height: 32 }}>
                <Button
                  type="link"
                  className="auth"
                  style={{ width: '100%', textAlign: 'right' }}
                  onClick={() => history.push('/recuperar-contrasena')}
                >
                  ¿Olvidó su contraseña?
                </Button>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  htmlType="submit"
                  shape="round"
                  className="auth-submit-button"
                  block
                >
                  <span>Iniciar sesión</span>
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Text className="version">
            Versión 1.0
          </Text>
        </Spin>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

export default connect(mapStateToProps)(Login);
