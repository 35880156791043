/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
} from 'antd';

const FormSubmitCustomControls = ({ controls }) => (
  <>
    <Row className="form-submit-controls">
      <Col xs={24} sm={24} md={0}>
        <Row>
          {controls.map((control, idx) => (
            <Button
              type="link"
              onClick={control.onClick}
              key={idx}
            >
              {control.icon}
            </Button>
          ))}
        </Row>
      </Col>
      <Col xs={0} sm={0} md={24}>
        {controls.map((control, idx) => (
          <Button
            type="link"
            onClick={control.onClick}
            key={idx}
          >
            {control.icon}
            {control.text}
          </Button>
        ))}
      </Col>
    </Row>
  </>
);

FormSubmitCustomControls.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.exact({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
  })).isRequired,
};

export default FormSubmitCustomControls;
