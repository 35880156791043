import API from './api';
import { store } from '../store';

export default async function (uri, type) {
  try {
    const res = await API.get(uri);
    if (res && res.status === 200) {
      const payload = res.data || [];
      store.dispatch({
        type,
        payload,
      });
    }
  } catch (err) {
    // onError(err);
  }
}
