import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
  Divider,
} from 'antd';
import {
  SearchOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Moment from 'moment';
import FormSubmitControls from 'components/FormSubmitCustomControls';
import API from 'utils/api';
import Table from 'components/Table';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import {
  RFC_PATTERN,
  CURP_PATTERN,
  digitoVerificador,
  INE_PATTERN,
} from 'utils/patterns';
import { onError } from 'utils/handlers';
import { capitalize } from 'utils/normalizers';
import LadaNumero from './LadaNumero';

const BusquedaAvanzadaPadrones = ({
  visible,
  setVisible,
  callback,
  dataExterno,
  setDataExterno,
  tipoDePadron,
}) => {
  const {
    tiposDeEstablecimiento,
    serviciosDelVehiculo,
    estadosDelVehiculo,
    marcasDeVehiculos,
    lineasVehiculares,
    tiposDeVehiculos,
    clasesDeVehiculos,
  } = useSelector((state) => state.catalogs);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const ciudadanoFullName = (ciudadano) => {
    const {
      nombre,
      apellido_paterno,
      apellido_materno,
    } = ciudadano;
    return `${nombre
      || ''} ${apellido_paterno
      || ''} ${apellido_materno
      || ''}`;
  };

  const contribuyenteFullName = (contribuyente) => {
    const {
      nombre,
      apellido_paterno,
      apellido_materno,
      razon_social,
      tipo_de_persona,
    } = contribuyente;
    return tipo_de_persona === 1 ? `${nombre
      || ''} ${apellido_paterno
      || ''} ${apellido_materno
      || ''}` : razon_social;
  };

  const fullNames = {
    1: ciudadanoFullName,
    15: contribuyenteFullName,
  };

  const onCancel = () => {
    setVisible(false);
    setData([]);
    setDataExterno([]);
    form.resetFields();
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      setSelectedRowKeys([]);
      const values = form.getFieldsValue();
      const keys = Object.keys(values);
      const validProps = keys.filter((prop) => values[prop]);
      let params = {};
      let uri = '';
      params = { ...values };
      switch (tipoDePadron) {
        case 1:
          uri = '/cuentaunicasir/ciudadanos/';
          params = {
            ...values,
            nombre_completo: `${values.nombre
              || ''} ${values.apellido_paterno
              || ''} ${values.apellido_materno
              || ''}`,
          };
          break;
        case 2:
          uri = '/empresas/empresas/';
          break;
        case 3:
          uri = '/catastro/predios/';
          break;
        case 4:
          uri = '/recaudacion/vehiculos/';
          break;
        case 15:
          uri = 'empresas/contribuyentes-caja/';
          break;
        default:
          break;
      }
      if (validProps.length) {
        const response = await API.get(uri, {
          params,
        });

        if (response?.status === 200) {
          if (response?.data?.length || response?.data?.results?.length) {
            let formatedData;
            switch (tipoDePadron) {
              case 1:
              case 15:
                formatedData = response.data.results.map((item) => ({
                  ...item,
                  nombre_completo: `${item.nombre || ''} ${item.apellido_paterno || ''} ${item.apellido_materno || ''}`,
                }));
                break;
              case 2:
              case 3:
              case 4:
                formatedData = response.data;
                break;
              default:
                break;
            }
            setData(formatedData || []);
          } else {
            message.warn('No se encontró ningún padrón relacionado', 2.5);
          }
        }
      } else {
        message.warn('Debe ingresar al menos un campo');
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinishSearch = async (record) => {
    if (!record?.nombre_completo) {
      // eslint-disable-next-line no-param-reassign
      record.nombre_completo = `${record.nombre
        || ''} ${record.apellido_paterno || ''} ${record.apellido_materno || ''}`;
    }
    callback(record);
    onCancel();
  };

  const handleLocalSearch = () => {
    const values = form.getFieldsValue();
    const val = {
      ...values,
      nombre_completo: `${values.nombre} ${values.apellido_paterno || ''} ${values.apellido_materno || ''}`,
    };

    const keys = Object.keys(values);
    const validProps = keys.filter((prop) => values[prop]);
    if (validProps.length) {
      let filtered;
      switch (tipoDePadron) {
        case 1:
          filtered = data
            .filter((e) => e?.nombre_completo.toLowerCase()
              ?.includes(val?.nombre_completo.toLowerCase())
          || e?.RFC?.includes(val?.RFC)
          || e?.email?.includes(val?.email)
          || e?.clave_ciudadana?.includes(val?.clave_ciudadana)
          || e?.numero_de_celular?.includes(val?.numero_de_celular)
          || e?.CURP?.includes(val?.CURP));
          break;
        case 2:
          filtered = data.filter((e) => e?.nombre_comercial.toLowerCase()
            ?.includes(val?.nombre_comercial.toLowerCase())
          || e?.pagina_web?.includes(val?.pagina_web)
          || e?.RFC?.includes(val?.RFC)
          || e?.tipo_de_establecimiento === val?.tipo_de_establecimiento);
          break;

        case 3:
          filtered = data.filter((e) => e?.CURT?.includes(val?.CURT)
          || e?.cuenta_unica_de_predial?.includes(val?.cuenta_unica_de_predial)
          || e?.clave_catastral_estandar?.includes(val?.clave_catastral_estandar)
          || e?.clave_catastral_municipal?.includes(val?.clave_catastral_municipal));
          break;

        case 4:

          filtered = data.filter((e) => e?.numero_de_placa?.includes(val?.numero_de_placa)
          || e?.numero_de_placa === val?.numero_de_placa
          || e?.servicio === val?.servicio
          || e?.estatus_del_vehiculo === val?.estatus_del_vehiculo
          || e?.linea === val?.linea
          || e?.tipo_de_vehiculo === val?.tipo_de_vehiculo
          || e?.clase_del_vehiculo === val?.clase_del_vehiculo
          || e?.modelo_del_vehiculo === val?.modelo_del_vehiculo);
          break;

        default:
          break;
      }
      setData(filtered);
    } else {
      message.warn('Debe ingresar al menos un campo');
    }
  };

  useEffect(() => {
    if (dataExterno.length) {
      setData(dataExterno);
    }
    // eslint-disable-next-line
  }, [dataExterno]);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const add = () => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    const nombre_completo = fullNames[tipoDePadron](match);
    onFinishSearch({ ...match, nombre_completo });
  };

  const rules = {
    email: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    RFC: [
      {
        pattern: RFC_PATTERN,
        message: 'Ingrese un RFC válido',
      },
    ],
    CURP: [
      {
        validator: async (rule, value) => {
          if (value) {
            const validado = value.toUpperCase().match(CURP_PATTERN);
            if (!validado || validado[2] !== digitoVerificador(validado[1])) {
              throw new Error('Ingrese una CURP válida');
            }
          }
        },
      },
    ],
    INE: [
      {
        validator: async (rule, value) => {
          if (value) {
            if (!INE_PATTERN.test(value)) {
              throw new Error('Ingrese un INE válido');
            }
          }
        },
      },
    ],
    nombre: [
      {
        validator: async (rule, value) => {
          const { apellido_paterno, apellido_materno } = form.getFieldsValue();
          if (value && !apellido_paterno && !apellido_materno) {
            throw new Error('Debe ingresar mínimo dos campos referentes a nombre');
          }
        },
      },
    ],
    apellido_paterno: [
      {
        validator: async (rule, value) => {
          const { nombre, apellido_materno } = form.getFieldsValue();
          if (value && !nombre && !apellido_materno) {
            throw new Error('Debe ingresar mínimo dos campos referentes a nombre');
          }
        },
      },
    ],
    apellido_materno: [
      {
        validator: async (rule, value) => {
          const { apellido_paterno, nombre } = form.getFieldsValue();
          if (value && !apellido_paterno && !nombre) {
            throw new Error('Debe ingresar mínimo dos campos referentes a nombre');
          }
        },
      },
    ],
    celular: [
      {
        pattern: /^(?:[0-9]{10}|)$/,
        message: 'Ingrese un número teléfonico válido',
      },
    ],
  };

  const controls = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => onCancel(),
    },
    {
      text: 'Limpiar',
      icon: <DeleteOutlined />,
      onClick: () => {
        setData([]);
        form.resetFields();
      },
    },
  ];

  const columns1 = [
    {
      titleText: 'Clave',
      dataIndex: 'clave_ciudadana',
      key: 'clave_ciudadana',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => {
            const nombre_completo = fullNames[tipoDePadron](record);
            onFinishSearch({ ...record, nombre_completo });
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      titleText: 'Correo',
      dataIndex: 'email',
      key: 'email',
    },
    {
      titleText: 'Nombre Completo',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
    },
    {
      titleText: 'Celular',
      dataIndex: 'numero_de_celular',
      key: 'numero_de_celular',
    },
    {
      titleText: 'CURP',
      dataIndex: 'CURP',
      key: 'CURP',
    },
    {
      titleText: 'RFC',
      dataIndex: 'RFC',
      key: 'RFC',
    },
    {
      titleText: 'Fecha de Nacimiento',
      dataIndex: 'fecha_nacimiento',
      key: 'fecha_nacimiento',
      render: (val) => (val ? new Moment(val, 'DD-MM-YYYY').format('DD/MM/YYYY') : ''),
    },
  ];

  const columns2 = [
    {
      titleText: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => onFinishSearch(record)}
        >
          {text}
        </Button>
      ),
      width: 100,
    },
    {
      titleText: 'RFC',
      dataIndex: 'RFC',
      key: 'RFC',
      width: 200,
    },
    {
      titleText: 'Nombre Comercial',
      dataIndex: 'nombre_comercial',
      key: 'nombre_comercial',
      width: 200,
    },
    {
      titleText: 'Razón Social',
      dataIndex: 'razon_social',
      key: 'razon_social',
      width: 300,
    },
    {
      titleText: 'Página Web',
      dataIndex: 'pagina_web',
      key: 'pagina_web',
      width: 300,
    },
    {
      titleText: 'Tipo de Establecimiento',
      dataIndex: 'tipo_de_establecimiento',
      key: 'tipo_de_establecimiento',
      render: (val) => tiposDeEstablecimiento.find((item) => item.id === val)?.nombre,
      width: 300,
    },
    {
      titleText: 'Domicilio Fiscal',
      dataIndex: 'domicilio_fiscal',
      key: 'domicilio_fiscal',
      render: (val) => {
        if (val?.calle_principal) {
          return `${val.calle_principal} #${val.numero_exterior},
          ${val.codigo_postal.d_tipo_asenta || ''}
          ${val.codigo_postal.d_asenta || ''},
          ${val.codigo_postal.d_codigo}, 
          ${val.codigo_postal.municipio.nom_mun || ''}, 
          ${val.codigo_postal.municipio.estado.nombre_de_AGEE || ''}`;
        }
        return '';
      },
      width: 350,
    },
  ];

  const columns3 = [
    {
      titleText: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => onFinishSearch(record)}
        >
          {text}
        </Button>
      ),
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'CURT',
      dataIndex: 'CURT',
      key: 'CURT',
      width: 300,
    },
    {
      titleText: 'Clave Catastral Estandar',
      dataIndex: 'clave_catastral_estandar',
      key: 'clave_catastral_estandar',
      width: 200,
    },
    {
      titleText: 'Dirección',
      dataIndex: 'direccion',
      key: 'direccion',
      render: (val) => {
        if (val?.calle_principal) {
          return `${val.calle_principal} #${val.numero_exterior}`;
        }
        // if (val?.calle_principal) {
        //   return `${val.calle_principal} #${val.numero_exterior},
        //   ${val?.codigo_postal?.d_tipo_asenta || ''}
        //   ${val?.codigo_postal?.d_asenta || ''},
        //   ${val?.codigo_postal?.d_codigo}`;
        // }
        return '';
      },
      width: 350,
    },
  ];

  const columns4 = [
    {
      titleText: 'Número de Placa',
      dataIndex: 'numero_de_placa',
      key: 'numero_de_placa',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => onFinishSearch(record)}
        >
          {text}
        </Button>
      ),
      width: 300,
    },
    {
      titleText: 'Tipo',
      dataIndex: 'tipo_de_vehiculo',
      key: 'tipo_de_vehiculo',
      width: 200,
      render: (val) => tiposDeVehiculos.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Clase',
      dataIndex: 'clase_del_vehiculo',
      key: 'clase_del_vehiculo',
      width: 200,
      render: (val) => clasesDeVehiculos.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Línea',
      dataIndex: 'linea',
      key: 'linea',
      width: 300,
      render: (val) => lineasVehiculares.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Estatus',
      dataIndex: 'estatus_del_vehiculo',
      key: 'estatus_del_vehiculo',
      width: 300,
      render: (val) => estadosDelVehiculo.find((e) => e.id === val)?.nombre,
    },
  ];

  const ContribuyenteForm = () => (
    <Row gutter={10}>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="clave"
          label="Clave"
          hasFeedback
          normalize={capitalize}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="nombre"
          label="Nombre"
          hasFeedback
          normalize={capitalize}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="apellido_paterno"
          label="Apellido paterno"
          hasFeedback
          normalize={capitalize}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="apellido_materno"
          label="Apellido materno"
          hasFeedback
          normalize={capitalize}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="razon_social"
          label="Razón Social"
          hasFeedback
          normalize={capitalize}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Form.Item
          name="correo_electronico"
          label="Correo electrónico"
          rules={rules.email}
          hasFeedback
          normalize={capitalize}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col md={16} sm={24} xs={24}>
        <LadaNumero
          form={form}
          rules={rules}
          fullSize
          names={{
            lada: 'lada',
            numero_de_telefono: 'numero_de_celular',
          }}
          celular
        />
      </Col>
    </Row>
  );

  return (
    <Modal
      width="90%"
      visible={visible}
      closable={false}
      footer={null}
      title={(
        <Row justify="space-between">
          Búsqueda Avanzada
          <FormSubmitControls
            controls={controls}
          />
        </Row>
        )}
      onCancel={onCancel}
      forceRender
      centered
    >
      <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={dataExterno?.length ? handleLocalSearch : handleSearch}
        >
          {tipoDePadron === 1 && (
            <Row gutter={10}>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Clave ciudadana"
                  rules={rules.required}
                  name="clave_ciudadana"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Nombre"
                  rules={rules.nombre}
                  name="nombre"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Apellido paterno"
                  rules={rules.apellido_paterno}
                  name="apellido_paterno"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Apellido materno"
                  rules={rules.apellido_materno}
                  name="apellido_materno"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Correo"
                  rules={rules.email}
                  name="email"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="No. celular"
                  rules={rules.required}
                  name="numero_de_celular"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="CURP"
                  rules={rules.CURP}
                  name="CURP"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="RFC"
                  rules={rules.RFC}
                  name="RFC"
                  normalize={(val) => val?.toUpperCase()}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          )}
          {tipoDePadron === 3 && (
            <Row gutter={10}>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Cuenta única de predial"
                  name="cuenta_unica_de_predial"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="CURT"
                  name="CURT"
                  normalize={(val) => val.toUpperCase()}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <NumericInput
                  label="Clave catastral estandar"
                  name="clave_catastral_estandar"
                />
              </Col>
              <Col md={6} sm={24} xs={24}>
                <NumericInput
                  label="Clave catastra municipal"
                  name="clave_catastral_municipal"
                />
              </Col>
              <Col span={24}>
                <Divider style={{ marginTop: 0 }}>Por Dirección</Divider>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <NumericInput
                  label="Código postal"
                  name="direccion__codigo_postal"
                />
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Calle principal"
                  name="direccion__calle_principal"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Número exterior"
                  name="direccion__numero_exterior"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          )}
          {tipoDePadron === 2 && (
            <Row gutter={10}>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Razón social"
                  name="razon_social"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Nombre comercial"
                  name="nombre_comercial"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Página web"
                  name="pagina_web"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="RFC"
                  rules={rules.RFC}
                  name="RFC"
                  normalize={(val) => val.toUpperCase()}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Tipo de establecimiento"
                  name="tipo_de_establecimiento"
                >
                  <Select dataSource={tiposDeEstablecimiento} labelProp="nombre" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider style={{ marginTop: 0 }}>Por Dirección</Divider>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <NumericInput
                  label="Código postal"
                  name="domicilio_fiscal__codigo_postal"
                />
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Calle principal"
                  name="domicilio_fiscal__calle_principal"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24}>
                <Form.Item
                  label="Número exterior"
                  name="domicilio_fiscal__numero_exterior"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          )}
          {tipoDePadron === 4 && (
            <Row gutter={10}>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Número de Placa"
                  name="numero_de_placa"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Tipo de Vehiculo"
                  name="tipo_de_vehiculo"
                >
                  <Select
                    dataSource={tiposDeVehiculos}
                    render={(e) => e.nombre}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Línea"
                  name="linea"
                >
                  <Select
                    dataSource={lineasVehiculares}
                    render={(e) => e.nombre}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Modelo"
                  name="modelo_del_vehiculo"
                >
                  <Select
                    dataSource={marcasDeVehiculos}
                    render={(e) => e.nombre}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Clase"
                  name="clase_del_vehiculo"
                >
                  <Select
                    dataSource={clasesDeVehiculos}
                    render={(e) => e.nombre}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Servicio"
                  name="servicio"
                >
                  <Select
                    dataSource={serviciosDelVehiculo}
                    render={(e) => e.nombre}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  label="Estatus"
                  name="estatus_del_vehiculo"
                >
                  <Select
                    dataSource={estadosDelVehiculo}
                    render={(e) => e.nombre}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {tipoDePadron === 15 && (<ContribuyenteForm />)}
          <Row gutter={[10, 5]} justify="end">
            <Col xs={24} sm={24} md={6}>
              <Button block htmlType="submit">
                Buscar
                <SearchOutlined />
              </Button>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                onClick={add}
                block
                disabled={!selectedRowKeys.length}
              >
                Agregar
                <PlusOutlined />
              </Button>
            </Col>
          </Row>
        </Form>
        {[1, 15].includes(tipoDePadron) && (
          <Table
            cols={columns1}
            data={data}
            noControls
            allowSort={false}
            allowSearch={false}
            rowSelection={rowSelection}
            handleOnRowClick={(record) => {
              setSelectedRowKeys([record.id]);
            }}
            rowKey="id"
          />
        )}
        {tipoDePadron === 2 && (
          <Table
            cols={columns2}
            data={data}
            noControls
            allowSort={false}
            allowSearch={false}
            rowSelection={rowSelection}
            handleOnRowClick={(record) => {
              setSelectedRowKeys([record.id]);
            }}
            rowKey="id"
          />
        )}
        {tipoDePadron === 3 && (
          <Table
            cols={columns3}
            data={data}
            noControls
            allowSort={false}
            allowSearch={false}
            rowSelection={rowSelection}
            handleOnRowClick={(record) => {
              setSelectedRowKeys([record.id]);
            }}
            rowKey="id"
          />
        )}
        {tipoDePadron === 4 && (
          <Table
            cols={columns4}
            data={data}
            noControls
            allowSort={false}
            allowSearch={false}
            rowSelection={rowSelection}
            handleOnRowClick={(record) => {
              setSelectedRowKeys([record.id]);
            }}
            rowKey="id"
          />
        )}
      </Spin>
    </Modal>
  );
};

BusquedaAvanzadaPadrones.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  dataExterno: PropTypes.arrayOf({}),
  setDataExterno: PropTypes.func,
  tipoDePadron: PropTypes.number.isRequired,
};

BusquedaAvanzadaPadrones.defaultProps = {
  dataExterno: [],
  setDataExterno: () => {},

};

export default BusquedaAvanzadaPadrones;
