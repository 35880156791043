import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  DatePicker,
  message,
  Tabs,
} from 'antd';

import {
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import Moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import getFormattedValues, { hasFiles, formatReceived } from 'utils/formatValues';
import { isMobile } from 'utils/responsive';
import Table from 'components/Table';
import Password from 'components/Password';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import LadaNumero from 'components/LadaNumero';
import { UPDATE_USER_INFO } from 'store/reducers/auth/index';
import { SET_PERMISOS } from 'store/reducers/catalogs';
import AvatarUploader from 'components/AvatarUploader';
import fetchSome from 'utils/fetchSome';
import Uploader from 'components/Uploader';
import PlainTransfer from 'components/PlainTransfer';
import Select from 'components/Select';
import {
  defaultOptions,
  allRequirementsPassed,
  validatePasswordRequirements,
} from 'utils/passwordRequirements';
import { onError, onSuccess } from 'utils/handlers';

const { TextArea } = Input;
const { TabPane } = Tabs;
const baseURI = 'usuarios/usuarios/';

const Usuarios = () => {
  const user = useSelector(({ auth }) => auth.user);
  const permisos = useSelector(({ catalogs }) => catalogs.permisos);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [formPersonales] = Form.useForm();
  const [formRoles] = Form.useForm();
  const [formPermisos] = Form.useForm();
  const [formSistema] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePwd, setVisiblePwd] = useState(false);
  const [empleados, setEmpleados] = useState([]);
  const [funciones, setFunciones] = useState([]);
  const [justificaciones, setJustificaciones] = useState([]);
  const [isExterno, setIsExterno] = useState(true);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [roles, setRoles] = useState([]);
  const [initRoles, setInitRoles] = useState();
  const [initPermisos, setInitPermisos] = useState();
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [isMutable, setIsMutable] = useState(true);

  const [canalesDePago, setCanalesDePago] = useState([]);
  const [unidadesDeRecaudacion, setUnidadesDeRecaudacion] = useState([]);

  const basicKeys = ['general', 'info_usuario', 'roles', 'permisos', 'sistema'];
  const formInstances = [form, formPersonales, formRoles, formPermisos, formSistema];

  const normalizeDataForTransfer = (_data, prop) => {
    if (_data?.length) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeDataForTransfer(clone.children, prop);
        if (!children.length) {
          delete clone.children;
          return ({
            ...clone,
            value: clone.id,
            title: `${item.id} - ${item.name}`,
            key: clone.id.toString(),
            selectable: item.seleccionable,
            checkable: item.seleccionable,
          });
        }
        return ({
          ...clone,
          value: clone.id,
          title: `${item.id} - ${item.name}`,
          children,
          disabled: true,
          selectable: item.seleccionable,
          key: clone.id.toString(),
        });
      });
      return output;
    }
    return [];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      const resEmpleados = await API.get('/usuarios/funcionario/');
      const usersWithEmpleado = res.data.filter((u) => !u.es_externo && u.empleado)
        .map((u) => u.empleado);
      const empleadosWithFlag = resEmpleados.data.map((e) => ({
        ...e,
        nombre_completo: `${e.nombre} ${e.apellido_paterno} ${e.apellido_materno || ''} `,
        disabled: usersWithEmpleado.includes(e.id),
      }));
      setEmpleados(empleadosWithFlag
        .sort((a, b) => a.disabled.toString().localeCompare(b.disabled)));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (!permisos?.length) {
          await fetchSome('usuarios/permisos/', SET_PERMISOS);
        }

        const resRoles = await API.get('catalogos/grupos/');
        const normalizedRoles = normalizeDataForTransfer(resRoles.data, 'name');
        setRoles(normalizedRoles);

        const resFunciones = await API.get('usuarios/funciones/');
        setFunciones(resFunciones.data);

        const resJustificaciones = await API.get('usuarios/justificaciones/');
        setJustificaciones(resJustificaciones.data || []);

        const resUnidades = await API.get('/configuracion/unidades-de-recaudacion/');
        setUnidadesDeRecaudacion(resUnidades.data);

        const resCanales = await API.get('/configuracion/canales-de-pago/');
        setCanalesDePago(resCanales.data);

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };

    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setOptions(defaultOptions);
    setVisible(false);
    form.resetFields();
    formPersonales.resetFields();
    formPermisos.resetFields();
    formRoles.resetFields();
    formSistema.resetFields();
    setSelectedRowKeys([]);
    setVisiblePwd(false);
    setIsExterno();
    setIsMutable(true);
    setCurrentTabKey('general');
    setInitPermisos([]);
    setInitRoles([]);
  };

  const arrEquals = (array1 = [], array2 = []) => {
    const array1Sorted = array1.slice().sort();
    const array2Sorted = array2.slice().sort();
    if (array1Sorted.length !== array2Sorted.length) {
      return false;
    }
    return array1Sorted.sort().every((val, idx) => val === array2Sorted[idx]);
  };

  const setRolesPermisos = async (key) => {
    const { roles: _roles = [] } = formRoles.getFieldsValue();
    const { permisos: _permisos = [] } = formPermisos.getFieldsValue();
    if (!arrEquals(initRoles, _roles)) {
      await API.put(`/usuarios/agregar-roles/${key}/`, {
        groups: _roles,
      });
    }
    if (!arrEquals(initPermisos, _permisos)) {
      await API.put(`/usuarios/agregar-permisos/${key}/`, {
        user_permissions: _permisos,
      });
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await formPersonales.validateFields();
      await formRoles.validateFields();
      await formPermisos.validateFields();
      await formSistema.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formPersonales.getFieldsValue(),
        ...formSistema.getFieldsValue(),
      };
      const files = ['foto', 'firma_electronica'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormattedValues(values, {
        files,
        formData: config.header,
        clean: false,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues, config);
        if (response?.status === 201) {
          await setRolesPermisos(response.data.id);
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues, config);
        if (response?.status === 200) {
          await setRolesPermisos(response.data.id);
          if (response.data.id === user.id) {
            const res = await API.get('usuarios/id');
            const { roles: _roles } = res.data;
            const { user_permissions: _permisos } = res.data;
            dispatch({
              type: UPDATE_USER_INFO,
              payload: {
                user: res.data,
                roles: [_roles],
                user_permissions: [_permisos],
              },
            });
          }
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, formInstances, setCurrentTabKey);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`usuarios/usuarios/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`${baseURI}${key}`);
      if (res?.status === 200 && res.data) {
        const record = res.data;
        const {
          roles: _roles,
          permisos: _permisos,
        } = record;
        setIsExterno(!record.es_externo);
        setInitRoles(_roles);
        setInitPermisos(_permisos);
        formRoles.setFieldsValue({
          roles: _roles,
        });
        formPermisos.setFieldsValue({
          permisos: _permisos,
        });
        formPersonales.setFieldsValue(formatReceived(record));
        formSistema.setFieldsValue(record);
      }
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    const [id] = selectedRowKeys;
    if (user.id !== id) {
      setVisibleAlert(true);
    } else {
      message.info('No puede eliminarse el usuario de la sesión actual');
    }
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    if (fromBasic && toBasic) {
      setCurrentTabKey(key);
    }
  };

  let timeout = null;
  const onFormValuesChange = (changedValues, allValues) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { password } = changedValues;
      if (password) {
        const newOps = validatePasswordRequirements(password, 33);
        setOptions(newOps);
      } else if (!allValues.password) {
        setOptions(defaultOptions);
      }
      formPersonales.validateFields(['password', 'confirmPassword']);
    }, 500);
  };

  const columns = [
    {
      titleText: 'Correo Electrónico',
      dataIndex: 'email',
      key: 'email',
      width: isMobile ? '100%' : 300,
    },
    {
      titleText: 'Nombre Completo',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 350,
      render: (_, record) => `${record.first_name} ${record.last_name} ${record.second_last_name || ''}`,
    },
  ];

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [required],
    requiredConditionally: [
      {
        required: isExterno,
        message: 'El campo es requerido',
      },
    ],
    email_alternativo: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    email: [
      required,
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    password: [
      {
        validator: async () => {
          const pwd = formPersonales.getFieldValue('password');
          if (!allRequirementsPassed(pwd)) {
            throw new Error('La contraseña no cumple con los requisitos');
          }
        },
      },
    ],
    confirmPassword: [
      {
        validator: async (rule, value) => {
          const pwd = formPersonales.getFieldValue('password');
          if (value && value !== pwd) {
            throw new Error('Las contraseñas no coinciden.');
          }
        },
      },
    ],

  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              mobileColIndex={0}
              rowKey="id"
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              baseURI={baseURI}
              allowImport
            />
          )
          : (
            <Card
              className="form-container"
              title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Usuario`}
              extra={(
                <FormSubmitControls
                  onFinish={onFinish}
                  onCancel={onCancel}
                  loading={loading}
                />
              )}
              bordered={false}
            >
              <Tabs
                onChange={isMutable ? onChangeTabKey : setCurrentTabKey}
                activeKey={currentTabKey}
                type="card"
              >
                <TabPane
                  tab={(
                    <span>
                      <InfoCircleOutlined />
                      Informacion General
                    </span>
                  )}
                  key="general"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    form={form}
                    name="general"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={{ es_externo: true }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="es_externo"
                          label="¿Es un Funcionario?"
                          hasFeedback
                        >
                          <Select
                            onChange={(value) => {
                              if (value) {
                                form.resetFields();
                                const [key] = selectedRowKeys;
                                const match = key ? data.find((e) => e.id === key) : {};
                                delete match.es_externo;
                                form.setFieldsValue({
                                  is_active: false,
                                  ...match,
                                  es_externo: value,
                                });
                              }
                              setIsExterno(!value);
                            }}
                            trueFalse
                          />
                        </Form.Item>
                      </Col>

                      {!isExterno && (
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            name="funcionario"
                            rules={rules.required}
                            label="Funcionario"
                            hasFeedback
                          >
                            <Select
                              onSelect={(key) => {
                                const selectedEmpleado = empleados
                                  .find((e) => e.id === key);
                                form.setFieldsValue({
                                  ...form.getFieldsValue(),
                                  first_name: selectedEmpleado.nombre,
                                  last_name: selectedEmpleado.apellido_paterno,
                                  second_last_name: selectedEmpleado.apellido_materno,
                                  email: selectedEmpleado.correo_electronico,
                                  lada: selectedEmpleado.lada,
                                  numero_de_celular: selectedEmpleado.numero_de_celular,
                                });
                                formPersonales.setFieldsValue({
                                  ...formPersonales.getFieldsValue(),
                                  observaciones: selectedEmpleado.observacion,

                                });
                                const { roles: _roles, permisos: _permisos } = selectedEmpleado;
                                formRoles.setFieldsValue({
                                  roles: _roles,
                                });
                                formPermisos.setFieldsValue({
                                  roles: _permisos,
                                });
                              }}
                              disabled={isExterno}
                              labelProp="nombre_completo"
                              dataSource={empleados}
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="first_name"
                          rules={rules.required}
                          label="Nombre"
                          hasFeedback
                        >
                          <Input allowClear disabled={!isExterno && !selectedRowKeys.length} />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="last_name"
                          rules={rules.required}
                          label="Apellido Paterno"
                          hasFeedback
                        >
                          <Input allowClear disabled={!isExterno && !selectedRowKeys.length} />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="second_last_name"
                          rules={rules.second_last_name}
                          label="Apellido Materno"
                          hasFeedback
                        >
                          <Input allowClear disabled={!isExterno && !selectedRowKeys.length} />
                        </Form.Item>
                      </Col>
                      {isExterno && (
                        <>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="funcion"
                              label="Función"
                              hasFeedback
                            >
                              <Select dataSource={funciones} />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="justificacion"
                              label="Justificación"
                              hasFeedback
                            >
                              <Select dataSource={justificaciones} />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      <LadaNumero
                        form={form}
                        rules={rules}
                        names={{
                          lada: 'lada',
                          numero_de_telefono: 'numero_de_celular',
                        }}
                        celular
                      />
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="email"
                          rules={rules.email}
                          label="Correo Electrónico"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="email_alternativo"
                          rules={rules.email_alternativo}
                          label="Correo Alternativo"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={8}
                      >
                        <AvatarUploader
                          form={form}
                          imageURL={form.getFieldValue('foto')}
                        />

                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <InfoCircleOutlined />
                      Información del Usuario
                    </span>
                  )}
                  key="info_usuario"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="info_usuario"
                    form={formPersonales}
                    scrollToFirstError
                    onValuesChange={onFormValuesChange}
                  >
                    <Row gutter={10} style={{ width: '100%' }}>
                      <Col xs={24} sm={24}>
                        <Row gutter={10}>
                          <Uploader
                            title="Firma Electrónica"
                            limitMB={2}
                            file={formPersonales.getFieldValue('firma_electronica')}
                            onError={onError}
                            rules={rules.firma_electronica}
                            formItemName="firma_electronica"
                            allowedExtensions={['cer']}
                            allowPreview={false}
                          />

                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="is_active"
                              rules={rules.required}
                              label="Activo"
                              hasFeedback
                            >
                              <Select trueFalse />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="fecha_de_vencimiento"
                              label="Fecha de Vencimiento"
                              hasFeedback
                            >
                              <DatePicker
                                disabledDate={(date) => date.isBefore(new Moment())}
                                format="DD/MM/YYYY"
                                placeholder=""
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={16}>
                            <Form.Item
                              name="observaciones"
                              label="Observaciones"
                              hasFeedback
                            >
                              <TextArea autoSize />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Button
                              onClick={() => setVisiblePwd(!visiblePwd)}
                              className="no-color primary-on-hover"
                              style={{ borderRadius: 6, marginBottom: 10 }}
                            >
                              Asignar contraseña
                              {!visiblePwd ? (<DownOutlined />) : (<UpOutlined />)}
                            </Button>
                          </Col>

                          {visiblePwd && (
                            <Password rules={rules} options={options} />
                          )}
                          <br />
                          <br />
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <InfoCircleOutlined />
                      Roles
                    </span>
                  )}
                  key="roles"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="roles"
                    form={formRoles}
                    onFinish={onFinish}
                  >
                    <Col span={24}>
                      <PlainTransfer
                        dataSource={roles}
                        label="Roles"
                        formItemName="roles"
                        form={formRoles}
                        filterProp="name"
                      />
                    </Col>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <InfoCircleOutlined />
                      Permisos
                    </span>
                  )}
                  key="permisos"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="permisos"
                    form={formPermisos}
                    onFinish={onFinish}
                  >
                    <Col span={24}>
                      <PlainTransfer
                        dataSource={permisos}
                        label="Permisos"
                        formItemName="permisos"
                        form={formPermisos}
                        filterProp="name"
                      />
                    </Col>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <InfoCircleOutlined />
                      Sistema
                    </span>
                  )}
                  key="sistema"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="sistema"
                    form={formSistema}
                    onFinish={onFinish}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="canal_de_pago"
                          label="Canal de Pago"
                        >
                          <Select dataSource={canalesDePago} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="unidad_de_recaudacion"
                          label="Unidad de Recaudación"
                        >
                          <Select dataSource={unidadesDeRecaudacion} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
              </Tabs>
            </Card>
          )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`usuario ${form.getFieldValue('email')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};
export default Usuarios;
