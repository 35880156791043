/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Input,
  Tooltip,
  Col,
  Grid,
  message,
} from 'antd';
import styled from 'styled-components';
import {
  CloseCircleOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Select, { defRender } from 'components/Select';
import { capitalize } from 'utils/normalizers';

const InputSearch = ({
  disabled,
  label,
  name,
  onChange,
  onClickAdvanced,
  rules,
  title,
  setVisibleBusqueda,
  onPressEnter,
  dataSelect,
  dataSelectLabel,
  inputProps,
  onChangeSelect,
  selectRules,
  normalizer,
}) => (
  <Form.Item noStyle style={{ marginBottom: '0px !important' }}>
    <Input.Group style={{ display: 'flex' }} compact>
      {!!dataSelect.length && (
        <Form.Item
          name="content_type"
          style={{
            marginTop: 'auto',
            width: '45%',
            minWidth: 180,
          }}
          rules={selectRules}
          label={dataSelectLabel}
        >
          <Select
            style={{ width: '100%' }}
            dataSource={dataSelect}
            render={(e) => e.model}
            onChange={onChangeSelect}
          />
        </Form.Item>
      )}
      <Form.Item
        style={{ width: '100%' }}
        normalize={normalizer}
        name={name}
        label={(
          <span>
            {label}
            {title && (
              <>
                {' '}
                <Tooltip
                  title={title}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            )}
          </span>
        )}
        rules={rules}
      >
        <Input
          allowClear
          onChange={onChange}
          disabled={disabled}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          onPressEnter={(e) => {
            if (e) {
              e.preventDefault();
            }
            onPressEnter();
            if (inputProps.onPressEnter) {
              inputProps.onPressEnter();
            }
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          disabled={disabled}
          style={{
            marginTop: 23,
          }}
          onClick={() => {
            onPressEnter();
            if (inputProps.onPressEnter) {
              inputProps.onPressEnter();
            }
          }}
        >
          <SearchOutlined />
        </Button>
      </Form.Item>
      {!!onClickAdvanced && (
        <Form.Item className="primary-on-hover" label="">
          <Button
            onClick={() => {
              if (onClickAdvanced) {
                onClickAdvanced();
              } else if (setVisibleBusqueda) {
                setVisibleBusqueda(true);
              }
            }}
            disabled={disabled}
            style={{ marginTop: 23 }}
          >
            ...
          </Button>
        </Form.Item>
      )}

    </Input.Group>
  </Form.Item>
);

InputSearch.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickAdvanced: PropTypes.func,
  rules: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  setVisibleBusqueda: PropTypes.func,
  onPressEnter: PropTypes.func,
  dataSelect: PropTypes.arrayOf(PropTypes.object),
  selectRules: PropTypes.arrayOf(PropTypes.object),
  dataSelectLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChangeSelect: PropTypes.func,
  normalizer: PropTypes.func,
};

InputSearch.defaultProps = {
  disabled: false,
  label: null,
  name: 'q',
  onChange: null,
  onClickAdvanced: null,
  rules: [],
  title: '',
  setVisibleBusqueda: () => { },
  onPressEnter: () => { },
  dataSelect: [],
  dataSelectLabel: null,
  inputProps: {},
  onChangeSelect: () => { },
  selectRules: [],
  normalizer: () => { },
};

const defBreakpoints = {
  xs: 24,
  sm: 24,
  md: 8,
};

const getBreakPoints = (resultProps, breakPoints) => {
  if (Array.isArray(resultProps.dataSource)
    ? resultProps.dataSource.length : resultProps.dataSource) {
    return { ...defBreakpoints, ...resultProps.breakPoints } || defBreakpoints;
  }
  return { ...defBreakpoints, ...breakPoints };
};

export const InputSearchPro = ({
  disabled,
  label,
  name,
  rules,
  onClickAdvanced,
  onPressEnter,
  inputProps,
  prefix,
  tooltip,
  resultProps,
  breakPoints,
  order,
  copyableResult,
}) => {
  const screens = Grid.useBreakpoint();

  const Prefix = () => !!prefix && (
    <Form.Item
      name={prefix.name}
      style={{
        ...prefix.style,
        width: !screens.md ? '100%' : prefix?.style?.width,
      }}
      rules={prefix.rules}
      label={prefix.label}
      hasFeedback
    >
      <Select
        style={{ width: !screens.md ? '100%' : prefix?.style?.width }}
        dataSource={prefix.dataSource}
        render={prefix.render}
        onChange={prefix.onChange}
        disabled={disabled || prefix.disabled}
        allowClear={prefix.allowClear}
      />
    </Form.Item>
  );

  const SearchBox = () => (
    <Form.Item
      style={{ width: '100%' }}
      name={name}
      normalize={(value) => {
        if (typeof inputProps?.normalize === 'function') {
          return inputProps.normalize(value);
        }
        return capitalize(value);
      }}
      label={(
        <span>
          {label}
          {tooltip && (
            <>
              {' '}
              <Tooltip
                title={tooltip}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          )}
        </span>
      )}
      rules={rules}
      hasFeedback
      // normalize={capitalize}
    >
      <Input
        allowClear
        {...inputProps}
        onPressEnter={(e) => {
          if (e) {
            e.preventDefault();
          }
          if (inputProps.onPressEnter) {
            inputProps.onPressEnter();
          }
        }}
        disabled={disabled || inputProps.disabled}
      />
    </Form.Item>
  );

  const SearchButton = () => (
    <Form.Item>
      <Button
        disabled={disabled}
        style={{
          marginTop: 23,
        }}
        onClick={() => {
          onPressEnter();
          if (inputProps.onPressEnter) {
            inputProps.onPressEnter();
          }
        }}
      >
        <SearchOutlined />
      </Button>
    </Form.Item>
  );

  const AdvancedButton = () => !!onClickAdvanced && (
    <Form.Item className="primary-on-hover">
      <Button
        onClick={() => {
          if (onClickAdvanced) {
            onClickAdvanced();
          }
        }}
        disabled={disabled}
        style={{ marginTop: 23 }}
      >
        ...
      </Button>
    </Form.Item>
  );

  const keyLabelRenderFunc = (item) => `${item[resultProps.keyProp]} - ${item[resultProps.labelProp]}`;

  const getText = (obj) => {
    if (resultProps.keyLabelRender) {
      return keyLabelRenderFunc(obj);
    }
    if (resultProps.render) {
      return resultProps.render(obj);
    }
    if (resultProps.labelProp) {
      return defRender(obj[resultProps.labelProp]);
    }
    return defRender(obj);
  };

  if (Array.isArray(resultProps.dataSource)
    ? resultProps.dataSource.length : resultProps.dataSource) {
    return (
      <Col {...getBreakPoints(resultProps, breakPoints)} order={order}>
        <FormItemRelative>
          <Form.Item
            name={resultProps.name}
            label={resultProps.label}
            rules={resultProps.rules}
          >
            <Select
              dataSource={(Array.isArray(resultProps.dataSource)
                ? resultProps.dataSource : [resultProps.dataSource])}
              keyLabelRender={!!resultProps.keyLabelRender}
              keyProp={resultProps.keyProp}
              labelProp={resultProps.labelProp}
              render={resultProps.render}
              async={resultProps.async}
              disabled
            />
          </Form.Item>
          {resultProps.onClear && (
            <CloseCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                resultProps.onClear(e);
              }}
            />
          )}
          {copyableResult && (
            <CopyOutlined
              onClick={(e) => {
                e.stopPropagation();
                const obj = Array.isArray(resultProps.dataSource)
                  ? resultProps.dataSource[0] : resultProps.dataSource;
                const txt = getText(obj);
                navigator.clipboard.writeText(txt);
                message.info('Texto copiado');
              }}
              style={{ right: resultProps.onClear ? 30 : 10 }}
            />
          )}
        </FormItemRelative>
      </Col>
    );
  }

  return (
    <Col {...getBreakPoints(resultProps, breakPoints)} order={order}>
      {screens.xs ? (
        <Form.Item noStyle>
          <Prefix />
          <Input.Group style={{ display: 'flex' }} compact>
            <SearchBox />
            <SearchButton />
            <AdvancedButton />
          </Input.Group>
        </Form.Item>
      ) : (
        <Input.Group style={{ display: 'flex' }} compact>
          <Prefix />
          <SearchBox />
          <SearchButton />
          <AdvancedButton />
        </Input.Group>
      )}
    </Col>
  );
};

const FormItemRelative = styled.div`
  position: relative;
  .anticon-close-circle, .anticon-copy {
    position: absolute;
    bottom: 8px;
    z-index: 2;
  }
  .anticon-close-circle {
    right: 10px;
  }
`;

InputSearchPro.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.object),
  onClickAdvanced: PropTypes.func,
  onPressEnter: PropTypes.func,
  inputProps: PropTypes.object,
  prefix: PropTypes.object,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  resultProps: PropTypes.object,
  breakPoints: PropTypes.object,
  order: PropTypes.number,
  copyableResult: PropTypes.bool,
};

InputSearchPro.defaultProps = {
  disabled: false,
  label: null,
  name: 'q',
  onClickAdvanced: null,
  rules: [],
  onPressEnter: () => { },
  inputProps: {},
  prefix: null,
  tooltip: null,
  resultProps: null,
  breakPoints: {},
  order: null,
  copyableResult: true,
};

export default InputSearch;
