import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Divider,
  message,
} from 'antd';

import { SendOutlined } from '@ant-design/icons';
import API from '../../utils/api';
import getQueryParams from '../../utils/getQueryParams';
import './index.scss';
import Card from './components/Card';

const { Text } = Typography;

const PasswordRecovery = () => {
  const history = useHistory();
  const email = getQueryParams('email', window.location.href);
  if (!email) {
    history.push('/inicio-de-sesion');
  }
  return (
    <Card>
      <Text style={{ textAlign: 'center' }}>
        Enviamos un correo electrónico a
        {' '}
        <b style={{ color: '#D88F4F' }}>
          {email}
        </b>
        {' '}
        con un enlace para recuperar su contraseña.
        Sigue el enlace para continuar con el proceso.
      </Text>
      <br />
      <Button
        style={{
          width: '150px',
        }}
        className="auth-submit-button"
        onClick={() => history.push('/inicio-de-sesion')}
      >
        Continuar
      </Button>
      <Divider style={{ margin: '10px 0' }} />
      <Text>
        ¿No recibiste nuestro correo electrónico?
        <Button
          type="link"
          className="auth"
          style={{
            padding: 0,
            marginLeft: 5,
          }}
          onClick={async () => {
            try {
              const response = await API.post('usuarios/solicitud-restablecer-contrasena', {
                email,
              });
              if (response && response.status === 200) {
                setTimeout(() => {
                  history.push(`recuperacion-de-contrasena?email=${email}`);
                  message.info('El correo fue reenviado');
                }, 300);
              }
              // eslint-disable-next-line no-console
            } catch (err) { console.log(err); }
          }}
        >
          Reenviar
          <SendOutlined />
        </Button>
      </Text>
      <br />
      <Text>
        Puedes cerrar esta ventana si ya has terminado.
      </Text>
    </Card>
  );
};

export default PasswordRecovery;
