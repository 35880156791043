import API from 'utils/api';
import { onError } from 'utils/handlers';

/**
 * GET Codigos postales
 * @param {Object} params
 * @returns {Promise <Array<Object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getCodigosPostales = async (params = null) => {
  try {
    const response = await API.get('catalogos/codigos-postales/', { params });
    return response.data;
  } catch (err) {
    onError(err);
    return { results: [] };
  }
};

/**
 * GET Estados
 * @returns {Promise <Array<Object>>}
 */
export const getEstados = async () => {
  try {
    const response = await API.get('catalogos/estados/');
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Municipios
 * @param {Object} params
 * @returns {Promise <Array<Object>>}
 */
export const getMunicipios = async (params = null) => {
  try {
    const response = await API.get('/catalogos/municipios/', { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Catalogo de paises
 * @param {object} params
 * @returns {Promise<Array<Object>>}
 */
export const getCatalogoDePaises = async (params = null) => {
  try {
    const response = await API.get('catalogos/catalogos-de-paises/', { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};
