import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import NumericInput from 'components/NumericInput';

const baseURI = '/catastro/tipos-de-tenencias/';

const TipoDeTenencia = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, {
          ...values,
        });
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, {
          ...values,
        });
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`/catalogos/tipos-de-dominios/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 90,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 90,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowExport={false}
          />
          <Modal
            visible={visible}
            title={(
              <Row justify="space-between">
                {`${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
                <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
              </Row>
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            forceRender
          >
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
              >
                <Row>
                  <Col span={24}>
                    <NumericInput
                      name="clave"
                      label="Clave"
                      hasFeedback
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="descripcion"
                      rules={rules.required}
                      label="Descripción"
                      hasFeedback
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Modal>
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => {
              setVisibleAlert(false);
              setSelectedRowKeys([]);
            }}
            visible={visibleAlert}
            content={`el tipo de dominio ${form.getFieldValue('descripcion')}`}
          />
        </Spin>
      </Col>
    </Row>
  );
};
export default TipoDeTenencia;
