/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef } from 'react';

const initialData = {
  q: null,
  page: 1,
  next: true,
  results: [],
};

const _getter = () => new Error('Getter is required');

/**
 * @param {func} getter Function to get data
 * @param {object} config
 * @param {boolean} config.auto Auto fetch first page
 * @param {string|number} config.selfValue
 * @returns [] [data, fetchData, loading]
 */
const usePaginatedFetcher = (getter = _getter, config = {}) => {
  const [data, setData] = useState(initialData);
  const loading = useRef(config.auto || false);

  const getDistinctResults = (_results) => [...new Set(_results.map(({ id }) => id))]
    .map((id) => _results.find((e) => e.id === id));

  const fetchData = async (page = 1, q = null, _params = null) => {
    loading.current = true;
    const params = { ..._params };
    params.page = page;
    if (q) {
      params[config?.qName || 'q'] = q;
    }
    const samePage = page === data.page && !(page === 1 && data.page === 1);
    const sameQ = q === data.q;
    if (!(samePage && sameQ)) {
      const _data = await getter(params);
      if (page !== 1) {
        setData((prev) => ({
          q,
          page,
          next: Boolean(_data.next),
          results: getDistinctResults([...prev.results, ..._data.results]),
        }));
      } else {
        setData({
          q: _data.q || null,
          page,
          next: Boolean(_data.next),
          results: _data.results,
        });
      }
    }
    loading.current = false;
  };

  useEffect(() => {
    if (config.auto && !config.selfValue) {
      fetchData();
    }
    return () => {
      loading.current = false;
    };
  }, []);

  return [data, fetchData, loading.current];
};

export default usePaginatedFetcher;
