import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  Typography,
  message,
} from 'antd';
import Select from 'components/Select';
import BusquedaAvanzadaPadrones from 'components/BusquedaAvanzada';
import FormSubmitControls from '../FormSubmitControls';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import API from '../../utils/api';
import { onError } from '../../utils/handlers';
import { createDecimalRegExp } from '../../utils/patterns';
import { InputSearchPro } from '../InputSearch';

const { Text } = Typography;
const baseURI = 'catastro/participe/';
const Participe = ({
  selectedRowKey,
}) => {
  const [searchFound, setSearchFound] = useState([]);
  const [decimalRegExp] = createDecimalRegExp(null, { post: 2 });
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [padrones, setPadrones] = useState([]);
  const [selectedPadron, setSelectedPadron] = useState(1);
  const [visibleBusqueda, setVisibleBusqueda] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const res = await API.get(baseURI, {
          params: {
            predio: selectedRowKey,
          },
        });
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      try {
        const res = await API.get('catalogos/padrones/');
        setPadrones(res.data.filter((e) => [1, 15].includes(e.id)));

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedPadron(1);
    setSearchFound([]);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.padron = form.getFieldValue('padron');
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        values.predio = selectedRowKey;
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const res = await API.patch(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    const currentPercentaje = data.reduce((acum, curr) => acum + curr.porcentaje, 0);
    const diff = 100 - currentPercentaje;
    if (diff) {
      setVisible(true);
      form.setFieldsValue({ porcentaje: diff });
    } else {
      message.info('El porcentaje ya ha alcanzado el 100%, actualicé el porcentaje de los demas participes de ser necesario');
    }
  };

  const ciudadanoFullName = (ciudadano) => {
    const {
      nombre,
      apellido_paterno,
      apellido_materno,
    } = ciudadano;
    return `${nombre
      || ''} ${apellido_paterno
      || ''} ${apellido_materno
      || ''}`;
  };

  const contribuyenteFullName = (contribuyente) => {
    const {
      nombre,
      apellido_paterno,
      apellido_materno,
      razon_social,
      tipo_de_persona,
    } = contribuyente;
    return tipo_de_persona === 1 ? `${nombre
      || ''} ${apellido_paterno
      || ''} ${apellido_materno
      || ''}` : razon_social;
  };

  const fullNames = {
    1: ciudadanoFullName,
    15: contribuyenteFullName,
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      if (![1, 15].includes(match.padron)) {
        setSelectedPadron(1);
        form.setFieldsValue({ padron: 1 });
      }
      if (match?.object_id_participe && [1, 15].includes(match.padron)) {
        const URI = match.padron === 1 ? '/cuentaunicasir/ciudadano-caja/'
          : 'empresas/contribuyentes/';
        const res = await API.get(`${URI}${match.object_id_participe}`);
        setSearchFound([{ ...res.data, nombre_completo: fullNames[match.padron](res.data) }]);
      }
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          await fetchData();
          onCancel();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [required],
    porcentaje: [
      required,
      {
        validator: async (rule, value) => {
          if (value) {
            const [key] = selectedRowKeys;
            const currentPercentaje = (key ? data.filter((e) => e.id !== key) : data)
              .reduce((acum, curr) => acum + curr.porcentaje, 0);
            const diff = 100 - currentPercentaje;
            if (!decimalRegExp.test(value)) {
              throw new Error('Ingrese un entero o decimal válido');
            }
            if (parseFloat(value) > diff) {
              throw new Error(`No puede ser mayor a ${diff}`);
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Descripcion',
      key: 'descripcion',
      dataIndex: 'descripcion',
      width: 250,
    },
    {
      titleText: 'Porcentaje',
      key: 'porcentaje',
      dataIndex: 'porcentaje',
      width: 150,
    },
    {
      titleText: 'Principal',
      key: 'es_participe_principal',
      dataIndex: 'es_participe_principal',
      render: (value) => (
        <span>
          {value ? 'PARTICIPE PRINCIPAL' : ''}
        </span>
      ),
      width: 150,
    },
  ];

  const resetAndValidate = () => {
    setSearchFound([]);
    form.resetFields(['object_id_participe', 'q']);
    form.validateFields(['q']);
  };
  // eslint-disable-next-line no-shadow
  const onChangeInputSearch = ({ target: { value } }) => {
    if (!value?.length) {
      resetAndValidate();
    }
    setTimeout(() => {
      form.setFieldsValue({ q: value });
    });
  };

  const fetchSearch = async (validate = true, _q = null) => {
    try {
      if (validate) {
        form.validateFields(['q']);
      }
      const q = form.getFieldValue('q') || _q;
      if (q) {
        setLoading(true);
        const URI = selectedPadron === 1 ? '/cuentaunicasir/ciudadano-caja/'
          : 'empresas/contribuyentes-caja';
        const res = await API.get(URI, {
          params: { q },
        });
        if (res?.status === 200 && res.data.results?.length) {
          const [participe] = res.data.results;
          setSearchFound([{ participe, nombre_completo: fullNames[selectedPadron](participe) }]);
          form.setFieldsValue({
            object_id_participe: participe.id,
            descripcion: participe.nombre_completo,
          });
          setTimeout(() => {
            form.validateFields(['object_id_participe']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setSearchFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setSearchFound([]);
    }
  };

  return (
    <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowExport={false}
        allowSort={false}
        allowSearch={false}
        mobileColIndex={0}
      />
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        title={(
          <Row justify="space-between">
            {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Participe`}
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
            />
          </Row>
        )}
        onCancel={onCancel}
        forceRender
      >
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            name="participe"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1, padron: 1 }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  tooltip="Ingrese el nombre del partícipe conforme a la documentación presentada"
                  name="descripcion"
                  label="Nombre del Participe"
                  rules={rules.required}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="porcentaje"
                  label="Porcentaje"
                  rules={rules.porcentaje}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="es_participe_principal"
                  label="¿Es partícipe principal?"
                  required
                  hasFeedback
                >
                  <Select trueFalse />
                </Form.Item>
              </Col>
              <InputSearchPro
                name="q"
                rules={rules.required}
                onClickAdvanced={() => setVisibleBusqueda(true)}
                label={selectedPadron && (selectedPadron === 1 ? 'Ciudadano' : 'Contribuyente')}
                inputProps={{
                  onPressEnter: () => fetchSearch(),
                  onChange: onChangeInputSearch,
                }}
                prefix={{
                  // style: { with: '100%' },
                  onChange: setSelectedPadron,
                  dataSource: padrones,
                  rules: rules.required,
                  label: 'Padrón',
                  allowClear: false,
                  name: 'padron',
                }}
                breakPoints={{
                  md: 24,
                }}
                resultProps={{
                  name: 'object_id_participe',
                  label: selectedPadron === 1 ? 'Ciudadano' : 'Contribuyente',
                  rules: rules.required,
                  dataSource: searchFound,
                  keyLabelRender: selectedPadron === 1,
                  keyProp: selectedPadron === 1 ? 'clave_ciudadana' : 'clave',
                  labelProp: selectedPadron === 1 ? 'nombre_completo' : 'razon_social',
                  onClear: resetAndValidate,
                  breakPoints: {
                    md: 24,
                  },
                }}
              />
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={(
          <>
            <Text style={{ fontSize: 16 }}>
              {`¿Desea eliminar el participe ${form.getFieldValue('descripcion')}?`}
            </Text>
            <Text>
              Solo será posible si no ha sido utilizado en otros procesos
            </Text>
          </>
      )}
      />
      <BusquedaAvanzadaPadrones
        visible={visibleBusqueda}
        setVisible={setVisibleBusqueda}
        callback={(values) => {
          setSearchFound([{ ...values }]);
          form.setFieldsValue({
            object_id_participe: values.id,
            descripcion: values.nombre_completo,
          });
        }}
        tipoDePadron={selectedPadron}
      />
    </Spin>
  );
};

Participe.propTypes = {
  selectedRowKey: PropTypes.number,
};

Participe.defaultProps = {
  selectedRowKey: undefined,
};

export default Participe;
