/* eslint-disable no-sequences */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
  Typography,
} from 'antd';

import FormSubmitControls from '../FormSubmitControls';
import CustomTable from '../Table';
import ModalDelete from '../ModalDelete';
import API from '../../utils/api';

const { Text } = Typography;

const Administrators = ({
  onError,
  onSuccess,
  disabled,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get('/usuarios/administradores-de-la-entidad/');
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!data.find((d) => d.email === values.email)) {
        const response = await API.post('/usuarios/administradores-de-la-entidad/', {
          ...values,
        });
        if (response?.status === 201) {
          if (response.data) {
            onSuccess(response, 'Se ha agregado correctamente', 2.5);
            onCancel();
            await fetchData();
          } else {
            message.warning('Ha ocurrido un Error', 2.5);
          }
        }
      } else {
        message.info('El Usuario ya es administrador de la entidad', 2.5);
        onCancel();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
    });
  };

  const onClickAdd = () => {
    onCancel();
    form.setFieldsValue();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`/usuarios/administradores-de-la-entidad/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          setSelectedRowKeys([]);
          form.resetFields();
          await fetchData();
          setVisibleAlert(false);
        } else {
          message.warning('Ha ocurrido un en else', 2.5);
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Correo',
      key: 'email',
      dataIndex: 'email',
      width: 300,
    },
    {
      titleText: 'Nombre',
      key: 'first_name',
      dataIndex: 'first_name',
      width: 150,
    },
    {
      titleText: 'Apellido',
      key: 'last_name',
      dataIndex: 'last_name',
      width: 150,
    },
  ];

  return (
    <>
      <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
        <CustomTable
          cols={columns}
          mobileCols={[columns[1]]}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickDelete,
          }}
          allowEdit={false}
          allowExport={false}
          allowSort={false}
          allowSearch={false}
          disabled={disabled}
          rowKey="id"
          pageSize={5}
        />
        <Modal
          visible={visible}
          closable={false}
          footer={null}
          title={(
            <Row justify="space-between">
              {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Administrador`}
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
              />
            </Row>
          )}
          onCancel={onCancel}
          forceRender
        >
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Correo"
                  rules={rules.required}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item>
                  <Button htmlType="submit" />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => (
            setVisibleAlert(false),
            setSelectedRowKeys([])
          )}
          visible={visibleAlert}
          content={(
            <Text style={{ fontSize: 16 }}>
              {`¿Deseas que ${form.getFieldValue('email')}
               deje de ser administrador de la entidad ?`}
            </Text>
        )}
        />
      </Spin>
    </>
  );
};
Administrators.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
Administrators.defaultProps = {
  disabled: false,
};
export default Administrators;
