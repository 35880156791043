import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Typography,
} from 'antd';

import { onError, onSuccess } from 'utils/handlers';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import PlainTransfer from 'components/PlainTransfer';
import { SET_PERMISOS } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';

const { Text } = Typography;

const Roles = () => {
  const permisos = useSelector(({ catalogs }) => catalogs.permisos);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get('/catalogos/grupos/');
      if (res?.status === 200) {
        setData(res.data);
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      if (!permisos?.length) {
        await fetchSome('usuarios/permisos/', SET_PERMISOS);
      }
      fetchData();
    };

    fetchAll();

    return () => {
      API.source.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setVisibleAlert(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post('/catalogos/grupos/', {
          ...values,
        });
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`/catalogos/grupos/${key}/`, {
          ...values,
        });
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [rowKey] = selectedRowKeys;
        const response = await API.delete(`/catalogos/grupos/${rowKey}`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
        } else {
          message.warning('Ha ocurrido un error', 2.5);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({ ...record });
  };
  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const onClickExport = () => { };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Rol',
      dataIndex: 'name',
      key: 'name',
      width: '100%',
    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin
          tip="Cargando..."
          spinning={loading}
          style={{ width: '100%', height: '100%' }}
        >
          {!visible
            ? (
              <Table
                cols={columns}
                data={data}
                rowSelection={rowSelection}
                rowKey="id"
                mobileColIndex={0}
                handleOnRowClick={handleOnRowClick}
                controls={{
                  onClickAdd,
                  onClickEdit,
                  onClickDelete,
                  onClickExport,
                }}
              />
            )
            : (
              <Col span={24} style={{ height: '100%' }}>
                <Card
                  className="form-container"
                  title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Rol`}
                  extra={(
                    <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
                  )}
                  bordered={false}
                >
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="name"
                          rules={rules.required}
                          label="Nombre del Rol"
                          hasFeedback
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <PlainTransfer
                          dataSource={permisos}
                          label="Permisos"
                          formItemName="permissions"
                          form={form}
                          filterProp="name"
                        />
                      </Col>
                      <Col span={0}>
                        <Form.Item className="submit-controls">
                          <Button
                            id="submit-button"
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            block
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={(
              <>
                <Text style={{ fontSize: 16 }}>
                  {`¿Desea eliminar el Rol ${form.getFieldValue('name')}?`}
                </Text>
                <Text>
                  Solo será posible si no ha sido utilizado en otros procesos
                </Text>
              </>
            )}
          />
        </Spin>
      </Col>
    </Row>
  );
};
export default Roles;
