export const foo = [
  {
    title: 'Validación pendiente',
    content: 'Tienes una cuenta contable pendiente por validar.',
    date: 'Viernes a las 9:25 AM',
    readed: false,
    key: 1,
  },
  {
    title: 'Documento por firmar',
    content: 'Existe mas de un documeto a la espera de ser firmado.',
    date: 'Viernes a las 9:25 AM',
    readed: false,
    key: 2,
  },
  {
    title: 'Periodo cerrado.',
    content: 'Se ha cerrado periodo y no has actualizado tus esquemas.',
    date: 'Viernes a las 9:25 AM',
    readed: true,
    key: 3,
  },
];

export const faqs = [
  {
    key: 1,
    question: '¿If he of the said Office or other. If any Bill shall be?',
    answer: 'If he of the said Office or other. If any Bill shall be',
  },
  {
    key: 2,
    question: '¿If he of the said Office or other. If any Bill shall be?',
    answer: 'If he of the said Office or other. If any Bill shall be',
  },
  {
    key: 3,
    question: '¿If he of the said Office or other. If any Bill shall be?',
    answer: 'If he of the said Office or other. If any Bill shall be',
  },
  {
    key: 4,
    question: '¿If he of the said Office or other. If any Bill shall be?',
    answer: 'If he of the said Office or other. If any Bill shall be',
  },
  {
    key: 5,
    question: '¿If he of the said Office or other. If any Bill shall be?',
    answer: 'If he of the said Office or other. If any Bill shall be',
  },
  {
    key: 6,
    question: '¿If he of the said Office or other. If any Bill shall be?',
    answer: 'If he of the said Office or other. If any Bill shall be',
  },
];
