import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  Typography,
} from 'antd';
import Select from 'components/Select';
import FormSubmitControls from '../FormSubmitControls';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import API from '../../utils/api';
import { onError } from '../../utils/handlers';

const { Text } = Typography;
const baseURI = 'catastro/caracteristicas-de-predio/';
const CatalogosServicios = 'catalogos/caracteristicas-de-predio/';
const ValoresServicios = '/catalogos/valores-de-servicios/';
// const baseURI = 'catastro/atributos-de-predio/';
const Servicio = ({
  selectedRowKey,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [servicioSelected, setServicioSelected] = useState([]);
  const [valorServicio, setValorServicio] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const resServicios = await API.get(CatalogosServicios);
        const res = await API.get(baseURI, {
          params: {
            predio: selectedRowKey,
          },
        });
        if (res.data) {
          const dataServicios = res.data;
          setData(dataServicios);
        }
        // await resServicios.data.forEach((e) => {
        //   e.valores = stringToJson(e.valores);
        // });
        setServicios(resServicios.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      try {
        await fetchData();
        const resValores = await API.get(ValoresServicios);
        setValorServicio(resValores.data);
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = {
        ...form.getFieldsValue(),
        caracteristica_del_predio: servicioSelected.id,
      };
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        values.predio = selectedRowKey;
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const res = await API.patch(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      if (match) {
        const serviciosCatalogo = await API.get(ValoresServicios, {
          params: {
            caracteristica_del_predio: match?.id,
          },
        });
        const serviceSelected = {
          id: match.caracteristica_del_predio.id,
          clave: match.caracteristica_del_predio.clave,
          descripcion: match.caracteristica_del_predio.descripcion,
          valores: serviciosCatalogo.data,
        };
        setServicioSelected(serviceSelected);
        setValorServicio(serviciosCatalogo.data);
        form.setFieldsValue(serviceSelected);
      }
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          await fetchData();
          onCancel();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const changeService = async (value) => {
    const match = await servicios.find((e) => e.id === value);
    if (match) {
      const serviciosCatalogo = await API.get(ValoresServicios, {
        params: {
          caracteristica_del_predio: match?.id,
        },
      });
      const serviceSelected = {
        id: match.id,
        clave: match.clave,
        descripcion: match.descripcion,
        valores: serviciosCatalogo.data,
      };
      setServicioSelected(serviceSelected);
      form.setFieldsValue(
        serviceSelected,
      );
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [required],
  };

  const columns = [
    {
      titleText: 'Clave',
      key: 'caracteristica_del_predio',
      dataIndex: 'caracteristica_del_predio',
      width: 250,
      render: (val) => (val.clave),
    },
    {
      titleText: 'Servicio',
      key: 'caracteristica_del_predio',
      dataIndex: 'caracteristica_del_predio',
      width: 250,
      render: (val) => (val.descripcion),
    },
    {
      titleText: 'Valor',
      key: 'valor',
      dataIndex: 'valor',
      width: 150,
      render: (val) => (valorServicio.find((item) => item.id === val)?.descripcion),
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowExport={false}
        allowSort={false}
        allowSearch={false}
        mobileColIndex={0}
      />
      <Modal
        open={visible}
        closable={false}
        footer={null}
        title={(
          <Row justify="space-between">
            {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} servicio`}
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
            />
          </Row>
        )}
        onCancel={onCancel}
        forceRender
      >
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            name="servicios"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  tooltip="Seleccione el nombre del servicio"
                  name="clave"
                  label="Clave"
                  rules={rules.required}
                >
                  <Select
                    dataSource={servicios}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  tooltip="Seleccione el nombre del servicio"
                  name="descripcion"
                  label="Nombre del servicio"
                  rules={rules.required}
                >
                  <Select
                    dataSource={servicios}
                    onChange={(value) => changeService(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  tooltip="Valor del servicio"
                  name="valor"
                  label="Valor del servicio"
                  rules={rules.required}
                >
                  <Select dataSource={servicioSelected.valores} labelProp="descripcion" />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={(
          <>
            <Text style={{ fontSize: 16 }}>
              {`¿Desea eliminar el servicio ${form.getFieldValue('descripcion')}?`}
            </Text>
            <Text>
              Solo será posible si no ha sido utilizado en otros procesos
            </Text>
          </>
      )}
      />
    </Spin>
  );
};

Servicio.propTypes = {
  selectedRowKey: PropTypes.number,
};

Servicio.defaultProps = {
  selectedRowKey: undefined,
};

export default Servicio;
