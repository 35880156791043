import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Tooltip,
} from 'antd';
// cSpell: disable
import { QuestionCircleOutlined } from '@ant-design/icons';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError } from 'utils/handlers';
import { SET_TIPOS_VIALIDADES } from 'store/reducers/catalogs';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import { getCatalogoDePaises } from 'api/catalogos';
// import Mapa from 'components/Mapa';
import ModalSearch from './ModalSearch';

const Direccion = ({
  useParentID,
  parentID,
  setLoading,
  callback,
  currentURI: _currentURI,
  setFormAddress,
  addressPropName,
  mutable: _mutable,
  // allowMap,
}) => {
  const currentURI = _currentURI.at(-1) !== '/' ? `${_currentURI}/` : _currentURI;
  const [form] = Form.useForm();
  const tiposVialidades = useSelector(({ catalogs }) => catalogs.tiposVialidades);
  const collpasedSidebar = useSelector(({ app }) => app.collpasedSidebar);
  const entidad = useSelector(({ auth }) => auth.entidad);
  const [visibleNoSeCodigo, setVisibleNoSeCodigo] = useState(false);
  const [filteredColonies, setFilteredColonies] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [paises, setPaises] = useState({ results: [] });

  const fetchPaises = async (page = 1, q = null, params = null) => {
    setLoading(true);
    // eslint-disable-next-line no-underscore-dangle
    const _paises = await getCatalogoDePaises({ page, descripcion: q, ...params });
    if ((((paises.q && q) && paises.q === q)
    || (!paises.q && !q))) {
      setPaises((prev) => ({
        ..._paises,
        q,
        page,
        results: [...prev.results, ..._paises.results],
      }));
    } else {
      setPaises({ ..._paises, q });
    }
    setLoading(false);
  };

  const formatValues = (values) => {
    const keys = Object.keys(values);
    const clone = {};
    keys.forEach((prop) => {
      const value = values[prop];
      if (value && value !== 'N/A') {
        clone[prop] = value;
      }
    });
    return clone;
  };

  const getCodigoPostal = async (codigoPostal) => {
    if (!codigoPostal?.d_codigo) {
      const resCP = await API.get(`/catalogos/codigos-postales/${codigoPostal}/`);
      return resCP.data;
    }
    return codigoPostal;
  };

  const getDireccion = async () => {
    try {
      setLoading(true);
      form.resetFields();
      let formattedValues = {};
      if (parentID) {
        const resParent = await API.get(`${currentURI}${useParentID ? parentID : ''}/`);
        if (resParent?.status === 200) {
          const parent = resParent.data;
          const addresData = parent[`${addressPropName}`];
          if (addresData?.id) {
            const codigoPostal = await getCodigoPostal(addresData.codigo_postal);
            const resColonies = await API.get('/catalogos/codigos-postales/', {
              params: {
                d_codigo: codigoPostal.d_codigo,
              },
            });
            formattedValues = formatValues({
              ...addresData,
              pais: codigoPostal.municipio.estado.pais.nombre || undefined,
              estado: codigoPostal.municipio.estado.nombre_de_AGEE || undefined,
              municipio: codigoPostal.municipio.nom_mun || undefined,
              codigo_postal: codigoPostal.d_codigo,
              colonia: codigoPostal.id,
            });
            setFilteredColonies(resColonies.data || []);
          }
        }
      } else {
        const resParent = await API.get(currentURI);
        if (resParent?.status === 200) {
          const parent = resParent.data;
          const addresData = parent[`${addressPropName}`];
          if (addresData?.id) {
            const codigoPostal = await getCodigoPostal(addresData.codigo_postal);
            const resColonies = await API.get('/catalogos/codigos-postales/', {
              params: {
                d_codigo: codigoPostal.d_codigo,
              },
            });
            formattedValues = formatValues({
              ...addresData,
              estado: codigoPostal.municipio.estado.nombre_de_AGEE || undefined,
              municipio: codigoPostal.municipio.nom_mun || undefined,
              codigo_postal: codigoPostal.d_codigo,
              colonia: codigoPostal.id,
              pais: entidad.pais,
            });
            setFilteredColonies(resColonies.data || []);
          }
        }
      }
      setMutable(_mutable);
      setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        form.onFinishHandler = onFinishHandler;
        if (setFormAddress) {
          setFormAddress(form);
        }
        form.setFieldsValue(formattedValues);
        setLoading(false);
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  /**
    * Metodo para manejar peticiones
   */
  const onFinish = async (_continue = true) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const resPatch = await API.patch(`${currentURI}${useParentID ? parentID : ''}/`, {
        [`${addressPropName}`]: {
          ...values,
          codigo_postal: values.colonia,
        },
      });
      if (resPatch?.status === 200) {
        message.info('Dirección agregada correctamente');
        setTimeout(async () => {
          callback(resPatch.data.address, _continue);
          if (_continue) {
            await getDireccion();
          }
        });
      }
      setTimeout(() => {
        setLoading(false);
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishHandler = async (_, _continue = true) => {
    try {
      await onFinish(null, _continue);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!tiposVialidades?.length) {
          await fetchSome('/usuarios/tipo-vialidad/', SET_TIPOS_VIALIDADES);
        }
        await fetchPaises(1, null, { id: 152 });
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await getDireccion();
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [parentID]);

  const getFromPostalCode = (param) => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-underscore-dangle
      const _pais = form.getFieldValue('pais');
      setTimeout(async () => {
        const res = await API.get('/catalogos/codigos-postales/', {
          params: {
            d_codigo: param,
          },
        });
        const { results } = res?.data;
        const resultsFromCountry = results.filter((e) => e.municipio.pais === _pais);
        if (resultsFromCountry.length) {
          const [firstItem] = resultsFromCountry;
          if (resultsFromCountry.length === 1) {
            form.setFieldsValue({
              colonia: firstItem.id,
              codigo_postal: firstItem.d_codigo,
              estado: firstItem.municipio.estado.nombre_de_AGEE,
              municipio: firstItem.municipio.nom_mun,
              pais: firstItem.municipio.estado.pais.nombre,
            });
            setFilteredColonies([firstItem]);
          } else {
            form.setFieldsValue({
              codigo_postal: firstItem.d_codigo,
              estado: firstItem.municipio.estado.nombre_de_AGEE,
              municipio: firstItem.municipio.nom_mun,
              colonia: undefined,
            });
            setFilteredColonies(res.data);
          }
        } else {
          message.info(`No se encontro información relacionada al código postal brindado ${results.length ? 'en el país seleccionado' : ''}`);
          form.setFieldsValue({
            codigo_postal: undefined,
            estado: undefined,
            municipio: undefined,
            colonia: undefined,
          });
          setFilteredColonies([]);
        }
      }, 300);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  // cSpell: enable
  const rules = {
    required: [{ required: true }],
    entre_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!form.getFieldValue('tipo_de_vialidad_entre')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    tipo_de_vialidad_entre: [
      {
        validator: async (rule, value) => {
          if (!value && !!form.getFieldValue('entre_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    y_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!form.getFieldValue('tipo_de_vialidad_y_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    tipo_de_vialidad_y_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!form.getFieldValue('y_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
  };

  const onFinishSearch = (values) => {
    const match = Array.isArray(values) ? values[0] : values;
    if (match) {
      form.setFieldsValue({
        estado: match.municipio.estado.nombre_de_AGEE,
        municipio: match.municipio.nom_mun,
        colonia: match.id,
        codigo_postal: match.d_codigo,
      });
      setFilteredColonies([match]);
      setVisibleNoSeCodigo(false);
    } else {
      message.warn('Seleccione un elemento');
    }
  };

  return (
    <>
      <Col span={24}>
        <Form
          name={addressPropName}
          form={form}
          layout="vertical"
          onFinish={() => onFinish()}
          initialValues={{
            pais: entidad ? entidad.pais : '',
            tipo_de_vialidad: 1,
            tipo_de_vialidad_entre: 1,
            tipo_de_vialidad_y_calle: 1,
          }}
          onValuesChange={(changedValues) => {
            if (changedValues.codigo_postal?.length === 5) {
              getFromPostalCode(changedValues.codigo_postal);
            }
          }}
        >
          <Row gutter={10} align="middle">
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label={(
                  <>
                    <span style={{ marginRight: 5 }}>
                      Código Postal
                    </span>
                    {mutable && (
                      <Tooltip
                        title="Ingresar este campo llenara
                            automáticamente estado, municipio y las colonias disponibles"
                      >
                        <QuestionCircleOutlined className="primary-on-hover" />
                      </Tooltip>
                    )}
                  </>
                )}
              >
                <NumericInput name="codigo_postal" maxLength={6} disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => setVisibleNoSeCodigo(true)}
                  className="primary-on-hover"
                  disabled={!mutable}
                >
                  No sé mi código
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="pais"
                label="País"
                rules={rules.required}
              >
                <Select dataSource={paises} scrollCallback={fetchPaises} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="estado"
                label="Estado"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="municipio"
                label="Municipio"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label="Colonia"
                name="colonia"
                rules={rules.required}
              >
                <Select dataSource={filteredColonies} labelProp="d_asenta" disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_exterior"
                label="Número Exterior"
                rules={rules.required}
              >
                <Input maxLength={20} disabled={!mutable} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_interior"
                label="Número Interior"
              >
                <Input maxLength={20} disabled={!mutable} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
              <Form.Item noStyle>
                <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                  <Form.Item
                    name="tipo_de_vialidad"
                    rules={rules.required}
                    label="Tipo"
                  >
                    <Select dataSource={tiposVialidades} disabled={!mutable} />
                  </Form.Item>
                  <Form.Item
                    name="calle_principal"
                    label="Vialidad Principal"
                    rules={rules.required}
                  >
                    <Input maxLength={170} disabled={!mutable} allowClear />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
              <Form.Item noStyle>
                <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                  <Form.Item
                    name="tipo_de_vialidad_entre"
                    label="Tipo"
                    rules={rules.tipo_de_vialidad_entre}
                  >
                    <Select dataSource={tiposVialidades} disabled={!mutable} />
                  </Form.Item>
                  <Form.Item
                    name="entre_calle"
                    rules={rules.entre_calle}
                    label="Entre Vialidad"
                    className="affix-wrapper-without-padding"
                  >
                    <Input maxLength={170} disabled={!mutable} allowClear />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
              <Form.Item noStyle>
                <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                  <Form.Item
                    name="tipo_de_vialidad_y_calle"
                    label="Tipo"
                    rules={rules.tipo_de_vialidad_y_calle}
                  >
                    <Select dataSource={tiposVialidades} disabled={!mutable} />
                  </Form.Item>
                  <Form.Item
                    name="y_calle"
                    rules={rules.y_calle}
                    label="Y Vialidad"
                  >
                    <Input maxLength={170} disabled={!mutable} allowClear />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col sxs={24} sm={24} md={12}>
              <Form.Item
                name="longitud"
                label="Longitud"
              >
                <Input
                  disabled={!mutable}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="latitud"
                label="Latitud"
              >
                <Input
                  disabled={!mutable}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="descripcion_de_la_ubicacion"
                label="Descripción de la Ubicación"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  maxLength={250}
                  disabled={!mutable}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          {/* {allowMap && (
            <Col span={24}>
              <Mapa
                showAddress
                interactive={mutable}
                form={form}
              />
            </Col>
          )} */}
        </Form>
      </Col>
      <ModalSearch
        setLoading={setLoading}
        callback={onFinishSearch}
        setVisible={setVisibleNoSeCodigo}
        visible={visibleNoSeCodigo}
        addressPropName={addressPropName}
      />
    </>
  );
};

Direccion.propTypes = {
  parentID: PropTypes.number,
  callback: PropTypes.func,
  currentURI: PropTypes.string.isRequired,
  addressPropName: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  setFormAddress: PropTypes.func.isRequired,
  mutable: PropTypes.bool,
  useParentID: PropTypes.bool,
  // allowMap: PropTypes.bool,
};

Direccion.defaultProps = {
  parentID: null,
  callback: () => { },
  addressPropName: 'direccion',
  mutable: true,
  useParentID: true,
  // allowMap: true,
};

export default Direccion;
