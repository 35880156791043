import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import socketio from 'socket.io-client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
// import socketEvents from '../../../utils/sockets';
import API from '../../../utils/api';
import {
  UPDATE_ENTIDAD_INFO,
  UPDATE_USER_INFO,
  SET_PERIODO_FISCAL_SELECTED,
} from '../../../store/reducers/auth';

// Components
import Layout from './Layout';
import AuthRoute from '../../../components/AuthRoute';
import { routes } from '../../../routes/index';
import { onError, onSuccess } from '../../../utils/handlers';
// Routes
import NotFound from './NotFound';
import Login from '../../Auth/Login/index';
import ForgotPassword from '../../Auth/ForgotPassword';
import PasswordRecovery from '../../Auth/PasswordRecovery';
import ActivarCuenta from '../../Auth/ActivarCuenta';
import RestablecerContraseña from '../../Auth/RestablecerContrasena';
import CancelarRestablecerContraseña from '../../Auth/CancelarRestablecerContrasena';

// const { REACT_APP_SOCKETS_URL } = process.env;
const AppRouter = () => {
  const dispatch = useDispatch();
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const token = useSelector(({ auth }) => auth.token);
  // const io = useRef();

  // useEffect(() => {
  //   if (token) {
  //     if (!io.current) {
  //       io.current = socketio(REACT_APP_SOCKETS_URL, { auth: { token } });
  //     }
  //     socketEvents(io.current);
  //   } else {
  //     io.current = null;
  //   }
  // }, [token]);

  useEffect(() => {
    const updateInfo = async () => {
      try {
        const response = await API.get('usuarios/id/');
        const { data } = response;
        const { entidad, roles } = data;
        delete data.roles;
        delete data.corte;
        delete data.entidad;
        if (response?.status === 200) {
          dispatch({
            type: UPDATE_USER_INFO,
            payload: {
              user: data,
              roles,
            },
          });
          dispatch({
            type: UPDATE_ENTIDAD_INFO,
            entidad,
          });
          // dispatch({
          //   type: SET_CORTE,
          //   payload: corte,
          // });
          const selected = { ...periodoFiscalSelected };
          const { periodo_fiscal_vigente } = entidad;
          if (selected?.vigente && selected?.id !== periodo_fiscal_vigente?.id) {
            selected.vigente = false;
          } else if (!selected?.vigente && selected?.id === periodo_fiscal_vigente?.id) {
            selected.vigente = true;
          }
          if (selected?.vigente !== periodoFiscalSelected?.vigente) {
            dispatch({
              type: SET_PERIODO_FISCAL_SELECTED,
              payload: selected,
            });
          }
        }
      } catch (err) {
        onError(err);
      }
    };
    if (token) {
      updateInfo();
    }
    // eslint-disable-next-line
  }, [token]);

  const getRoutes = (_routes, parentPath = '') => _routes.map((r) => {
    if (r.children) {
      return getRoutes(r.children, parentPath + r.path);
    }
    if (r.withoutLayout) {
      return (
        <AuthRoute path={parentPath + r.path} type={r.type} key={parentPath + r.path} exact>
          <r.component onSuccess={onSuccess} onError={onError} />
        </AuthRoute>
      );
    }
    return (
      <AuthRoute path={parentPath + r.path} type={r.type} key={parentPath + r.path} exact>
        <Layout onSuccess={onSuccess} onError={onError}>
          <r.component onSuccess={onSuccess} onError={onError} />
        </Layout>
      </AuthRoute>
    );
  });

  let flattedRoutes = getRoutes(periodoFiscalSelected?.vigente
    ? routes : routes.filter((e, i) => i !== 1));
  while (flattedRoutes.find((r) => Array.isArray(r))) {
    flattedRoutes = flattedRoutes.flat();
  }

  return (
    <Router>
      <div className="app-container">
        <Switch>
          {/* Layout routes */}
          {flattedRoutes}
          {/* Guest routes */}
          <AuthRoute path="/" type="guest" exact>
            <Login />
          </AuthRoute>
          <AuthRoute path="/recuperar-contrasena" type="guest" exact>
            <ForgotPassword onSuccess={onSuccess} onError={onError} />
          </AuthRoute>
          <AuthRoute path="/recuperacion-de-contrasena" type="guest" exact>
            <PasswordRecovery />
          </AuthRoute>
          <AuthRoute path="/activar-cuenta" type="guest" exact>
            <ActivarCuenta />
          </AuthRoute>
          <AuthRoute path="/restablecer-contrasena" type="guest" exact>
            <RestablecerContraseña />
          </AuthRoute>
          <AuthRoute path="/cancelar-restablecer-contrasena" type="guest" exact>
            <CancelarRestablecerContraseña />
          </AuthRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;
