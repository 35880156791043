export const defLibre = {
  name: 'Libre',
  color: '#FFFFFF',
  primaryText: '#6853DD',
  cardTitleColor: '#FFFFFF',
  backgroundColor: '#6853DD',
  activeBackgroundColor: '#D257E550',
  scrollbarTrackBackground: '#C4C4C4',
  scrollbarThumbBackground: '#6853DD',
};

export const defSigob = {
  name: 'SiGob',
  color: '#FFFFFF',
  primaryText: '#D88F4F',
  cardTitleColor: '#D88F4F',
  backgroundColor: '#235161',
  activeBackgroundColor: '#D88F4F',
  scrollbarTrackBackground: '#565656',
  scrollbarThumbBackground: '#C4C4C4',
};

export const migob = {
  name: 'MiGob',
  color: '#FFFFFF',
  primaryText: '#D2B25B',
  cardTitleColor: '#D2B25B',
  backgroundColor: '#4D4D4D',
  activeBackgroundColor: '#D2B25B',
  scrollbarThumbBackground: '#C4C4C4',
};

export const huawei = {
  name: 'Huawei',
  color: '#FFFFFF',
  primaryText: '#CF0A2C',
  cardTitleColor: '#CF0A2C',
  backgroundColor: '#232527',
  activeBackgroundColor: '#CF0A2C',
  scrollbarTrackBackground: '#565656',
  scrollbarThumbBackground: '#C4C4C4',
};

export const defMX = {
  name: 'MX',
  color: '#FFFFFF',
  primaryText: '#9D2449',
  cardTitleColor: '#CA9668',
  backgroundColor: '#12322B',
  activeBackgroundColor: '#9D2449',
  scrollbarTrackBackground: '#235161',
  scrollbarThumbBackground: '#C4C4C4',
};

export const dark = {
  name: 'Dark',
  // Sidebar
  color: '#FFFFFF',
  activeColor: '#2D85F8',
  primaryText: '#2D85F8',
  backgroundColor: '#242526',
  activeBackgroundColor: '#5858583C',
  // Body
  titleDivider: '#235161',
  cardTitleColor: '#2D85F8',
  // Navbar
  navBackgroundColor: '#242526',
  hamburger: '#FFFFFF',
  inputBorder: 'none',
  // Body
  inputBackground: '#3A3B3C',
  bodyBackgroundColor: '#171819',
  bodyTableBackgroundColor: '#242526',
  bodyColor: '#FFFFFF',
  // bodyColor: '#868686',
  radioInner: '#2D85F8',
  // General
  scrollbarTrackBackground: '#235161',
  scrollbarThumbBackground: '#C4C4C4',
  cardBackground: '#242526',
  backgroundPaginationItemActive: '#5858583C',
  formItemLabelColor: '#FFFFFF',
  modalHeaderBackground: '#18191A',
  activeSort: '#2D85F8',
  divider: '1px solid #404040',
  dashboardCardsBodyColor: '#3A3B3C',
};

export const jalisco = {
  name: 'Jalisco',
  color: '#FFFFFF',
  primaryText: '#88919C',
  cardTitleColor: '#FFFFFF',
  backgroundColor: '#5C6877',
  activeBackgroundColor: '#88919C',
  colorsContrast: false,
};

export const themesAsArray = [
  defLibre,
  defSigob,
  defMX,
  dark,
  huawei,
  migob,
  jalisco,
];

export default {
  defLibre,
  defSigob,
  defMX,
  dark,
  huawei,
  migob,
  jalisco,
};
