// Constants
export const SET_THEME = 'SET_THEME';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLEAN = 'CLEAN';
export const SET_RECIBOS = 'SET_RECIBOS';

const initialState = {
  collapsedSidebar: false,
  theme: {},
  recibos: [],
};

// Action handlers
const toggleSidebar = (state, { payload }) => ({ ...state, collapsedSidebar: payload });
const setTheme = (state, { payload }) => ({ ...state, theme: { ...payload } });
const setRecibos = (state, { payload }) => ({ ...state, recibos: payload });
const clean = () => ({ ...initialState });

const ACTION_HANDLER = {
  [`${SET_THEME}`]: setTheme,
  [`${TOGGLE_SIDEBAR}`]: toggleSidebar,
  [`${SET_RECIBOS}`]: setRecibos,
  [`${CLEAN}`]: clean,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action) : state;
};
