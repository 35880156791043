import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Modal,
  Button,
  Input,
  Divider,
} from 'antd';
import {
  EllipsisOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { onError } from 'utils/handlers';
import { capitalize } from 'utils/normalizers';

const BusquedaAvanzadaPredio = ({ callback }) => {
  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [visibleFormSearch, setVisibleFormSearch] = useState(false);

  const changeSearch = () => {
    form.resetFields();
    setVisibleFormSearch(!visibleFormSearch);
  };

  const handleSearch = () => {
    try {
      const params = form.getFieldsValue();
      const keys = Object.keys(params);
      const validProps = keys.filter((prop) => params[prop]);
      if (validProps.length) {
        callback(params);
        setVisibleFormSearch(false);
      }
    } catch (err) {
      onError(err);
    }
  };

  const onCancel = () => {
    setVisibleFormSearch(false);
    form.resetFields();
  };

  const handleReset = () => {
    form.resetFields();
    const params = form.getFieldsValue();
    callback(params);
  };

  const style = {
    BusquedaPredio: {
      paddingTop: '1rem',
    },
  };

  return (
    <>
      <Form form={form}>
        <Row style={style.BusquedaPredio}>
          <Input.Group compact>
            <Form.Item name="clave_catastral_municipal">
              <Input
                allowClear
                normalize={capitalize}
                placeholder="Buscar..."
                disabled={visibleFormSearch}
              />
            </Form.Item>
            <Button
              disabled={visibleFormSearch}
              onClick={() => handleSearch()}
            >
              <SearchOutlined />
            </Button>
            <Button onClick={() => changeSearch()}><EllipsisOutlined /></Button>
            <Button onClick={() => handleReset()}>Limpiar</Button>
          </Input.Group>
        </Row>
      </Form>
      <Modal
        width="90%"
        visible={visibleFormSearch}
        closable
        footer={null}
        onCancel={onCancel}
        forceRender
        centered
      >
        <Form
          layout="vertical"
          form={form}
          style={{ zIndex: 1, backgroundColor: 'rgb(255,255,255)' }}
        >
          <Row gutter={10} style={style.BusquedaPredio}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="clave"
                label="Clave"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="cuenta_unica_predial"
                label="Cuenta única predial"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="curt"
                label="CURT"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="clave_catastral_estandar"
                label="Clave catastral estándar"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="clave_catastral_municipal"
                label="Clave catastral municipal"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Divider><strong>Por dirección</strong></Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="direccion__calle_principal"
                label="Calle principal"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Divider><strong>Por propietario</strong></Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="rfc"
                label="RFC"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="curp"
                label="CURP"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="razon_social"
                label="Razón social"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="nombres_de_participes"
                label="Nombre de participes"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item>
                <Button onClick={handleSearch}>
                  <SearchOutlined />
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

BusquedaAvanzadaPredio.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default BusquedaAvanzadaPredio;
