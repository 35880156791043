/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Upload,
  Button,
  Tooltip,
  message,
  Typography,
} from 'antd';

import {
  UploadOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import Previewer from './Previewer';

const { Text } = Typography;

const Uploader = ({
  file,
  title,
  limitMB,
  fullSize,
  // setFile,
  onError,
  formItemName,
  allowedExtensions,
  allowPreview,
  rules,
}) => {
  const defaultBreakPoints = {
    xs: 24,
    sm: 24,
    md: 8,
  };
  const [fileList, setFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState(null);

  const span = { span: 24 };
  const getProps = () => (fullSize ? span : defaultBreakPoints);
  const accept = allowedExtensions.map((e) => `.${e}`).join(',');
  const formats = allowedExtensions.join(', ').toUpperCase();

  const preview = () => {
    if (file && typeof file === 'string') {
      setIFrameUrl(file);
      setVisible(true);
    }
  };

  return (
    <>
      <Col {...getProps()}>
        <Form.Item
          name={formItemName}
          label={(
            <>
              <Text style={{ marginRight: 5 }}>{title}</Text>
              {(allowPreview && file && typeof file === 'string') && (
                <Tooltip title="Click para ver preview">
                  <Button
                    type="link"
                    onClick={preview}
                    icon={<QuestionCircleOutlined />}
                  />
                </Tooltip>
              )}
              {(!allowPreview && file && typeof file === 'string') && (
                <Tooltip
                  title="Por motivos de seguridad este archivo
                    no es accesible, si desea modificarlo suba un archivo"
                >
                  <CheckCircleOutlined style={{ marginLeft: 10, color: '#1bc943' }} />
                </Tooltip>
              )}
            </>
          )}
          rules={rules}
        >
          <Upload
            beforeUpload={() => false}
            onRemove={() => {
              setFileList([]);
              // setFile();
            }}
            onChange={(e) => {
              const { name } = e.file;
              const isValidExt = allowedExtensions.includes(name.split('.').pop());
              if (!isValidExt) {
                message.warning(`Solo se admiten archivos en formato ${formats}`);
              }
              const isLmt = e.file.size / 1024 / 1024 < limitMB;
              if (!isLmt) {
                message.warning(`El archivo no debe sobrepasar los ${limitMB}MB`);
              }
              if (isValidExt && isLmt) {
                setFileList([e.file]);
                // setFile(e.file);
              } else {
                setFileList([]);
                // setFile();
              }
            }}
            fileList={fileList}
            accept={accept}
          >
            <Button>
              <UploadOutlined />
              {' '}
              Subir archivo
            </Button>
          </Upload>
        </Form.Item>
      </Col>
      <Previewer
        visible={visible}
        setVisible={setVisible}
        iFrameUrl={iFrameUrl}
        onError={onError}
      />
    </>
  );
};
Uploader.propTypes = {
  allowPreview: PropTypes.bool,
  fullSize: PropTypes.bool,
  file: PropTypes.any,
  title: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  limitMB: PropTypes.number.isRequired,
  formItemName: PropTypes.string.isRequired,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rules: PropTypes.arrayOf(PropTypes.object),
};
Uploader.defaultProps = {
  fullSize: false,
  file: null,
  allowPreview: true,
  rules: [],
};
export default Uploader;
