import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';

const baseURI = '/catastro/estados-de-construcciones/';

const EstadoDeLaConstruccion = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [mutable, setMutable] = useState(true);

  /*!
    El fetchData se utiliza para recuperar la informacion dentro del endpoint que se usara para
    el llenado de la tabla principal
  */
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  /*
    El useEffect se utilza para que cualquier cosa que se necesite actualizar can cada cambio
    ocurrido
  */
  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  /*
    El onCancel es una funcion utilizada para restaurar todas las variables de estado, y campos
    del formulario a su estado inicial
   */
  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
  ];

  // const requiredRule = {
  //   required: true,
  //   message: 'El campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          loading={loading}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Estado De La Construcción`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="clave"
                  label="Clave"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="descripcion"
                  label="Descripción"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default EstadoDeLaConstruccion;
