import API from 'utils/api';
import { formatReceived } from 'utils/formatValues';
import { onError, onSuccess } from 'utils/handlers';

export const baseURI = 'catastro/predios/';

/**
 * GET Predio
 * @param {Object} params
 * @returns {Promise<Array<object>>}
 */
export const getPredios = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    const output = { ...response.data, results: formatReceived(response.data.results) };
    return output;
  } catch (err) {
    onError(err);
    return { results: [] };
  }
};

/**
 * GET Predio
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getPredio = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};
/**
 *
 * @param {object} values
 *
 * @returns {Promise<object|boolean>}
 */
export const postPredio = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

export const patchPredio = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};
