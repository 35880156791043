import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import getFormattedValues, { formatReceived } from 'utils/formatValues';

export const baseURI = '/empresas/contribuyentes/';

/**
 * GET Contribuyentes
 * @param {Object} params
 * @returns {Promise<Array<object>>}
 */
export const getContribuyentes = async (params = null) => {
  try {
    const response = await API.get(baseURI, { params });
    const output = { ...response.data, results: formatReceived(response.data.results) };
    return output;
  } catch (err) {
    onError(err);
    return { results: [] };
  }
};

/**
 * GET Contribuyente
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getContribuyente = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Contribuyente
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postContribuyente = async (values) => {
  try {
    const response = await API.post(baseURI, getFormattedValues(values));
    onSuccess(response, 'Agregado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Contribuyente
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchContribuyente = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, getFormattedValues(values));
    onSuccess(response, 'Editado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return false;
  }
};
/**
 * DELETE Contribuyente
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteContribuyente = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}/`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
