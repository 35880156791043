import API from 'utils/api';
import getFormattedValues, { formatReceived, hasFiles } from 'utils/formatValues';
import { onError, onSuccess } from 'utils/handlers';

export const baseURI = 'cuentaunicasir/ciudadanos/';

const files = ['foto'];
const headers = { 'Content-Type': 'multipart/form-data' };

/**
 * Funcion para Armar el nombre copleto del ciudadano
 * @param {Object} ciudadano
 * @param {Boolean} showKey
 * @returns Object
 */
export const fullName = (ciudadano, showKey = true) => `${showKey
  && (Object.hasOwn(ciudadano, 'clave_ciudadana') || Object.hasOwn(ciudadano, 'clave'))
  ? `${ciudadano?.clave_ciudadana || ciudadano?.clave || ''} - `
  : ''}${ciudadano?.nombre
  || ''} ${ciudadano?.apellido_paterno
  || ''} ${ciudadano?.apellido_materno
  || ''}`;

/**
 *  Funcion para injectar el nombre completo del ciudadano con o sin clave
 * @param {Array} ciudadanos
 * @returns <Array|Object>
 */
const insertFullName = (ciudadanos) => {
  if (Array.isArray(ciudadanos)) {
    return ciudadanos.map((e) => ({
      ...e,
      nombre_completo: fullName(e),
      nombre_completo_corto: fullName(e, false),
    }));
  }
  return {
    ...ciudadanos,
    nombre_completo: fullName(ciudadanos),
    nombre_completo_corto: fullName(ciudadanos, false),
  };
};

/**
 * GET Ciudadano
 * @param {number} cuentaUnicaID ID cuenta única
 * @returns {Promise<Object>}
 */
export const getCiudadano = async (cuentaUnicaID) => {
  try {
    const response = await API.get(`${baseURI}${cuentaUnicaID}/`);
    return formatReceived(insertFullName(response.data));
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * GET Ciudadanos
 * @param {object} params
 * @returns {Promise<Array<Object>>}
 */
export const getCiudadanos = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return {
      ...response.data,
      results: formatReceived(insertFullName(response.data.results)),
    };
  } catch (err) {
    onError(err);
    return { results: [] };
  }
};

/**
 * POST Ciudadano
 * @param {object} values
 * @returns {Promise<Object>}
 */
export const postCiudadano = async (values) => {
  try {
    const config = hasFiles(values, files) ? { headers } : {};
    const formattedValues = getFormattedValues(values, {
      formData: config.headers,
      files,
      clean: false,
    });
    const response = await API.post(baseURI, formattedValues, config);
    onSuccess(response, 'Agregado correctamente');
    return formatReceived(insertFullName(response.data));
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * PATCH Ciudadano
 * @param {number} id
 * @param {object} values
 * @returns {Promise<Object>}
 */
export const patchCiudadano = async (id, values) => {
  try {
    const config = hasFiles(values, files) ? { headers } : {};
    const formattedValues = getFormattedValues(values, {
      formData: config.headers,
      files,
      clean: false,
    });
    const response = await API.patch(`${baseURI}${id}`, formattedValues, config);
    onSuccess(response, 'Actualizado correctamente');
    return formatReceived(insertFullName(response.data));
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * DELETE Ciudadano
 * @param {number} id
 * @param {object} values
 * @returns {Promise<boolean>}
 */
export const deleteCiudadano = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}/`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
