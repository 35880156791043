/* eslint-disable no-tabs */
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot * {
	background-color: ${({ theme }) => theme.primaryText};
}
.ant-tabs {
	.ant-tabs-tabpane, .ant-tabs-tab {
		color: ${({ theme }) => theme.bodyColor};
	}
}
.ant-collapse {
	background-color: ${({ theme }) => theme.cardBackground};
	.ant-collapse-header {
		background-color: ${({ theme }) => theme.cardBackground};
		color: ${({ theme }) => theme.bodyColor} !important;
	}
}
.ant-layout {
	.ant-typography, .anticon {
		color: ${({ theme }) => theme.bodyColor};
	}
	.ant-layout-sider .logo-sidebar {
		background-color: ${({ theme }) => theme.backgroundColor};
	}
	.ant-layout-content {
    background: ${({ theme }) => theme.contentBackgroundColor};
		label, h1, h2, h3, h4, h5, h6, .anticon, .ant-breadcrumb-link,
		.ant-typography, .ant-breadcrumb-separator, .ant-steps-item-title,
		.ant-upload-text, .ant-upload-hint, .ant-empty-description {
    	color: ${({ theme }) => theme.bodyColor};
		}
		.ant-steps-item-title::after {
			background-color: ${({ theme }) => theme.bodyColor};
		}
	}
}

#layot-drawer {
  .ant-drawer-close * {
		color: ${({ theme }) => theme.color};
  }
  .ant-drawer-header {
    background: ${({ theme }) => theme.backgroundColor};
    .ant-drawer-title {
      color: ${({ theme }) => theme.color};
    }
  }
	.ant-drawer-body {
    background: ${({ theme }) => theme.contentBackgroundColor};
		* {
      color: ${({ theme }) => theme.contentTextColor};
		}
	}
}

.ant-layout {
	.ant-layout-sider {
		background-color: ${({ theme }) => theme.backgroundColor};
		svg, svg path {
			color: ${({ theme }) => theme.color} !important;
			fill: ${({ theme }) => theme.color} !important;
		}
    #side-menu {
		  background-color: ${({ theme }) => theme.backgroundColor};
    }
		.ant-menu-item:active, .ant-menu-submenu-title:active {
      background-color: ${({ theme }) => theme.activeBackgroundColor};
		}
    .ant-menu-sub.ant-menu-inline {
      background-color: ${({ theme }) => theme.backgroundColor};
    }
    .ant-menu-item:not(.ant-menu-item-selected) {
      background-color: ${({ theme }) => theme.backgroundColor};
      color: ${({ theme }) => theme.color};
    }
    .ant-menu-submenu-title {
      color: ${({ theme }) => theme.color};
      span, .anticon  {
        color: ${({ theme }) => theme.color};
      }
    }
    .ant-menu-item-selected {
      background-color: ${({ theme }) => theme.activeBackgroundColor} !important;
      .anticon, a, span {
      	color: ${({ theme }) => theme.color};
				color: ${({ theme }) => theme.activeColor};
			}
    }
    .ant-menu-item:not(.ant-menu-item-selected) {
      span, a {
        color: ${({ theme }) => theme.color};
      }
    }
    .ant-menu-submenu-arrow,
		.ant-menu-submenu-arrow::before,
		.ant-menu-submenu-arrow::after {
			background-image: linear-gradient(to right, #FFFFFF, #FFFFFF);
			background-image: 
			linear-gradient(to right, ${({ theme }) => theme.colorArrow}, ${({ theme }) => theme.colorArrow}) !important;
		}
    .ant-menu-submenu-selected, .ant-menu-submenu-active {
      background-color: ${({ theme }) => theme.activeBackgroundColor};
			.ant-menu-submenu-title span {
				color: ${({ theme }) => theme.activeColor};
			}
			.ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title span {
      	color: ${({ theme }) => theme.color};
			}
      .ant-menu-submenu-arrow,
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background-image: linear-gradient(to right, #FFFFFF, #FFFFFF);
        background-image: 
        linear-gradient(to right, ${({ theme }) => theme.differentArrow}, ${({ theme }) => theme.differentArrow}) !important;
      }
    }
		.ant-menu-submenu-active:not(.ant-menu-submenu-selected) {
      background-color: ${({ theme }) => theme.activeBackgroundColor};
    }
		.ant-menu-item-active:not(.ant-menu-item-selected) {
			background-color: ${({ theme }) => theme.activeBackgroundColor};
		}
		.logo-sidebar .anticon {
			color: ${({ theme }) => theme.color};
		}
	}
  .ant-layout-header {
		background-color: ${({ theme }) => theme.navBackgroundColor};
		.toggle-sidebar-trigger.close * {
			color: ${({ theme }) => theme.hamburger} !important;
		}
		.ant-select-selector {
			border: ${({ theme }) => theme.inputBorder} !important;
		}
  }
	.ant-layout-content {
		background: ${({ theme }) => theme.bodyBackgroundColor} !important;
	}
}
.ant-menu-submenu-popup {
	.ant-menu-item-active,
	.ant-menu-item-selected {
		background-color: ${({ theme }) => theme.activeBackgroundColor} !important;
		.anticon, a, span {
			color: ${({ theme }) => theme.color};
			color: ${({ theme }) => theme.activeColor};
		}
		svg {
			fill: ${({ theme }) => theme.color} !important;
			fill: ${({ theme }) => theme.activeColor} !important;
		}
	}

	.ant-menu-submenu-active > .ant-menu-submenu-title,
	.ant-menu-submenu-selected > .ant-menu-submenu-title {
    svg {
			fill: ${({ theme }) => theme.color} !important;
			fill: ${({ theme }) => theme.activeColor} !important;
		}
		background-color: ${({ theme }) => theme.activeBackgroundColor}  !important;
		color: ${({ theme }) => theme.color}  !important;
		color: ${({ theme }) => theme.activeColor}  !important;
		.ant-menu-submenu-arrow,
		.ant-menu-submenu-arrow::before,
		.ant-menu-submenu-arrow::after {
			background-image:linear-gradient(to right, ${({ theme }) => theme.color}, ${({ theme }) => theme.color}) !important;
			background-image:linear-gradient(to right, ${({ theme }) => theme.activeColor}, ${({ theme }) => theme.activeColor}) !important;
		}
	}
}



.ant-form-item-control-input-content * {
	&:not(.ant-input-group):not(.ant-form-item) {
		background: ${({ theme }) => theme.inputBackground} !important;
		color: ${({ theme }) => theme.bodyColor} !important;
	}
}
.ant-input-affix-wrapper,
.ant-input-affix-wrapper *,
.ant-select * {
	background: ${({ theme }) => theme.inputBackground} !important;
	color: ${({ theme }) => theme.bodyColor} !important;
}


.footer {
	background: ${({ theme }) => theme.cardBackground} !important;
}

.ant-dropdown-menu-item:hover {
	background-color: ${({ theme }) => theme.backgroundColor} !important;
	color: ${({ theme }) => theme.color} !important;
}

.ant-card-head {
	border-bottom: 2px solid ${({ theme }) => theme.backgroundColor} !important;
	border-bottom: 2px solid ${({ theme }) => theme.titleDivider} !important;
	.ant-card-head-title {
		color: ${({ theme }) => theme.primaryText} !important;
	}
}

.ant-popover-open *,
.ant-popover-title .title,
.ant-popover-title .ant-btn:hover * {
	color: ${({ theme }) => theme.primaryText} !important;
}

.ant-modal-header {
	background-color: ${({ theme }) => theme.modalHeaderBackground};
	* {
		color: ${({ theme }) => theme.primaryText} !important;
	}
}

.ant-popover-title {
	color: ${({ theme }) => theme.primaryText} !important;
	background-color: ${({ theme }) => theme.modalHeaderBackground};
	.ant-btn:not(:hover) * {
		color: ${({ theme }) => theme.bodyColor} !important;
	}
}

.ant-popover-arrow {
	background: ${({ theme }) => theme.modalHeaderBackground} !important;
	border-top-color: ${({ theme }) => theme.modalHeaderBackground} !important;
	border-left-color: ${({ theme }) => theme.modalHeaderBackground} !important;
}

.ant-modal-body,
.ant-popover-content .ant-popover-inner-content {
	background-color: ${({ theme }) => theme.cardBackground} !important;
	* {
		color: ${({ theme }) => theme.bodyColor} !important;
	}
}

.primary-text,
.primary-text *,
.ant-breadcrumb > span:last-child,
.ant-tabs-tab-active > .ant-tabs-tab-btn {
	color: ${({ theme }) => theme.primaryText} !important;
}

#help-button {
	background-color: ${({ theme }) => theme.backgroundColor};
}

.ant-radio-checked .ant-radio-inner {
	border-color: ${({ theme }) => theme.backgroundColor};
	border-color: ${({ theme }) => theme.radioInner};
	&::after {
		background-color: ${({ theme }) => theme.backgroundColor};
		background-color: ${({ theme }) => theme.radioInner};
	}
}

::-webkit-scrollbar-thumb {
	background: ${({ theme }) => theme.scrollbarThumbBackground};
}



#submit-button,
#cancel-button {
	* {
		color: ${({ theme }) => theme.bodyColor} !important;
	}
	&:hover * {
		color: ${({ theme }) => theme.primaryText} !important;
	}
}

.form-container {
	.ant-col-24 {
		.ant-typography {
			color: ${({ theme }) => theme.bodyColor} !important;
		}
	}
}

.ant-form-item-label label {
	color: ${({ theme }) => theme.formItemLabelColor} !important;
}
.customCard {
	background-color: ${({ theme }) => theme.cardBackground} !important;
}
.ant-divider-vertical {
	height: 100%;
	width: 0px;
	margin: 0;
	border-left: ${({ theme }) => theme.divider} !important;
}
.ant-modal-content {
	background-color: ${({ theme }) => theme.cardBackground} !important;
}

.modal-delete {
	.ant-modal-title {
		color: ${({ theme }) => theme.bodyColor} !important;
	}
	.ant-modal-body {
		.ant-typography {
			color: ${({ theme }) => theme.bodyColor} !important;
		}
	}
}

.ant-form-item .ant-input-disabled {
	color: ${({ theme }) => theme.bodyColor} !important;
}
.primary-on-hover:not(:disabled):hover,
.primary-on-hover:not(:disabled):hover *,
.primary-on-hover:not(:disabled):active,
.primary-on-hover:not(:disabled):active * {
	color: ${({ theme }) => theme.primaryText} !important;
}
.ant-table .ant-table-thead > tr > th {
	background-color: ${({ theme }) => theme.backgroundColor};
	background-color: ${({ theme }) => theme.bodyBackgroundColor};
}

td.ant-table-cell {
	background-color: ${({ theme }) => theme.bodyTableBackgroundColor} !important;
	color: ${({ theme }) => theme.bodyColor} !important;
}

th.ant-table-cell {
	color: ${({ theme }) => theme.color} !important;
}
th.ant-table-cell .ant-row .ant-col .anticon:hover,
th.ant-table-cell .ant-col .anticon.active {
	color: ${({ theme }) => theme.activeBackgroundColor} !important;
	color: ${({ theme }) => theme.activeSort} !important;
}

.table-controls .ant-btn *,
.table-controls .ant-dropdown-trigger,
.table-controls .ant-dropdown-trigger *  {
	color: ${({ theme }) => theme.bodyColor} !important;
}

::selection,
.ant-tabs-tab-active,
.ant-select-item-option-active *,
.table-controls .ant-dropdown-open,
.ant-layout-header .anticon:hover *,
.table-controls .ant-dropdown-open *,
.ant-layout-header div.username:hover *,
.table-controls .ant-btn:not(:disabled):hover *,
.ant-layout-header .toggle-sidebar-trigger.open *,
.ant-btn-link:not(:disabled):not(.no-color):hover,
.ant-btn-link:not(:disabled):not(.no-color):hover *,
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
	color: ${({ theme }) => theme.primaryText} !important;
	::selection {
		color: ${({ theme }) => theme.backgroundColor} !important;
		background: ${({ theme }) => theme.primaryText} !important;
	}
}

.ant-table-pagination .ant-pagination-item-active {
	background: ${({ theme }) => theme.backgroundColor} !important;
	background: ${({ theme }) => theme.backgroundPaginationItemActive} !important;
}
.ant-drawer-title,
#dashboard .ant-card-head * {
	color: ${({ theme }) => theme.color} !important;
	::selection {
		color: ${({ theme }) => theme.backgroundColor} !important;
		background: ${({ theme }) => theme.primaryText} !important;
	}
}
#dashboard {
	.ant-card-head {
		background-color: ${({ theme }) => theme.backgroundColor};
		* {
			color: ${({ theme }) => theme.color} !important;
			color: ${({ theme }) => theme.cardTitleColor} !important;
		}
	}
	.ant-card-body {
		background-color: ${({ theme }) => theme.dashboardCardsBodyColor} !important;
		.ant-typography.pending-text:nth-child(2) {
			color: ${({ theme }) => theme.bodyColor} !important;
		}
	}
}

::selection {
	background: ${({ theme }) => theme.backgroundColor} !important;
}

.ant-btn:not(:disabled) {
	:active *, :focus *, :hover * {
		color: ${({ theme }) => theme.primaryText} !important;
	}
	&:not(.ant-btn-link) {
		:active *, :focus *, :hover * {
			border-color: ${({ theme }) => theme.primaryText} !important;
		}
	}
}

.submit-delete {
	&:focus {
		border: 1px solid ${({ theme }) => theme.primaryText} !important
	}
}
`;
