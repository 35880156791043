import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Button,
  Form,
  message,
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';

import API from '../../../utils/api';
import FormSubmitControls from '../../../components/FormSubmitControls';
import Select from '../../../components/Select';
import { SET_PERIODO_FISCAL_SELECTED } from '../../../store/reducers/auth/index';
import { onError } from '../../../utils/handlers';

const ModalChangeFiscalPeriod = ({
  editable,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [periodosFiscales, setPeriodosFiscales] = useState([]);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);

  const fetchData = async () => {
    const res = await API.get('catalogos/periodos-fiscales/');
    setPeriodosFiscales(res.data);
  };
  useEffect(() => {
    if (visible) {
      fetchData();
    }
    return () => API.tryCancel;
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
  };

  const periodoAsStr = (periodo = periodoFiscalSelected) => {
    if (!periodo?.periodo) {
      return 'No seleccionado';
    }
    const periodoStr = `${periodo?.periodo} - ${periodo?.identificador}`;
    const vigenteStr = `${periodo?.vigente ? ' (vigente)' : ''}`;
    return `${periodoStr}${vigenteStr}`;
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const { periodoFiscal: id } = form.getFieldsValue();
      const payload = periodosFiscales.find((e) => e.id === id);
      dispatch({
        type: SET_PERIODO_FISCAL_SELECTED,
        payload,
      });
      setTimeout(() => {
        onCancel();
        message.info(`Periodo Fiscal ${periodoAsStr(payload)} seleccionado correctamente`, 2);
      });
    } catch (err) {
      onError(err);
    }
  };

  const rules = {
    periodoFiscal: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <>
      Periodo Fiscal:
      {' '}
      {editable ? (
        <Button onClick={() => setVisible(true)} type="link" style={{ padding: 0, height: 'auto' }}>
          {periodoAsStr()}
          {' '}
          <EditOutlined />
        </Button>
      ) : periodoFiscalSelected.periodo}

      <Modal
        title={(
          <Row justify="space-between">
            Selección de Periodo Fiscal
            <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
          </Row>
        )}
        visible={visible}
        footer={null}
        closable={false}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{ periodoFiscal: periodoFiscalSelected?.id }}
        >
          <Col span={24}>
            <Form.Item
              name="periodoFiscal"
              rules={rules.periodoFiscal}
              hasFeedback
              label="Periodo fiscal"
            >
              <Select
                dataSource={periodosFiscales}
                render={(e) => `${e.periodo} - ${e.identificador}${e.vigente ? ' (Vigente)' : ''}`}
              />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

ModalChangeFiscalPeriod.propTypes = {
  editable: PropTypes.bool,
};

ModalChangeFiscalPeriod.defaultProps = {
  editable: true,
};

export default ModalChangeFiscalPeriod;
