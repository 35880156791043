export const SET_LADAS = 'CATALOGS__SET_LADAS';
export const SET_ESTADOS_GLOBALES = 'CATALOGS__SET_ESTADOS_GLOBALES';
export const SET_GENEROS = 'CATALOGS__SET_GENEROS';
export const SET_METODOS_DE_PAGO = 'CATALOGS__SET_METODOS_DE_PAGO';
export const SET_TIPOS_VIALIDADES = 'CATALOGS__SET_TIPOS_VIALIDADES';
export const SET_CUENTAS_CONTABLES = 'CATALOGS__SET_CUENTAS_CONTABLES';
export const SET_CONFIG_MASCARA_CONTABLE = 'CATALOGS__SET_CONFIG_MASCARA_CONTABLE';
export const SET_TIPOS_PODER = 'CATALOGS__SET_TIPOS_PODER';
export const SET_PERIODOS = 'CATALOGS__SET_PERIODOS';
export const SET_TIPOS_PRESUPUESTOS = 'CATALOGS__SET_TIPOS_PRESUPUESTOS';
export const SET_ESTADOS_CIVILES = 'CATALOGS__SET_ESTADOS_CIVILES';
export const SET_ESTADOS_DE_LOS_FUNCIONARIOS = 'CATALOGS__SET_ESTADOS_DE_LOS_FUNCIONARIOS';
export const SET_PERMISOS = 'CATALOGS__SET_PERMISOS';
export const SET_PADRONES = 'CATALOGS__SET_PADRONES';
export const SET_PAISES = 'CATALOGS__SET_PAISES';
export const CLEAR = 'CATALOGS__CLEAR';

const initialState = {
  ladas: [],
  estadosGlobales: [],
  tiposVialidades: [],
  cuentasContables: [],
  tiposDePoder: [],
  configMascaraContable: null,
  estadosCiviles: [],
  estadosDeLosFuncionarios: [],
  generos: [],
  padrones: [],
};

const formatedPayload = (payload) => (Array.isArray(payload) ? payload : []);

// Action handlers
const setLadas = (state, payload) => ({ ...state, ladas: payload });
const setEstadosGlobales = (state, payload) => ({ ...state, estadosGlobales: payload });
const setTiposVialidades = (state, payload) => ({ ...state, tiposVialidades: payload });
const setCuentasContables = (state, payload) => ({ ...state, cuentasContables: payload });
const setTiposDePoder = (state, payload) => ({ ...state, tiposDePoder: payload });
const setPeriodos = (state, payload) => ({ ...state, periodos: payload });
const setTiposPresupuestos = (state, payload) => ({ ...state, tiposPresupuestos: payload });
const setEstadosCiviles = (state, payload) => ({ ...state, estadosCiviles: payload });
const setGeneros = (state, payload) => ({ ...state, generos: payload });
const setPermisos = (state, payload) => ({ ...state, permisos: payload });
const setPaises = (state, { payload }) => ({
  ...state,
  paises: formatedPayload(payload),
});

const setConfigMascaraContable = (state, payload) => {
  const config = payload.find((c) => c.tipo_de_mascara === 1);
  return ({ ...state, configMascaraContable: config || null });
};
const setEstadosDeLosFuncionarios = (state, payload) => ({
  ...state,
  estadosDeLosFuncionarios: payload,
});
const setPadrones = (state, payload) => ({
  ...state,
  padrones: payload,
});
const clear = () => ({ ...initialState });

const ACTION_HANDLER = {
  [CLEAR]: clear,
  [SET_LADAS]: setLadas,
  [SET_ESTADOS_GLOBALES]: setEstadosGlobales,
  [SET_TIPOS_VIALIDADES]: setTiposVialidades,
  [SET_CUENTAS_CONTABLES]: setCuentasContables,
  [SET_CONFIG_MASCARA_CONTABLE]: setConfigMascaraContable,
  [SET_TIPOS_PODER]: setTiposDePoder,
  [SET_PERIODOS]: setPeriodos,
  [SET_TIPOS_PRESUPUESTOS]: setTiposPresupuestos,
  [SET_GENEROS]: setGeneros,
  [SET_ESTADOS_CIVILES]: setEstadosCiviles,
  [SET_PERMISOS]: setPermisos,
  [SET_ESTADOS_DE_LOS_FUNCIONARIOS]: setEstadosDeLosFuncionarios,
  [SET_PAISES]: setPaises,
  [SET_PADRONES]: setPadrones,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLER[action.type];
  return handler ? handler(state, action.payload) : state;
};
