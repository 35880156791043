import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Breadcrumb,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';

const { Text, Title } = Typography;

const Bread = ({
  split,
  currentPath,
  isMobile,
  splicedSplit,
  routes,
}) => {
  const entidad = useSelector(({ auth }) => auth.entidad);
  const user = useSelector(({ auth }) => auth.user);

  const findItemNested = (arr, path) => (
    // eslint-disable-next-line
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.path.replace('/', '') === path) return item;
      if (item.children) return findItemNested(item.children, path);
    }, null)
  );

  const getTitleOfRoute = (path, special = false) => {
    if (special && path === 'dashboard') {
      return (
        <>
          <Text className="primary-text">
            Dashboard
          </Text>
          <br />
          <Text style={{ fontSize: 14 }}>
            {`Entidad: ${entidad ? entidad.razon_social || '' : ''}`}
          </Text>
        </>
      );
    }
    const rrr = findItemNested(routes, path);
    return rrr ? rrr.title : '';
  };

  const getDisplay = () => {
    let sum = split.filter((x) => x.length).reduce((prev, next) => prev + next.length, 0);
    if (split.length) {
      sum += split[split.length - 1].length;
    }
    if (isMobile) {
      return 'none';
    }
    if (isMobile && sum >= 40) {
      return 'none';
    }
    return 'unset';
  };
  return (
    <Row
      className="breadcrumb"
      align="middle"
      justify="space-between"
      style={{
        marginBottom: 10,
        height: 44,
      }}
    >
      <Title
        level={4}
        className="breadbrumb-title primary-text"
        style={{
          margin: 0,
          display: getDisplay(),
        }}
      >
        {getTitleOfRoute(split[split.length - 1], true)}
      </Title>
      {currentPath.includes('/dashboard')
        ? (
          <Text style={{ fontSize: 18 }} className="primary-text">
            {`Bienvenido ${user.first_name ? user.first_name.split(' ').shift() : ''} ${user.last_name || ''} !!`}
          </Text>
        )
        : (
          <Breadcrumb style={{ marginLeft: 'auto' }}>
            {split.length < 4 && split.map((path) => (
              <Breadcrumb.Item key={path}>
                {getTitleOfRoute(path)}
              </Breadcrumb.Item>
            ))}
            {split.length >= 4 && splicedSplit.map((path, _idx) => (
              <Breadcrumb.Item key={path}>
                {getTitleOfRoute(path, _idx + 2)}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
    </Row>
  );
};

Bread.propTypes = {
  split: PropTypes.arrayOf(PropTypes.string).isRequired,
  splicedSplit: PropTypes.arrayOf(PropTypes.string).isRequired,
  routes: PropTypes.arrayOf({}).isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool,
};

Bread.defaultProps = {
  isMobile: false,
};

export default Bread;
