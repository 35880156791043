import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import 'antd/dist/antd.css';
import '../index.scss';
import {
  UserOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from '@ant-design/icons';

import {
  Row,
  Col,
  Button,
  Tag,
  Layout,
  Menu,
  Avatar,
  Drawer,
  Typography,
  Modal,
  Tabs,
  Collapse,
  Divider,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';

import GlobalStyles from '../global';
import { defSigob } from './themes';
import { faqs, foo } from './hardcodedStuff';
import Bread from './Bread';
import Footer from './Footer';
import ThemeConfig from './ThemeConfig';
import LayoutHeader from './LayoutHeader';
import ModalChangePassword from './ModalChangePassword';
import ModalChangeFiscalPeriod from './ModalChangeFiscalPeriod';
import { routes } from '../../../routes/index';
import logo from '../../../assets/logo.png';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Content, Sider } = Layout;
const { Text } = Typography;

const getPosition = (str, substr, index) => str.split(substr, index).join(substr).length;

// eslint-disable-next-line react/prop-types
const AppLayout = ({ children, onError }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const roles = useSelector(({ auth }) => auth.roles);
  const theme = useSelector(({ app }) => app.theme);

  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const history = useHistory();
  const location = window.location.href;
  const [collapsed, setCollapsed] = useState(collapsedSidebar);
  const [isMobile, setIsMobile] = useState(false);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleHelp, setVisibleHelp] = useState(false);

  const [margin, setMargin] = useState();
  const [drawerContent, setDrawerContent] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  // eslint-disable-next-line no-unused-vars
  const [helpImage, setHelpImage] = useState(logo);
  // eslint-disable-next-line no-unused-vars
  const [faqsList, setFaqsList] = useState(faqs);

  const idx = getPosition(location, '/', 3);
  let currentPath = location.substr(idx);
  const split = currentPath.split('/').filter((x) => x.length);
  currentPath = split.map((s, is) => `/${split.filter((a, i) => i <= is).join('/')}`);
  // eslint-disable-next-line no-unused-vars
  const [openKeys, setOpenKeys] = useState(currentPath || []);

  const splicedSplit = [...split];
  splicedSplit.splice(0, 2);

  useEffect(() => {
    if (isMobile) {
      setMargin(0);
      setCollapsedWidth(0);
      setCollapsed(true);
      dispatch({
        type: 'TOGGLE_SIDEBAR',
        payload: true,
      });
    } else {
      setMargin(collapsedSidebar ? 80 : 300);
      setCollapsedWidth(80);
    }
    // eslint-disable-next-line
  }, [isMobile]);

  const User = () => (
    <div
      className="user-info"
    >
      <Row>
        <Row className="avatar-wrapper">
          {/* eslint-disable-next-line no-use-before-define */}
          {UserAvatar(false)}
        </Row>
        <Col
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text strong>
            {`${user.first_name || ''} ${user.last_name || ''} ${user.second_last_name || ''}`}
          </Text>
          <Text>
            {user.email}
          </Text>

          {!!roles?.length && (
            <Col className="card">
              <Text style={{
                fontSize: 16,
                marginBottom: 5,
              }}
              >
                Roles
              </Text>
              <Row>
                {roles.map((role) => (
                  <React.Fragment key={role.id}>
                    <Tag>
                      {role.name.split('-').shift().trim()}
                    </Tag>
                  </React.Fragment>
                ))}
              </Row>
            </Col>
          )}

          <Col className="card">
            <Text style={{
              fontSize: 16,
              marginBottom: 5,
            }}
            >
              Información del usuario
            </Text>
            {!!user.unidad_responsable && (
              <Text>
                Unidad responsable:
                {' '}
                {user.unidad_responsable}
              </Text>
            )}
            {!!user?.funcionario?.puesto && (
              <Text>
                Puesto:
                {' '}
                {user.funcionario.puesto?.descripcion}
              </Text>
            )}
            {!!user.funcion_del_usuario_externo && (
              <Text>
                Función:
                {' '}
                {user?.funcion_del_usuario_externo?.descripcion}
              </Text>
            )}
            {!!user.justificacion && (
              <Text>
                Justificación:
                {' '}
                {user?.justificacion?.descripcion}
              </Text>
            )}
          </Col>

          <Row className="card">
            {/* <Button
              type="link"
              onClick={() => {
                history.push('/configuracion/cuenta-de-usuario');
              }}
            >
              <UserOutlined />
              Perfil de Usuario
            </Button> */}
            <Button
              type="link"
              className="update-password no-color"
              onClick={() => {
                setVisiblePassword(true);
                setVisibleDrawer(false);
              }}
            >
              <EditOutlined />
              Actualizar Contraseña
            </Button>
            <Button
              type="link"
              danger
              className="logout no-color"
              onClick={() => {
                dispatch({ type: 'LOGOUT' });
                dispatch({ type: 'CLEAN' });
                history.push('/');
              }}
            >
              <LogoutOutlined />
              Cerrar sesión
            </Button>
          </Row>
          <br />
          <br />
          {periodoFiscalSelected && (
            <Text>
              <ModalChangeFiscalPeriod onError={onError} />
            </Text>
          )}
        </Col>
      </Row>
    </div>
  );

  const onClickAvatar = () => {
    setDrawerContent(User);
    setDrawerTitle('Usuario');
    setVisibleDrawer(true);
  };

  const onClickSetting = () => {
    setDrawerContent(<ThemeConfig onError={onError} />);
    setVisibleDrawer(true);
    setDrawerTitle('Configuración de tema');
  };

  const notifications = () => (foo.map((item) => (
    <Row
      key={item.key}
      className={`notification-item ${item.readed ? 'readed' : ''}`}
    >
      <Col>
        <Text style={{ color: '#000' }} strong>
          {item.title}
        </Text>
        <br />
        <Text>
          {item.content}
        </Text>
        <br />
        <Text>{item.date}</Text>
      </Col>
    </Row>
  )));

  const onClickNotifications = () => {
    setDrawerContent(notifications);
    setDrawerTitle('Notificaciones');
    setVisibleDrawer(true);
  };

  const UserAvatar = () => {
    let props;
    if (user.foto) {
      props = {
        src: user.foto,
      };
    } else {
      props = {
        icon: <UserOutlined />,
      };
    }
    return (
      <Avatar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        size="large"
      />
    );
  };

  const Help = () => (
    <>
      <Button
        id="help-button"
        type="primary"
        shape="circle"
        onClick={() => setVisibleHelp(true)}
        icon={
          <QuestionCircleOutlined />
        }
      />
      <Modal
        title={(
          <Row justify="space-between">
            Ayuda
            <Button
              type="link"
              onClick={() => setVisibleHelp(false)}
            >
              <ArrowLeftOutlined />
              Regresar
            </Button>
          </Row>
        )}
        visible={visibleHelp}
        className="modal-help"
        onCancel={() => setVisibleHelp(false)}
        footer={null}
        centered
        closable={false}
      >
        <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
          <TabPane tab="Media" key="media">
            <Row className="img-wrapper">
              <img src={helpImage} alt="demo-help" />
            </Row>
          </TabPane>
          <TabPane tab="FAQ" key="faq">
            <Collapse accordion>
              {faqsList.map((item) => (
                <Panel header={item.question} key={item.key}>
                  <p>{item.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </TabPane>
          <TabPane tab="Otros" key="other">
            Más contenido sobre otras preguntas...
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );

  // eslint-disable-next-line react/prop-types
  const LinkTo = ({ to, title }) => (
    <Link to={to}>
      {title}
    </Link>
  );

  const getMenuItems = (_routes, parentPath = '', level = 0) => _routes.map((route) => {
    const fullPath = `${parentPath}${route.path}`;
    const icon = route.icon ? <route.icon /> : <span />;
    if (route.children) {
      return (
        <SubMenu
          title={route.title}
          key={fullPath}
          icon={icon}
        >
          {(collapsedSidebar && level === 0) && (
            <Menu.Item
              title={route.title}
              key={fullPath}
              icon={icon}
              className="ant-menu-item-active ant-menu-item-active-fixed"
            >
              {route.title}
              <Divider />
            </Menu.Item>
          )}
          {getMenuItems(route.children, fullPath, level + 1)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item
        key={fullPath}
        icon={icon}
      >
        <LinkTo to={fullPath} title={route.title} />
      </Menu.Item>
    );
  });

  const toggle = () => {
    setCollapsed(!collapsed);
    dispatch({
      type: 'TOGGLE_SIDEBAR',
      payload: !collapsed,
    });
    if (isMobile) {
      setMargin(0);
      setCollapsedWidth(0);
    } else {
      setMargin(!collapsed ? 80 : 300);
      setCollapsedWidth(!collapsed ? 80 : 300);
    }
  };

  return (
    <ThemeProvider
      theme={theme || defSigob}
    >
      <GlobalStyles />
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
          id="left-sider"
          theme="dark"
          width={300}
          breakpoint="md"
          onBreakpoint={(broken) => {
            setIsMobile(broken);
          }}
          style={{
            position: 'absolute',
            top: 64,
            left: 0,
          }}
        >
          {/* <LayoutMenu /> */}
          <Menu
            id="side-menu"
            theme="light"
            mode="inline"
            defaultSelectedKeys={currentPath}
          // openKeys={collapsedSidebar ? [] : openKeys}
          // onOpenChange={(keys) => setOpenKeys(keys)}
          >
            {getMenuItems(periodoFiscalSelected?.vigente
              ? routes : routes.filter((e, i) => i !== 1))}
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{ marginLeft: collapsed ? margin : 300, transition: 'all 0.2s' }}
        >
          <LayoutHeader
            isMobile={isMobile}
            toggle={toggle}
            collapsed={collapsed}
            onClickAvatar={onClickAvatar}
            onClickNotifications={onClickNotifications}
            onClickSetting={onClickSetting}
          />
          <Content
            style={{
              left: margin,
              width: `calc(100vw - ${margin}px)`,
              padding: '15px 25px 0 25px',
            }}
          >
            <Bread
              split={split}
              routes={routes}
              isMobile={isMobile}
              currentPath={currentPath}
              splicedSplit={splicedSplit}
            />
            <div
              className="customCard"
              style={{
                height: `calc(100vh - ${isMobile ? 140 : 175}px)`,
                overflowY: 'scroll',
                minHeight: 230,
              }}
            >
              {children}
            </div>
            <Footer margin={margin} onError={onError} />
          </Content>
          <Drawer
            id="layot-drawer"
            title={drawerTitle}
            placement="right"
            width={isMobile ? '100%' : 400}
            destroyOnClose
            onClose={() => {
              setVisibleDrawer(false);
            }}
            visible={visibleDrawer}
            closable
          >
            {drawerContent}
          </Drawer>
        </Layout>
      </Layout>
      <ModalChangePassword
        onError={onError}
        visible={visiblePassword}
        setVisible={setVisiblePassword}
      />
      <Help />
    </ThemeProvider>
  );
};

export default AppLayout;
