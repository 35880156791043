/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import Select from './Select';
import fetchSome from '../utils/fetchSome';
import { SET_LADAS } from '../store/reducers/catalogs';
import { toInteger } from '../utils/normalizers';

const LadaNumero = ({
  form,
  names,
  fullSize,
  disabled,
  breakPoints: bPoints,
  ladaOnly,
  rules: _rules,
  hasFeedback,
}) => {
  const ladas = useSelector(({ catalogs }) => catalogs.ladas);
  const ladaDefaultID = useSelector(({ auth }) => auth.entidad?.lada);
  const ladaDefault = ladas.find((e) => e.id === ladaDefaultID);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  React.useEffect(() => {
    if (!ladas || !ladas.length) {
      fetchSome('usuarios/lada/', SET_LADAS);
    }
    // eslint-disable-next-line
  }, []);

  const rules = _rules || {
    numero_de_telefono: [
      {
        pattern: /^(?:[0-9]{10}|)$/,
        message: 'Ingrese un número teléfonico válido',
      },
      {
        validator: async (rule, value) => {
          if (!value && form.getFieldValue(names.lada)) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
  };

  const mdBp = bPoints.md || (collapsedSidebar ? 8 : 16);
  const lgBp = bPoints.lg || (collapsedSidebar ? 8 : 16);
  const xlBp = bPoints.xl || (collapsedSidebar ? 8 : 16);
  const xxlBp = bPoints.xxl || (collapsedSidebar ? 8 : 16);

  const defaultBreakPoints = {
    xs: 24,
    sm: 24,
    md: mdBp,
    lg: lgBp,
    xl: xlBp,
    xxl: xxlBp,
  };

  const span = { span: 24 };
  const breakPoints = (fullSize ? span : defaultBreakPoints);

  const Lada = () => (
    <Form.Item
      rules={rules[names.lada]}
      name={names.lada}
      hasFeedback={hasFeedback}
      label="Lada"
    >
      <Select
        allowClear
        filterOption={(input, option) => {
          const normalizedInput = input.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '').toLowerCase();
          const children = option?.label?.props?.children;
          const normalizedText = `${children?.[0]}${children?.[2]}`
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
          return normalizedText.indexOf(normalizedInput) >= 0;
        }}
        style={{ width: ladaOnly ? '100%' : 100 }}
        dropdownClassName="lada-dropdown wrap-text"
        disabled={disabled}
        placeholder={!disabled ? ladaDefault?.lada : ''}
        dataSource={ladas}
        render={(item) => (
          <>
            {item.lada}
            <br />
            {item.pais}
          </>
        )}
      />
    </Form.Item>
  );
  if (ladaOnly) {
    return <Lada />;
  }

  return (
    <Col {...breakPoints}>
      <Form.Item noStyle>
        <Input.Group className="select-input lada-num" style={{ display: 'flex' }} compact>
          <Lada />
          <Form.Item
            name={names.numero_de_telefono}
            rules={rules[names.numero_de_telefono]}
            label="Número"
            normalize={toInteger}
            hasFeedback={hasFeedback}
          >
            <Input allowClear disabled={disabled} maxLength={10} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </Col>
  );
};

LadaNumero.propTypes = {
  form: PropTypes.object.isRequired,
  names: PropTypes.exact({
    lada: PropTypes.string,
    numero_de_telefono: PropTypes.string,
  }),
  fullSize: PropTypes.bool,
  disabled: PropTypes.bool,
  breakPoints: PropTypes.exact({
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
  }),
  ladaOnly: PropTypes.bool,
  rules: PropTypes.object,
  hasFeedback: PropTypes.bool,
};

LadaNumero.defaultProps = {
  names: {
    lada: 'lada',
    numero_de_telefono: 'numero_de_telefono',
  },
  fullSize: false,
  disabled: false,
  breakPoints: {
    md: null,
    lg: null,
    xl: null,
    xxl: null,
  },
  ladaOnly: false,
  rules: null,
  hasFeedback: true,
};

export default LadaNumero;
