import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Typography,
  Button,
  Spin,
  Form,
  message,
} from 'antd';

import {
  LeftOutlined,
} from '@ant-design/icons';
import API from '../../utils/api';
import getQueryParams from '../../utils/getQueryParams';
import './index.scss';
import initialOptions from '../../utils/passwordRequirements';
import Card from './components/Card';
import Password from '../../components/Password';
import { onError } from '../../utils/handlers';

const { Title } = Typography;

const RestablecerContrasena = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const codigo = getQueryParams('codigo', window.location.href);
  const [options, setOptions] = useState(initialOptions);

  const onFormValuesChange = (changedValues, allValues) => {
    setPassword(allValues.password);
    const newOps = [];
    const ops = [...options];
    if (allValues.password) {
      newOps.push(
        {
          label: ops[0].label,
          checked: allValues.password && allValues.password.length >= 8,
          key: 1,
        },
        {
          label: ops[1].label,
          checked: /[A-ZÁÉÍÓÚÑ]/.test(allValues.password),
          key: 2,
        },
        {
          label: ops[2].label,
          checked: /[a-záéíóúñ]/.test(allValues.password),
          key: 3,
        },
        {
          label: ops[3].label,
          checked: !(/^[A-ZÁÉÍÓÚÑa-záéíóúñ0-9]+$/.test(allValues.password)),
          key: 4,
        },
        {
          label: ops[4].label,
          checked: /[0-9]/.test(allValues.password),
          key: 5,
        },
      );
      setOptions(newOps);
    } else {
      setOptions([...initialOptions]);
    }
    setTimeout(() => {
      if (allValues.passwordConfirm && changedValues.password) {
        form.validateFields(['password', 'passwordConfirm']);
      }
    });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const response = await API.post(`usuarios/restablecer-contrasena/?codigo=${codigo}`, {
        contrasena: values.password,
        contrasena_confirmacion: values.passwordConfirm,
      });
      if (response?.status === 200) {
        message.info(response.data.detail);
        history.push('/inicio-de-sesion');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    password: [
      {
        validator: async () => {
          if (options.filter((o) => o.checked).length !== options.length) {
            throw new Error('La contraseña no cumple con los requisitos');
          }
        },
      },
    ],
    passwordConfirm: [
      {
        validator: async (rule, value) => {
          if (value && value !== password) {
            throw new Error('Las contraseñas no coinciden.');
          }
          if (options.filter((o) => o.checked).length !== options.length) {
            throw new Error('  ');
          }
        },
      },
    ],
  };

  return (
    <Card>
      <Spin tip="Loading..." spinning={loading} style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Button
            type="link"
            className="auth-button"
            style={{ textAlign: 'left', padding: 0 }}
            onClick={() => history.push('/inicio-de-sesion')}
          >
            <LeftOutlined />
            {' '}
            Volver
          </Button>
        </Row>
        <Title style={{ fontWeight: 'bold' }} level={3}>
          Restablecer contraseña
        </Title>
        <Form
          name="forgot-pwd-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onValuesChange={onFormValuesChange}
        >
          <Row gutter={[10, 10]}>
            <Password rules={rules} options={options} />
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              htmlType="submit"
              shape="round"
              style={{
                backgroundColor: '#235161',
                color: '#D88F4F',
              }}
              block
              className="auth-button"
            >
              <span>Restablecer</span>
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default RestablecerContrasena;
