import React from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Form,
  message,
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const AvatarUploader = ({
  disabled,
  setImageURL,
  imageURL,
  formItemName,
}) => {
  const [fileList, setFileList] = React.useState([]);
  return (
    <Form.Item
      label="Fotografía"
      style={{ marginBottom: 0 }}
      className="avatar-uploader-cont"
      name={formItemName}
    >
      <Upload
        name="avatar"
        className="avatar-uploader"
        accept="image/png, image/jpeg, image/jpg"
        onRemove={() => {
          setImageURL();
          setFileList([]);
        }}
        onChange={(info) => {
          const { file } = info;
          const ext = file.name.split('.').pop();

          const isValidExt = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
          if (!isValidExt) {
            message.warning('Solo se admiten archivos en formato JPG, JPEG o PNG');
          }

          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            message.warning('El archivo no debe sobrepasar los 2MB');
          }
          if (isValidExt && isLt2M) {
            getBase64(file, setImageURL);
            setFileList([file]);
          } else {
            setImageURL();
            setFileList([]);
          }
        }}
        listType="picture-card"
        beforeUpload={() => false}
        showUploadList={false}
        disabled={disabled}
        fileList={fileList}
      >
        {imageURL
          ? <img src={imageURL} alt="avatar" style={{ width: '100%' }} />
          : (
            <div>
              <PlusOutlined />
              <div className="ant-upload-text">Subir</div>
            </div>
          )}
      </Upload>
    </Form.Item>
  );
};

AvatarUploader.propTypes = {
  disabled: PropTypes.bool,
  formItemName: PropTypes.string.isRequired,
  setImageURL: PropTypes.func.isRequired,
  imageURL: PropTypes.string,
};

AvatarUploader.defaultProps = {
  imageURL: null,
  disabled: false,
};

export default AvatarUploader;
