import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Form,
  Col,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const Dragger = ({
  rules,
  name,
  label,
  allowedExtensions,
  limitMB,
  multiple,
  form,
  disabled,
}) => {
  const parsedMultiple = parseInt(multiple, 10) || 0;
  if (typeof multiple === 'number') {
    // eslint-disable-next-line no-param-reassign
    multiple = parsedMultiple > 1 ? parsedMultiple : false;
  }
  const [fileList, setFileList] = React.useState([]);
  const accept = allowedExtensions.map((e) => `.${e}`).join(',');
  const formats = allowedExtensions.join(', ').toUpperCase();

  useEffect(() => {
    if (form) {
      setFileList(form.getFieldValue(name)?.fileList || []);
    }
    // eslint-disable-next-line
  }, []);

  const beforeUpload = (file, files) => {
    // eslint-disable-next-line no-param-reassign
    files = files.filter((f) => !fileList.find((e) => e.name === f.name));
    const isValidExt = !multiple ? allowedExtensions.includes(file.name.split('.').pop())
      : files.every((f) => allowedExtensions.includes(f.name.split('.').pop()));

    const isLmt = !multiple ? (file.size / 1024 / 1024) < limitMB
      : files.every((f) => (f.size / 1024 / 1024) < limitMB);
    if (!isLmt) {
      message.warn(`${multiple ? 'Cada' : 'El'} archivo no debe sobrepasar los ${limitMB}MB`);
    }

    if (!isValidExt) {
      message.warn(`Solo se admiten archivos en formato ${formats}`);
    }

    if (isValidExt && isLmt) {
      if (multiple) {
        setFileList([...fileList, ...files]);
      } else {
        setFileList([file]);
      }
    }
    return false;
  };

  return (
    <Col span={24}>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
      >
        <Upload.Dragger
          disabled={disabled}
          beforeUpload={beforeUpload}
          className="custom-dragger"
          onRemove={(file) => {
            setFileList(fileList.filter((e) => e.uid !== file.uid));
          }}
          fileList={fileList}
          accept={accept}
          multiple={!!multiple}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click o arrastre el documento en esta área.
          </p>
          <p className="ant-upload-hint">
            Soporte para subir
            {' '}
            {!multiple && 'un'}
            {multiple && typeof multiple === 'number' && multiple}
            {multiple && typeof multiple !== 'number' && 'varios'}
            {' '}
            archivo
            {multiple && 's'}
            .
          </p>
        </Upload.Dragger>
      </Form.Item>
    </Col>
  );
};

Dragger.propTypes = {
  allowedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  limitMB: PropTypes.number,
  multiple: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.bool,
};

Dragger.defaultProps = {
  rules: [],
  limitMB: 20,
  multiple: false,
  form: null,
  disabled: false,
};

export default Dragger;
