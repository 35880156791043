import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  Button,
  message,
  DatePicker,
  // Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import Table from 'components/Table';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import makeMessager from 'utils/complexFormsMessages';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Direccion from 'components/Direccion';
import Participe from 'components/Catalagos/Participe';
import Servicio from 'components/Catalagos/Servicios';
import Construcciones from 'components/Catalagos/Construcciones';
import FactoresEspeciales from 'components/Catalagos/FactoresEspeciales';
import { getContribuyente } from 'api/empresas/contribuyente';
import { InputSearchPro } from 'components/InputSearch';
import Select from 'components/Select';
import { percentaje } from 'utils/validators';
import { toInteger, listToString } from 'utils/normalizers';
import getFormattedValues, {
  DATE_FRONTEND_FORMAT,
  // discartFalsyValues,
} from 'utils/formatValues';
import BusquedaAvanzadaPadrones from 'components/BusquedaAvanzada';
import NumericInput from 'components/NumericInput';
import { getPredios } from 'api/predio';
import BusquedaAvanzadaPredio from 'components/BusquedaAvanzadaPredio';
import { getCiudadano } from 'api/cuentaunicasir';
import ArcGisMap from 'components/ArcGisMap';
import Moment from 'moment';
import { ExpandOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { TabPane } = Tabs;
const baseURI = '/catastro/predios/';

const baseColumns = [
  {
    titleText: 'Descripción',
    dataIndex: 'descripcion',
    key: 'descripcion',
    width: 300,
  },
  {
    titleText: 'Participes',
    dataIndex: 'nombres_de_participes',
    key: 'nombres_de_participes',
    render: (val) => listToString(val),
    width: 300,
  },
];

const Predios = () => {
  const [columns, setColumns] = useState([]);
  const history = useHistory();
  const [form] = Form.useForm();
  const [formTerreno] = Form.useForm();
  const [formConstruccion] = Form.useForm();
  const [formFactorespeciales] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState({ results: [] });
  const [ciudadanoFound, setCiudadanoFound] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [isMutable, setIsMutable] = useState(true);
  const [usoPredio, setUsoPredio] = useState([]);
  const [regimenDePropiedad, setRegimenDePropiedad] = useState([]);
  const [zonasHomogeneas, setZonasHomogeneas] = useState([]);
  const [unidadesDeMedida, setUnidadesDeMedida] = useState([]);
  const [unidadesDeMedidaDeSuperficies, setUnidadesDeMedidaDeSuperficies] = useState([]);
  const [configuracionesDeClavesDePredios, setConfiguracionesDeClavesDePredios] = useState([]);
  const [clavesDePredios, setClavesDePredios] = useState([]);
  const [unidades, setUnidades] = useState();
  const [tipoDeDesarrollo, setTipoDeDesarrollo] = useState([]);
  const [tipoDePredio, setTipoDePredio] = useState([]);
  const [tipoDeTenencia, setTipoDeTenencia] = useState([]);
  const [formAddress, setFormAddress] = useState();
  const [formFactoresEspeciales, setFormFactoresEspeciales] = useState();
  const [formAddressNotificacion, setFormAddressNotificacion] = useState();
  const basicKeys = ['general', 'terreno', 'participes', 'servicios', 'contrucciones', 'map'];
  const complexForms = [
    { key: 'factores', formInstance: formFactoresEspeciales },
    { key: 'direccion', formInstance: formAddress },
    { key: 'direccion_notificacion', formInstance: formAddressNotificacion },
    { key: 'contrucciones', formInstance: formConstruccion },
  ];
  const [isCiudadano, setIsCiudadano] = useState(true);
  const [visibleBusquedaPadrones, setVisibleBusquedaPadrones] = useState(false);
  const [contribuyenteFound, setContribuyenteFound] = useState([]);
  const [dataExterno, setDataExterno] = useState([]);
  // const [current, setCurrent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [qParams, setQParams] = useState();
  const [expanded, setExpanded] = useState(false);
  const [predioSelected, setPredioSelectedMunicipal] = useState([]);
  const [corredoresDeValor, setCorredoresDeValor] = useState([]);
  const [girosPredio, setGirosPredio] = useState([]);
  const [meritoDemerito, setMeritoDemerito] = useState([]);
  // const [atributoId, setAtributoId] = useState(0);

  const fetchData = async (page = 1, params) => {
    setLoading(true);
    setCurrentPage(page);
    const _data = await getPredios({ page, ...params });
    setData(_data);
    setLoading(false);
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resUsoPredio = await API.get('/catastro/uso-de-predio/');
      setUsoPredio(resUsoPredio.data || []);

      const resRegimenDePropiedad = await API.get('/catastro/regimenes-de-propiedades/');
      setRegimenDePropiedad(resRegimenDePropiedad.data || []);

      const resZonas = await API.get('/catastro/zonas-homogeneas/');
      setZonasHomogeneas(resZonas?.data || []);

      const resCorredoresDeValor = await API.get('/catastro/corredores-de-valor/');
      setCorredoresDeValor(resCorredoresDeValor?.data || []);

      const resUnidadesDeMedida = await API.get('/catastro/unidades-de-medida-de-predios/');
      setUnidadesDeMedida(resUnidadesDeMedida.data || []);

      const resUnidadesDeMedidaDeSuperficies = await API
        .get('/catastro/unidades-de-medida-de-superficie-de-predios/');
      setUnidadesDeMedidaDeSuperficies(resUnidadesDeMedidaDeSuperficies.data || []);

      const GIROS_DATA = await API.get('/empresas/giros-de-entidades/');
      setGirosPredio(GIROS_DATA.data || []);

      const resTipoDePredio = await API.get('/catastro/tipos-de-predios/');
      setTipoDePredio(resTipoDePredio.data || []);

      const resTipoDeDesarrollo = await API.get('/catastro/tipos-de-desarrollo-de-predios/');
      setTipoDeDesarrollo(resTipoDeDesarrollo.data || []);

      const resTipoDeTenencia = await API.get('/catastro/tipos-de-tenencias/');
      setTipoDeTenencia(resTipoDeTenencia.data || []);

      const resMerito = await API.get('/catalogos/motivos-de-merito-demerito-terreno/');
      setMeritoDemerito(resMerito.data || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showRequirementsMsg = () => {
    message.info((
      <>
        <span>
          No se han registrado y autorizado las llaves de predios.
          Es requerido mínimo una llave principal.
        </span>
        <Button
          type="link"
          onClick={() => {
            message.destroy();
            history.push('/configuracion/entidad/entidad?tab=configurations');
          }}
        >
          Ir
        </Button>
      </>
    ), 3);
  };

  const fetchCiudadano = async (id) => {
    setLoading(true);
    if (id) {
      const _ciudadano = await getCiudadano(id);
      if (_ciudadano) {
        setCiudadanoFound({
          ..._ciudadano,
          nombre_completo: `${_ciudadano?.nombre} ${_ciudadano?.apellido_paterno} ${_ciudadano?.apellido_materno || ''} `,
        });
        form.setFieldsValue({
          ciudadano: _ciudadano.id,
        });
      } else {
        message.info('No se encontró ciudadano');
      }
    }
    setLoading(false);
  };

  const fetchContribuyente = async (id) => {
    setLoading(true);
    if (id) {
      const _contribuyente = await getContribuyente(id);
      if (_contribuyente) {
        if (_contribuyente.tipo_de_persona === 2) {
          _contribuyente.nombre_completo = _contribuyente?.razon_social;
        }
        _contribuyente.nombre_completo = _contribuyente.nombre_completo || `${_contribuyente?.nombre} ${_contribuyente?.apellido_paterno} ${_contribuyente?.apellido_materno}`;
        setContribuyenteFound(_contribuyente);
        form.setFieldsValue({ contribuyente: _contribuyente.id });
      } else {
        message.info('No se encontró el Contribuyente');
      }
    }
    setLoading(false);
  };

  const searchCiudadano = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q']);
      const { q } = form.getFieldsValue(['q']);
      if (q) {
        const _ciudadanos = await getCiudadano({ q });
        if ([1].includes(_ciudadanos.results.length)) {
          const [selected] = _ciudadanos.results;
          form.setFieldsValue({
            ciudadano: _ciudadanos.id,
          });
          setCiudadanoFound([selected]);
        } else if (_ciudadanos.results.length > 1) {
          setDataExterno(_ciudadanos);
          setVisibleBusquedaPadrones(true);
        } if (!_ciudadanos.results.length) {
          message.info('No se encontró ciudadano');
        }
      } else {
        await form.validateFields(['q']);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const searchContribuyente = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q']);
      const { q } = form.getFieldsValue(['q']);
      if (q) {
        const _contribuyente = await getContribuyente({ q });
        if (_contribuyente?.results?.length === 1) {
          const [selected] = _contribuyente.results;
          if (selected.tipo_de_persona === 2) {
            selected.nombre_completo = selected?.razon_social;
          }
          selected.nombre_completo = selected.nombre_completo || `${selected?.nombre} ${selected?.apellido_paterno} ${selected?.apellido_materno}`;
          form.setFieldsValue({
            contribuyente: selected.id,
          });
          setContribuyenteFound([selected]);
        } else if (_contribuyente.results.length > 1) {
          setDataExterno(_contribuyente);
          setVisibleBusquedaPadrones(true);
        } if (!_contribuyente.results.length) {
          message.info('No se encontró Contribuyente');
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        const resClaves = await API.get('/catastro/clave-de-predios/');
        setClavesDePredios(resClaves.data);
        const resConfiguracionesDeClaves = await API.get('/catastro/configuracion-de-la-clave/', {
          params: { estados_globales: 4 },
        });
        const llaves = resConfiguracionesDeClaves.data;
        if (llaves.some((e) => e.es_principal === true)) {
          setConfiguracionesDeClavesDePredios(llaves);
          setTimeout(() => {
            setColumns(resClaves.data
              .filter((e) => llaves
                .some((a) => a.clave_de_predio === e.id))
              .map((e) => ({
                titleText: e.descripcion,
                dataIndex: e.clave,
                key: e.clave,
                width: 300,
              })).concat(baseColumns));
          });
          await fetchAll();
        } else {
          showRequirementsMsg();
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchRequirements();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setIsEditing(false);
    setVisible(false);
    setExpanded(false);
    form.resetFields();
    formTerreno.resetFields();
    formConstruccion.resetFields();
    formFactorespeciales.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setIsMutable(true);
    setPredioSelectedMunicipal(null);
    setUnidades();
    setCiudadanoFound(null);
    setContribuyenteFound(null);
    // setAtributoId(0);
  };

  // funcion para hacer POST o PATCH a atruibutos de predio
  // const postOrPatchAtributos = async (atributos, predioId) => {
  //   const atributosDePredioId = atributoId;
  //   if (atributosDePredioId) {
  //     await API.patch(`/catastro/atributos-de-predio/${atributosDePredioId}`, atributos);
  //   } else {
  //     // eslint-disable-next-line no-param-reassign
  //     atributos.predio = predioId;
  //     await API.post('/catastro/atributos-de-predio/', atributos);
  //   }
  // };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formTerreno.validateFields();
      await formConstruccion.validateFields();
      await formFactorespeciales.validateFields();
      const values = {
        ...formTerreno.getFieldsValue(),
        ...form.getFieldsValue(),
      };
      values.contribuyente = values.es_propietario_ciudadano ? null : values.contribuyente;
      values.ciudadano = !values.es_propietario_ciudadano ? null : values.ciudadano;

      // await postOrPatchAtributos(values, selectedRowKeys[0]);

      const formattedValues = getFormattedValues(values, { clean: false });

      if (!selectedRowKeys.length) {
        const response = await API.post('/catastro/predios/', formattedValues);
        if (response?.status === 201) {
          if (!_continue) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`/catastro/predios/${key}/`, formattedValues);
        if (response?.status === 200) {
          if (!_continue) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line max-len
      onError(err, setLoading, [form, formTerreno, formFactorespeciales, formConstruccion], setCurrentTabKey);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}`);
        if (response && response.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err);
    }
  };

  // const valuesChanged = () => {
  //   const values = form.getFieldsValue();
  //   return !Object.keys(values).map((key) => current[key] === values[key]).every((e) => e);
  // };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    const { estados_globales } = record;
    setIsMutable(estados_globales < 4);
    if (record?.participe_principal) {
      form.setFieldsValue({
        principal: record?.participe_principal,
      });
    }
    if (record?.clave_catastral_municipal) {
      setPredioSelectedMunicipal(record);
    }
    if (record.unidad_de_medida && record.unidad_de_medida_de_la_superficie) {
      setUnidades({
        unidad_de_medida: unidadesDeMedida
          .find((e) => e.id === record.unidad_de_medida)?.descripcion,
        unidad_de_medida_de_la_superficie: unidadesDeMedidaDeSuperficies
          .find((e) => e.id === record.unidad_de_medida_de_la_superficie)?.descripcion,
      });
    }
    if (record?.clave_catastral_municipal) {
      const seccionesClaveCatastral = {
        0: 'seccion_de_localidad',
        1: 'seccion_de_sector',
        2: 'seccion_de_manzana',
        3: 'seccion_de_lote',
        4: 'seccion_de_condominio',
      };
      const clave = record?.clave_catastral_municipal;
      clave.split('-').forEach((e, i) => {
        form.setFieldsValue({
          [seccionesClaveCatastral[i]]: e,
        });
      });
    }
    if (record.unidad_de_medida && record.unidad_de_medida_de_la_superficie) {
      setUnidades({
        unidad_de_medida: unidadesDeMedida
          .find((e) => e.id === record.unidad_de_medida)?.descripcion,
        unidad_de_medida_de_la_superficie: unidadesDeMedidaDeSuperficies
          .find((e) => e.id === record.unidad_de_medida_de_la_superficie)?.descripcion,
      });
    }
    if (record?.atributo_de_predio) {
      const { atributo_de_predio } = record;
      if (atributo_de_predio) {
        const lastAtributo = atributo_de_predio[atributo_de_predio.length - 1];
        form.setFieldsValue({
          giro_del_predio: lastAtributo?.giro_del_predio?.id,
          giro_alternativo_del_predio: lastAtributo?.giro_alternativo_del_predio?.id,
          desarrollo_de_etapa: lastAtributo?.desarrollo_de_etapa,
          desarrollo_de_manzana: lastAtributo?.desarrollo_de_manzana,
          desarrollo_de_lote: lastAtributo?.desarrollo_de_lote,
          desarrollo_de_condominio: lastAtributo?.desarrollo_de_condominio,
        });
      }
    }
    if (record?.valor_calculado) {
      const { valor_calculado } = record;
      if (valor_calculado) {
        const lastValor = valor_calculado[valor_calculado.length - 1];
        form.setFieldsValue({
          impuesto_predial: lastValor?.impuesto_predial,
          impuesto_predial_anterior: lastValor?.impuesto_predial_anterior,
        });
      }
    }
    if (record?.recibo) {
      const { recibo } = record;
      if (recibo) {
        const DATE_CREACION = recibo.fecha_de_creacion.split('T')[0].replace(/-/g, '/');
        form.setFieldsValue({
          folio_recibo: recibo?.folio,
          fecha_de_pago: DATE_CREACION,
        });
      }
    }
    if (record?.tramite) {
      const { tramite } = record;
      if (tramite) {
        const DATE_CREACION = tramite.fecha_de_creacion.split('T')[0].replace(/-/g, '/');
        form.setFieldsValue({
          motivo_del_movimiento: tramite?.descripcion,
          ultimo_movimiento: DATE_CREACION,
        });
      }
    }
    if (record?.clave_catastral_municipal) {
      setPredioSelectedMunicipal(record);
    }
    if (record?.participe_principal) {
      form.setFieldsValue({
        principal: record?.participe_principal,
      });
    }
    // if (record?.atributo_de_predio) {
    //   const { atributo_de_predio } = record;
    //   if (atributo_de_predio) {
    //     const lastAtributo = atributo_de_predio[atributo_de_predio.length - 1];
    //     setAtributoId(lastAtributo?.id);
    //     form.setFieldsValue({
    //       giro_del_predio: lastAtributo?.giro_del_predio?.id,
    //       giro_alternativo_del_predio: lastAtributo?.giro_alternativo_del_predio?.id,
    //       desarrollo_de_etapa: lastAtributo?.desarrollo_de_etapa,
    //       desarrollo_de_manzana: lastAtributo?.desarrollo_de_manzana,
    //       desarrollo_de_lote: lastAtributo?.desarrollo_de_lote,
    //       desarrollo_de_condominio: lastAtributo?.desarrollo_de_condominio,
    //     });
    //   }
    // }

    form.setFieldsValue({
      ...record,
      valor_del_ultimo_impuesto_predial: record.valor_del_ultimo_impuesto_predial || 'N/A',
    });
    formConstruccion.setFieldsValue(record);
    formFactorespeciales.setFieldsValue(record);
    form.setFieldsValue(record);
  };

  const fillValues = async () => {
    try {
      // fetchCiudadano, fetchContribuyente y todo lo que ocupa peticiones aquí
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.results.find((item) => item.id === key);
      if (match?.ciudadano) {
        form.setFieldsValue({
          es_propietario_ciudadano: true,
        });
        setIsCiudadano(true);
        await fetchCiudadano(match?.ciudadano.id);
      }
      if (match?.contribuyente) {
        form.setFieldsValue({
          es_propietario_ciudadano: false,
        });
        setIsCiudadano(false);
        await fetchContribuyente(match?.contribuyente);
      }
      if (match?.clave_catastral_municipal) {
        const seccionesClaveCatastral = {
          0: 'seccion_de_localidad',
          1: 'seccion_de_sector',
          2: 'seccion_de_manzana',
          3: 'seccion_de_lote',
          4: 'seccion_de_condominio',
        };
        const clave = match?.clave_catastral_municipal;
        clave.split('-').forEach((e, i) => {
          form.setFieldsValue({
            [seccionesClaveCatastral[i]]: e,
          });
        });
      }
      formTerreno.setFieldsValue({
        valor_del_terreno: match.valor_del_terreno,
        motivo_de_merito_demerito: match.motivo_de_merito_demerito,
        valor_m2_del_terreno: match.valor_m2_del_terreno,
        factor_merito_demerito: match.factor_merito_demerito,
        zona_homogenea: match.zona_homogenea,
        corredor_de_valor: match.corredor_de_valor,
        superficie_total_del_terreno: match.superficie_total_del_terreno,
        valor_catastral_calculado: match.valor_catastral_calculado,
        tipo_de_predio: match.tipo_de_predio,
        uso_de_predio: match.uso_de_predio,
        tipo_de_desarrollo: match.tipo_de_desarrollo,
        tipo_de_tenencia: match.tipo_de_tenencia,
      });
      if (match?.valor_del_terreno) {
        form.setFieldsValue({
          valor_catastral: match.valor_catastral,
        });
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickEdit = async () => {
    await fillValues();
    setIsEditing(true);
    setVisible(true);
  };

  const handleOnBlur = (event, numDigits, nameInput) => {
    const { value } = event.target;
    const paddedValue = value.padStart(numDigits, '0');
    form.setFieldValue(nameInput, paddedValue);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const keyValidator = (validateRequired = false) => ({
    validator: async (rule, val) => {
      const value = val?.toString()?.trim() || '';
      const clave = clavesDePredios.find((e) => e.clave === rule.field);
      const config = configuracionesDeClavesDePredios
        .find((e) => e.clave_de_predio === clave.id);
      if (validateRequired && config.es_requerido && !value) {
        throw new Error('El campo es requerido');
      }
      if (value) {
        if (config.longitud_minima === config.longitud_maxima
          && value.length !== config.longitud_minima) {
          throw new Error(`El campo debe contener ${config.longitud_minima} caracteres`);
        }
        if (value.length < config.longitud_minima) {
          throw new Error(`El campo debe contener mínimo ${config.longitud_minima} caracteres`);
        }
        if (value.length > config.longitud_maxima) {
          throw new Error(`El campo debe contener máximo ${config.longitud_maxima} caracteres`);
        }
      }
    },
  });

  const rules = {
    required: [required],
    porcentaje_del_condominio: [
      {
        validator: percentaje,
      },
    ],
    keyRequired: [
      required,
      keyValidator(),
    ],
    key: [
      keyValidator(true),
    ],
  };

  const onClickAdd = () => {
    setSelectedRowKeys([]);
    onCancel();
    setVisible(true);
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  const onChangeTabKey = (key) => {
    setCurrentTabKey(key);
  };

  const onClickExpand = async () => {
    await fillValues();
    setVisible(true);
    setExpanded(true);
  };

  const resetAndValidate = () => {
    setCiudadanoFound([]);
    form.resetFields(['ciudadano', 'ciudadano__clave']);
    form.validateFields(['ciudadano__clave']);
  };

  const resetAndValidateContribuyente = () => {
    setContribuyenteFound([]);
    form.resetFields(['cuntribuyente', 'contribuyente__clave']);
    form.validateFields(['contribuyente__clave']);
  };
  const onChangeClaveContribuyente = ({ target: { value } }) => {
    if (!value?.length) {
      resetAndValidateContribuyente();
    }
    setTimeout(() => {
      form.setFieldsValue({ contribuyente__clave: value });
    });
  };
  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };
  const perteneceOptions = [
    {
      id: true,
      descripcion: 'Ciudadano',
    },
    {
      id: false,
      descripcion: 'Contribuyente',
    },
  ];

  const onValuesChange = (changedValues, allValues) => {
    const propsCatastral = ['seccion_de_localidad', 'seccion_de_sector', 'seccion_de_manzana', 'seccion_de_lote', 'seccion_de_condominio'];
    const changeKey = Object.keys(changedValues)[0];
    if (propsCatastral.includes(changeKey)) {
      // EN LA SECCION_DE_CONDOMINIO EN JUAREZ ES 4 Y EN NAYARIT ES 5
      const pads = { seccion_de_localidad: 2, seccion_de_condominio: 4 };
      form.setFieldsValue({
        clave_catastral_municipal: propsCatastral.map((e) => (allValues[e] || '').padStart(pads[e] || 3, '0')).join('-'),
      });
    }
    formTerreno.setFieldsValue(changedValues);
    formConstruccion.setFieldsValue(changedValues);
    formFactorespeciales.setFieldsValue(changedValues);
  };

  const calcularValorCatastral = (nameProp, valor) => {
    // SI NO SE ENCUENTRA EL REGISTRO EN EL ARREGLO DE DATOS, SE CALCULA EL VALOR CATASTRAL
    const objectCatastral = {
      superficie_total_del_terreno: parseFloat(formTerreno.getFieldValue('superficie_total_del_terreno')),
      valor_m2_del_terreno: parseFloat(formTerreno.getFieldValue('valor_m2_del_terreno')),
      factor_merito_demerito: parseFloat(formTerreno.getFieldValue('factor_merito_demerito')),
    };

    // SUSTITUIR EL VALOR DE LA PROPIEDAD
    objectCatastral[nameProp] = parseFloat(valor);

    // CALCULAR EL VALOR CATASTRAL
    const valorCatastral = (
      (objectCatastral.superficie_total_del_terreno || 1)
        * (objectCatastral.valor_m2_del_terreno || 1)
        * (objectCatastral.factor_merito_demerito || 1)
    );

    // ACTUALIZAR EL VALOR CATASTRAL EN EL FORMULARIO
    formTerreno.setFieldsValue({
      valor_catastral_calculado: valorCatastral,
    });
  };

  const callbackConstrucciones = async (superficie, valor) => {
    const {
      valor_de_la_construccion,
      valor_del_terreno,
      superficie_de_la_construccion,
    } = form.getFieldsValue();
    if (superficie !== superficie_de_la_construccion || valor !== valor_de_la_construccion) {
      const values = {
        superficie_de_la_construccion: superficie,
        valor_de_la_construccion: valor,
      };
      values.valor_catastral = values.valor_de_la_construccion + valor_del_terreno;
      try {
        setLoading(true);
        const [key] = selectedRowKeys;
        const response = await API.patch(`/catastro/predios/${key}/`, values);
        form.setFieldsValue({
          superficie_de_la_construccion:
            response.data.superficie_de_la_construccion,
          valor_de_la_construccion: response.data.valor_de_la_construccion,
          valor_catastral: response.data.valor_catastral,
        });
        setLoading(false);
      } catch (error) {
        onError(error, setLoading);
      }
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
          {(!visible) ? (
            <>
              <BusquedaAvanzadaPredio
                callback={(params) => {
                  setQParams(params);
                  fetchData(1, params);
                }}
                dataExterno={currentPage}
              />
              {!!columns.length && (
                <Table
                  cols={columns}
                  mobileCols={[columns[1] || columns[0]]}
                  data={data?.results}
                  rowSelection={rowSelection}
                  handleOnRowClick={handleOnRowClick}
                  controls={{
                    onClickEdit,
                    onClickAdd,
                    onClickDelete,
                  }}
                  customActions={[
                    {
                      onClick: async () => onClickExpand(),
                      icon: <ExpandOutlined />,
                      text: 'Vista completa',
                    },
                  ]}
                  allowSearch={false}
                  rowKey="id"
                  baseURI={baseURI}
                  paginationCallback={(page) => fetchData(page, qParams)}
                  pagination={false}
                  count={data?.count}
                  currentPage={currentPage}
                />
              )}
            </>
          ) : (
            <Col span={24} style={{ height: '100%' }}>
              <Card
                className="form-container no-padding"
                title={`${selectedRowKeys.length ? `Editar ${predioSelected?.clave_catastral_municipal}` : 'Agregar Predio'}`}
                extra={(
                  <FormSubmitControls
                    onFinish={(basicKeys
                      .concat(complexForms.map((e) => e.key))
                      .includes(currentTabKey) && !expanded
                      ? handleOnFinish : null)}
                    onCancel={onCancel}
                    mutable={isMutable}
                    allowSaveAndContinue
                  />
                )}
                bordered={false}
              >
                <Tabs
                  onChange={isMutable && !expanded ? onChangeTabKey : setCurrentTabKey}
                  activeKey={currentTabKey}
                  type="card"
                >
                  <TabPane tab="General" key="general">
                    <Form
                      name="general"
                      layout="vertical"
                      form={form}
                      onFinish={onFinish}
                      onValuesChange={onValuesChange}
                      scrollToFirstError
                      forceRender
                      initialValues={
                        {
                          estados_globales: 1,
                          fecha_de_creacion: new Moment(),
                        }
                      }
                    >
                      <Row gutter={10}>
                        {
                          !!selectedRowKeys.length
                          && (
                            <Col xs={24} sm={24} md={20}>
                              <Form.Item
                                name="participe_principal"
                                label="Participe principal"
                                hasFeedback
                              >
                                <Input allowClear disabled />
                              </Form.Item>
                            </Col>
                          )
                        }
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="fecha_de_creacion"
                            label="Fecha de alta"
                            disabled
                          >
                            {Moment().format(DATE_FRONTEND_FORMAT)}
                            <DatePicker
                              placeholder=""
                              format={DATE_FRONTEND_FORMAT}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="numero_de_inscripcion_registro_publico"
                            label="Inscripcion"
                            hasFeedback
                            rules={rules.required}
                          >
                            <Input allowClear disabled={expanded} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="numero_de_registro_publico_de_libro"
                            label="Libro"
                            hasFeedback
                            rules={rules.required}
                          >
                            <Input allowClear disabled={expanded} />
                          </Form.Item>
                        </Col>
                        {configuracionesDeClavesDePredios.map((config) => {
                          const clave = clavesDePredios
                            .find((e) => e.id === config.clave_de_predio);
                          const specificLength = config.longitud_maxima === config.longitud_minima;
                          return (
                            <Col xs={24} sm={24} md={6} key={clave.clave}>
                              <Form.Item
                                name={clave.clave}
                                rules={config.es_requerido ? rules.keyRequired : rules.key}
                                label={clave.descripcion}
                                hasFeedback
                                normalize={!config.es_alfanumerica ? toInteger : null}
                                tooltip={{
                                  title: `Longitud de ${config.longitud_minima}${specificLength ? '' : ` a ${config.longitud_maxima}`} caracteres`,
                                }}
                              >
                                <Input maxLength={config.longitud_maxima} disabled />
                              </Form.Item>
                            </Col>
                          );
                        })}
                        <Col md={3} sm={24} xs={8}>
                          <Form.Item
                            label="Localidad"
                            name="seccion_de_localidad"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={2}
                              onBlur={(event) => handleOnBlur(event, 2, 'seccion_de_localidad')}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Sector"
                            name="seccion_de_sector"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={3}
                              onBlur={(event) => handleOnBlur(event, 3, 'seccion_de_sector')}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Manzana"
                            name="seccion_de_manzana"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={3}
                              onBlur={(event) => handleOnBlur(event, 3, 'seccion_de_manzana')}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Lote"
                            name="seccion_de_lote"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={3}
                              onBlur={(event) => handleOnBlur(event, 3, 'seccion_de_lote')}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Condominio"
                            name="seccion_de_condominio"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={4}
                              onBlur={(event) => handleOnBlur(event, 4, 'seccion_de_condominio')}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                          <Form.Item
                            label="CURT"
                            name="CURT"
                          >
                            <Input allowClear disabled={expanded} maxLength />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Etapa"
                            name="desarrollo_de_etapa"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={5}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Manzana"
                            name="desarrollo_de_manzana"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={5}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Lote"
                            name="desarrollo_de_lote"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={5}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} sm={24} xs={24}>
                          <Form.Item
                            label="Condominio"
                            name="desarrollo_de_condominio"
                          >
                            <Input
                              allowClear
                              disabled={expanded}
                              maxLength={4}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="es_propietario_ciudadano"
                            label="Tipo de posesionario"
                            hasFeedback
                          >
                            <Select
                              disabled={expanded}
                              dataSource={perteneceOptions}
                              onChange={(value) => {
                                setIsCiudadano(value);
                                resetAndValidate();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {isCiudadano ? (
                          <InputSearchPro
                            disabled={expanded}
                            tooltip="Busqueda por correo"
                            label="Posesionario (ciudadano)"
                            name="q"
                            onClickAdvanced={() => setVisibleBusquedaPadrones(true)}
                            onPressEnter={() => searchCiudadano()}
                            breakPoints={{
                              xs: 24,
                              sm: 24,
                              md: 12,
                            }}
                            inputProps={{
                              onPressEnter: () => searchCiudadano(),
                              onChange: ({ target: { value } }) => {
                                if (!value) {
                                  resetAndValidate();
                                }
                              },
                            }}
                            resultProps={{
                              name: 'ciudadano',
                              label: 'Posesionario (ciudadano)',
                              dataSource: ciudadanoFound,
                              labelProp: 'nombre_completo',
                              onClear: resetAndValidate,
                              breakPoints: {
                                xs: 24,
                                sm: 24,
                                md: 12,
                              },
                            }}
                          />
                        ) : (
                          <InputSearchPro
                            disabled={expanded}
                            tooltip="Busqueda por Nombre Completo"
                            label="Posesionario (contribuyente)"
                            onClickAdvanced={() => setVisibleBusquedaPadrones(true)}
                            name="q"
                            onPressEnter={() => searchContribuyente()}
                            breakPoints={{
                              xs: 24,
                              sm: 24,
                              md: 12,
                            }}
                            inputProps={{
                              onPressEnter: () => searchContribuyente(),
                              onChange: ({ target: { value } }) => {
                                if (!value) {
                                  resetAndValidate();
                                }
                              },
                            }}
                            resultProps={{
                              breakPoints: {
                                xs: 24,
                                sm: 24,
                                md: 16,
                              },
                              name: 'contribuyente',
                              label: 'Posesionario (contribuyente)',
                              dataSource: contribuyenteFound,
                              keyLabelRender: true,
                              keyProp: 'clave',
                              labelProp: 'nombre_completo',
                              onClear: onChangeClaveContribuyente,
                            }}
                          />
                        )}
                        <Col xs={24} sm={24} md={3} />

                        <Col md={6} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            disabled
                            label="Superficie del terreno"
                            name="superficie_total_del_terreno"
                          />
                        </Col>

                        <Col md={6} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            disabled
                            label="Valor de Terreno"
                            name="valor_del_terreno"
                          />
                        </Col>

                        <Col md={6} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            disabled
                            label="Superficie Total de construcción"
                            name="superficie_de_la_construccion"
                          />
                        </Col>

                        <Col md={6} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            disabled
                            label="Valor De La Construccion"
                            name="valor_de_la_construccion"
                          />
                        </Col>

                        {
                          expanded && (
                            <Col md={12}>
                              <Row gutter={10}>
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="fecha_de_pago"
                                    label="Fecha de pago"
                                  >
                                    <Input allowClear disabled />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="folio_recibo"
                                    label="Recibo"
                                  >
                                    <Input allowClear disabled />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name=""
                                    label="Caja"
                                  >
                                    <Input allowClear disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={10}>
                                <Col xs={24} sm={24} md={8}>
                                  <Form.Item
                                    name="ultimo_movimiento"
                                    label="Ultimo movimiento"
                                  >
                                    <Input allowClear disabled />
                                  </Form.Item>
                                </Col>
                                <Col sm={24} xs={24} md={8}>
                                  <Form.Item
                                    rules={rules.required}
                                    name="motivo_del_movimiento"
                                    label="Motivo del movimiento"
                                    hasFeedback
                                  >
                                    <TextArea disabled={expanded} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          )
                        }
                        {
                        isEditing && (
                          <Col md={6} sm={24} xs={24}>
                            <NumericInput
                              disabled
                              decimal
                              label="Valor catastral"
                              name="valor_catastral"
                            />
                          </Col>
                        )
                        }
                        {
                          expanded && (
                          <Row gutter={10}>
                            <Col xs={24} sm={24} md={18}>
                              <Form.Item
                                name="impuesto_predial"
                                label="Impuesto predial actual"
                              >
                                <Input allowClear disabled />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={18}>
                              <Form.Item
                                name="impuesto_predial_anterior"
                                label="Impuesto predial anterior"
                              >
                                <Input allowClear disabled />
                              </Form.Item>
                            </Col>
                          </Row>
                          )
                        }
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="uso_de_predio"
                            rules={rules.required}
                            label="Uso de predio"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={usoPredio} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="uso_de_predio_secundario"
                            label="Uso de predio secundario"
                            hasFeedback
                          >
                            <Select dataSource={usoPredio} disabled={expanded} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_predio"
                            rules={rules.required}
                            label="Tipo De Predio"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={tipoDePredio} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_desarrollo"
                            rules={rules.required}
                            label="Tipo De Desarrollo"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={tipoDeDesarrollo} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_tenencia"
                            rules={rules.required}
                            label="Tipo De Tenencia"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={tipoDeTenencia} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="regimen_de_propiedad"
                            label="Régimen de propiedad"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={regimenDePropiedad} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="giro_del_predio"
                            label="Giro de predio"
                            hasFeedback
                          >
                            <Select
                              disabled={expanded}
                              dataSource={girosPredio}
                              labelProp="nombre"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="giro_alternativo_del_predio"
                            label="Giro de predio secundario"
                            hasFeedback
                          >
                            <Select
                              disabled={expanded}
                              dataSource={girosPredio}
                              labelProp="nombre"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={16} sm={24} xs={24}>
                          <Form.Item
                            rules={rules.required}
                            name="descripcion"
                            label="Descripción"
                            hasFeedback
                          >
                            <TextArea disabled={expanded} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab="Dirección"
                    key="direccion"
                    forceRender
                    disabled={!selectedRowKeys.length}
                  >
                    <h1 style={{ fontSize: '28px', marginTop: '15px' }}>Ubicación del predio</h1>
                    <Direccion
                      currentURI={baseURI}
                      parentID={selectedRowKeys[0]}
                      setLoading={setLoading}
                      setFormAddress={setFormAddress}
                      setCurrentTabKey={setCurrentTabKey}
                      addressPropName="direccion"
                      mutable
                      callback={(values, _continue) => {
                        if (!_continue) {
                          onCancel();
                        } else {
                          const [key] = selectedRowKeys;
                          const clone = [...data];
                          const mappedData = clone.map((e) => (e.id === key
                            ? ({ ...e, direccion: values?.id }) : e));
                          setData(mappedData);
                        }
                      }}
                    />
                    <h1 style={{ fontSize: '28px', marginTop: '15px' }}>Dirección de notificación</h1>
                    <Direccion
                      currentURI={baseURI}
                      parentID={selectedRowKeys[0]}
                      setLoading={setLoading}
                      setFormAddress={setFormAddressNotificacion}
                      setCurrentTabKey={setCurrentTabKey}
                      addressPropName="direccion_notificacion"
                      callback={(values, _continue) => {
                        if (!_continue) {
                          onCancel();
                        } else {
                          const [key] = selectedRowKeys;
                          const clone = [...data];
                          const mappedData = clone.map((e) => (e.id === key
                            ? ({ ...e, direccion_notificacion: values?.id }) : e));
                          setData(mappedData);
                        }
                      }}
                    />
                  </TabPane>
                  <TabPane
                    tab="Participes"
                    key="participes"
                    disabled={!selectedRowKeys.length}
                  >
                    <Participe
                      selectedRowKey={selectedRowKeys[0]}
                    />
                  </TabPane>
                  <TabPane
                    tab="Terreno"
                    key="terreno"
                    forceRender
                  >
                    <Form
                      name="terreno"
                      layout="vertical"
                      form={formTerreno}
                      onFinish={onFinish}
                      onValuesChange={(changedValues) => {
                        if ('valor_del_terreno' in changedValues) {
                          form.setFieldsValue(changedValues);
                        }
                        if ('superficie_total_del_terreno' in changedValues) {
                          form.setFieldsValue(changedValues);
                        }
                        if ('valor_del_terreno' in changedValues) {
                          const valor_terreno = parseFloat(changedValues.valor_del_terreno);
                          const valor_construccion = parseFloat(formConstruccion.getFieldValue('valor_de_la_construccion'));
                          const valor_catastral = (valor_terreno + valor_construccion).toFixed(2);
                          form.setFieldsValue({ valor_catastral });
                        }
                      }}
                      scrollToFirstError
                      initialValues={{ estados_globales: 1 }}
                    >
                      <Row gutter={10}>
                        <Col md={6} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            label="Superficie"
                            name="superficie_total_del_terreno"
                            onChange={(e) => calcularValorCatastral('superficie_total_del_terreno', e.target.value)}
                          />
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            label="Valor m2 Terreno"
                            name="valor_m2_del_terreno"
                            disabled={expanded}
                            onChange={(e) => calcularValorCatastral('valor_m2_del_terreno', e.target.value)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            decimal
                            name="factor_merito_demerito"
                            label="Mérito/Demérito"
                            onChange={(e) => calcularValorCatastral('factor_merito_demerito', e.target.value)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <Form.Item
                            name="motivo_de_merito_demerito"
                            label="Motivo del mérito"
                          >
                            <Select
                              dataSource={meritoDemerito}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="valor_catastral_calculado"
                            label="Valor de Terreno calculado"
                          >
                            <Input allowClear disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="valor_del_terreno"
                            label="Valor del Terreno"
                            style={{ marginBottom: '8px' }}
                          >
                            <Input allowClear />
                          </Form.Item>
                          <Button
                            style={{ marginBottom: '12px' }}
                            onClick={() => {
                              const valor_del_terreno = parseFloat(formTerreno.getFieldValue('valor_catastral_calculado'));
                              formTerreno.setFieldsValue({ valor_del_terreno });
                              const valor_construccion = parseFloat(formConstruccion.getFieldValue('valor_de_la_construccion'));
                              const valor_catastral = (valor_del_terreno
                                + valor_construccion).toFixed(2);
                              form.setFieldsValue({ valor_catastral, valor_del_terreno });
                            }}
                          >
                            Utilizar valor calculado
                          </Button>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="corredor_de_valor"
                            label="Corredor de valor"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={corredoresDeValor} labelProp="vialidad_principal" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="zona_homogenea"
                            label="Zona Homogénea"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={zonasHomogeneas} />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            name="posicion"
                            label="Posición"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            name="forma_del_terreno"
                            label="Forma del terreno"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            name="tipo_de_desnivel"
                            label="Tipo de desnivel"
                            hasFeedback
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            name="unidad_de_medida"
                            label="Unidad de medida de la superficie"
                            hasFeedback
                          >
                            <Select
                              dataSource={unidadesDeMedida}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            name="unidad_de_medida_de_la_superficie"
                            label="Unidad de medida de área"
                            hasFeedback
                          >
                            <Select
                              dataSource={unidadesDeMedidaDeSuperficies}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_predio"
                            rules={rules.required}
                            label="Tipo de predio"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={tipoDePredio} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="uso_de_predio"
                            rules={rules.required}
                            label="Uso de predio"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={usoPredio} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="uso_de_predio_secundario"
                            label="Uso de predio secundario"
                            hasFeedback
                          >
                            <Select dataSource={usoPredio} disabled={expanded} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_desarrollo"
                            rules={rules.required}
                            label="Tipo de desarrollo"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={tipoDeDesarrollo} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_tenencia"
                            rules={rules.required}
                            label="Tipo de tenencia"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={tipoDeTenencia} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="regimen_de_propiedad"
                            label="Régimen de propiedad"
                            hasFeedback
                          >
                            <Select disabled={expanded} dataSource={regimenDePropiedad} />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            label="Superficie privada"
                            name="superficie_privada_de_Terreno"
                          />
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            label="Superficie común"
                            name="superficie_de_terreno_comun"
                          />
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <NumericInput
                            decimal
                            label="Superficie total del terreno"
                            name="superficie_total_del_terreno"
                            onChange={(e) => calcularValorCatastral('superficie_total_del_terreno', e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab="Factores especiales"
                    key="factores"
                    forceRender
                    disabled={!selectedRowKeys.length}
                  >
                    <Form
                      name="factores"
                      layout="vertical"
                      form={formFactorespeciales}
                      onFinish={onFinish}
                      onValuesChange={(changedValues) => form.setFieldsValue(changedValues)}
                      scrollToFirstError
                      initialValues={{ estados_globales: 1 }}
                    >
                      <Row gutter={10}>
                        <FactoresEspeciales
                          selectedRowKey={selectedRowKeys[0]}
                          setCurrentTabKey={setCurrentTabKey}
                          setFormFactoresEspeciales={setFormFactoresEspeciales}
                          unidades={unidades}
                        />
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab="Servicios"
                    key="servicios"
                    forceRender
                    disabled={!selectedRowKeys.length}
                  >
                    <Servicio
                      selectedRowKey={selectedRowKeys[0]}
                    />
                  </TabPane>
                  <TabPane
                    tab="Construcciones"
                    key="contrucciones"
                    forceRender
                    disabled={!selectedRowKeys.length}
                  >
                    <Construcciones
                      selectedRowKey={selectedRowKeys[0]}
                      callback={callbackConstrucciones}
                    />

                    {/* <Form
                      name="contruccion"
                      layout="vertica"
                      form={formConstruccion}
                      onFinish={onFinish}
                      onValuesChange={(changedValues) => form.setFieldsValue(changedValues)}
                      scrollToFirstError
                      initialValues={{ estados_globales: 1 }}
                    >
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="superficie_de_la_construccion"
                            label="Superficie de Construcción"
                            rules={rules.requiredDecimal}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="superficie_de_construccion"
                            label="Valor total de construcciones"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col span={22}>
                          <Table
                            cols={columnsConstruccion}
                            dataSource={[]}
                            handleOnRowClick={handleOnRowClick}
                            controls={{
                              onClickAdd,
                              onClickEdit,
                              onClickDelete,
                            }}
                            allowSort={false}
                            allowSearch={false}
                            rowSelection={rowSelection}
                            rowKey="id"
                          />
                        </Col>
                      </Row>
                    </Form> */}
                  </TabPane>
                  <TabPane
                    tab="Mapa"
                    key="map"
                    disabled={!selectedRowKeys.length}
                  >
                    <ArcGisMap
                      setLoading={setLoading}
                      clave_catastral_municipal={predioSelected?.clave_catastral_municipal}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={`El Predio ${form.getFieldValue('descripcion')}`}
          />
          <BusquedaAvanzadaPadrones
            visible={visibleBusquedaPadrones}
            setVisible={setVisibleBusquedaPadrones}
            callback={(found) => {
              if (isCiudadano) {
                setCiudadanoFound([found]);
                form.setFieldsValue({ ciudadano: found.id });
              } else {
                setContribuyenteFound([found]);
                form.setFieldsValue({ contribuyente: found.id });
              }
            }}
            dataExterno={dataExterno}
            setDataExterno={setDataExterno}
            tipoDePadron={isCiudadano ? 1 : 15}
            allowAdd={false}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default Predios;
