/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Input,
  Row,
  Col,
  Modal,
  Typography,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Select from 'components/Select';
import toCurrency from 'utils/toCurrency';
import NumericInput from 'components/NumericInput';
import FormSubmitControls from '../FormSubmitControls';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import API from '../../utils/api';
import { onError } from '../../utils/handlers';

const { Text } = Typography;
const { TextArea } = Input;

const baseURI = 'catastro/construcciones/';

const URImerito = 'catalogos/motivos-de-merito-demerito-construccion/';

const Construcciones = ({
  selectedRowKey,
  callback,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [claveValuacion, setClaveValuacion] = useState([]);
  const [nivelesConstruccion, setNivelesConstruccion] = useState([]);
  const [tiposConstruccion, setTiposConstruccion] = useState([]);
  const [calidadesConstruccion, setCalidadesConstruccion] = useState([]);
  const [estadosConstruccion, setEstadosConstruccion] = useState([]);
  const [edadesConstruccion, setEdadesConstruccion] = useState([]);
  const [meritoDemerito, setMeritoDemerito] = useState([]);
  const [superficieTotal, setSuperficieTotal] = useState(0);
  const [valorTotalDeLasConstrucciones, setValorTotalDeLasConstrucciones] = useState(0);

  const fillValue = () => {
    const { uso_tipologia_construccion } = form.getFieldsValue();
    const found = claveValuacion.find((e) => e.id === uso_tipologia_construccion);
    if (found) {
      form.setFieldsValue({ valor_m2_de_construccion: found?.valor });
    }
  };

  const calcularValorCatastral = (nameProp, valor) => {
    // OBTENER EL ID DEL REGISTRO SELECCIONADO Y BUSCARLO EN EL ARREGLO DE DATOS
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);

    if (match) {
      // SUSTITUIR EL VALOR DE LA PROPIEDAD
      match[nameProp] = parseFloat(valor);

      // OBJETO DESTRUCTURING PARA EXTRAER LOS VALORES DEL OBJETO
      const {
        superficie_de_construccion,
        valor_m2_de_construccion,
        valor_merito_demerito,
      } = match;

      // CALCULAR EL VALOR CATASTRAL
      const valorCatastral = (
        (superficie_de_construccion || 1)
        * (valor_m2_de_construccion || 1)
        * (valor_merito_demerito || 1)
      );

      // ACTUALIZAR EL VALOR CATASTRAL EN EL FORMULARIO
      form.setFieldsValue({
        valor_catastral_de_la_construccion_calculado: toCurrency(valorCatastral, true),
      });
    } else {
      // SI NO SE ENCUENTRA EL REGISTRO EN EL ARREGLO DE DATOS, SE CALCULA EL VALOR CATASTRAL
      const objectCatastral = {
        superficie_de_construccion: parseFloat(form.getFieldValue('superficie_de_construccion')),
        valor_m2_de_construccion: parseFloat(form.getFieldValue('valor_m2_de_construccion')),
        valor_merito_demerito: parseFloat(form.getFieldValue('valor_merito_demerito')),
      };

      // SUSTITUIR EL VALOR DE LA PROPIEDAD
      objectCatastral[nameProp] = parseFloat(valor);

      // CALCULAR EL VALOR CATASTRAL
      const valorCatastral = (
        (objectCatastral.superficie_de_construccion || 1)
        * (objectCatastral.valor_m2_de_construccion || 1)
        * (objectCatastral.valor_merito_demerito || 1)
      );

      // ACTUALIZAR EL VALOR CATASTRAL EN EL FORMULARIO
      form.setFieldsValue({
        valor_catastral_de_la_construccion_calculado: toCurrency(parseFloat(valorCatastral), true),
      });
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const res = await API.get(baseURI, {
          params: {
            predio: selectedRowKey,
          },
        });
        if (res.data) {
          res.data = res.data.filter((e) => !e.tramite);
          let superficie = 0;
          let valor = 0;
          res.data.forEach((e) => {
            superficie += e.superficie_de_construccion;
            valor += e.valor_catastral_de_la_construccion;
          });
          setSuperficieTotal(superficie);
          setValorTotalDeLasConstrucciones(valor);
          callback(superficie, valor);
          setData(res.data);
        }
        const resMerito = await API.get(URImerito);
        if (resMerito.data) {
          setMeritoDemerito(resMerito.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);

        const resMeritoDemerito = await API.get('catalogos/motivos-de-merito-demerito-construccion/');
        setMeritoDemerito(resMeritoDemerito.data);

        const resEdadesConstruccion = await API.get('/catalogos/edades-de-las-construccion/');
        setEdadesConstruccion(resEdadesConstruccion.data);

        const resTipoConstruccion = await API.get('/catastro/tipos-de-construcciones/');
        setTiposConstruccion(resTipoConstruccion.data);

        const resCalidadConstruccion = await API.get('/catastro/calidades-de-construcciones/');
        setCalidadesConstruccion(resCalidadConstruccion.data);

        const resNivelConstruccion = await API.get('/catalogos/niveles-de-construccion/');
        setNivelesConstruccion(resNivelConstruccion.data);

        const resEstadosConstruccion = await API.get('/catastro/estados-de-construcciones/');
        setEstadosConstruccion(resEstadosConstruccion.data);

        const resClaveValuacion = await API.get('/catastro/catalogo-uso-tipo-construccion/');
        setClaveValuacion(resClaveValuacion.data);

        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        values.predio = selectedRowKey;
        values.valor_catastral_de_la_construccion = parseFloat(values.valor_catastral_de_la_construccion.toString().replace(/,/g, ''));
        values.valor_catastral_de_la_construccion_calculado = parseFloat(values.valor_catastral_de_la_construccion_calculado.toString().replace(/,/g, ''));
        if (!selectedRowKeys.length) {
          values.tramite = null;
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const res = await API.patch(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      if (match) {
        const {
          superficie_de_construccion,
          valor_m2_de_construccion,
          valor_merito_demerito,
        } = match;
        const valorCatastral = superficie_de_construccion * valor_m2_de_construccion * valor_merito_demerito;
        const construccionSelected = {
          id: match.id,
          clave_de_valuacion: match.clave_de_valuacion,
          nivel: match.nivel,
          merito_o_demerito: match.merito_o_demerito,
          tipo: match.tipo,
          calidad: match.calidad,
          estado: match.estado,
          edad: match.edad,
          superficie_de_construccion: match.superficie_de_construccion,
          valor_catastral_de_la_construccion_calculado: match.valor_catastral_de_la_construccion_calculado || toCurrency(parseFloat(valorCatastral), true),
          valor_catastral_de_la_construccion: toCurrency(match.valor_catastral_de_la_construccion, true),
        };
        form.setFieldsValue(construccionSelected);
      }
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          await fetchData();
          onCancel();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [required],
  };

  const columns = [
    {
      titleText: 'Clave valuación',
      key: 'uso_tipologia_construccion',
      dataIndex: 'uso_tipologia_construccion',
      width: 250,
      render: (val) => {
        const _claveValuacion = claveValuacion.find((e) => e.id === val);
        return `${_claveValuacion.clave} - ${_claveValuacion.descripcion}`;
      },
    },
    {
      titleText: 'Superficie',
      key: 'superficie_de_construccion',
      dataIndex: 'superficie_de_construccion',
      width: 250,
    },
    {
      titleText: 'Valor m2',
      key: 'valor_m2_de_construccion',
      dataIndex: 'valor_m2_de_construccion',
      width: 250,
      render: (val) => toCurrency(val || 0, true),
    },
    {
      titleText: 'Méritos/Deméritos',
      key: 'valor_merito_demerito',
      dataIndex: 'valor_merito_demerito',
      width: 250,
    },
    {
      titleText: 'Valor de construcción',
      key: 'valor_catastral_de_la_construccion',
      dataIndex: 'valor_catastral_de_la_construccion',
      width: 250,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading} style={{ width: '100%', height: '100%' }}>
      <Row gutter={10} style={{ marginTop: '15px' }}>
        <Col xs={24} sm={24} md={8}>
          <h1>
            <strong>Superficie total de construcciones: </strong>
            { toCurrency(superficieTotal || 0, true) }
          </h1>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <h1>
            <strong>Valor total de construcciones: </strong>
            { toCurrency(valorTotalDeLasConstrucciones || 0, true) }
          </h1>
        </Col>
      </Row>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowExport={false}
        allowSort={false}
        allowSearch={false}
        mobileColIndex={0}
      />
      <Modal
        open={visible}
        closable={false}
        footer={null}
        title={(
          <Row justify="space-between">
            {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} construcción`}
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
            />
          </Row>
        )}
        onCancel={onCancel}
        forceRender
      >
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            name="construcciones"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="uso_tipologia_construccion"
                  label="Clave valuación"
                  rules={rules.required}
                >
                  <Select
                    dataSource={claveValuacion.filter((e) => e.activo === true)}
                    keyLabelRender
                    onChange={fillValue}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <NumericInput
                  decimal
                  label="Superficie"
                  name="superficie_de_construccion"
                  rules={rules.required}
                  onChange={(e) => calcularValorCatastral('superficie_de_construccion', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <NumericInput
                  decimal
                  tooltip="Valor monetario de las construccion por metro cuadrado"
                  name="valor_m2_de_construccion"
                  rules={rules.required}
                  label="Valor por m2"
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <NumericInput
                  decimal
                  name="valor_merito_demerito"
                  label="Mérito/Demérito"
                  rules={rules.required}
                  onChange={(e) => calcularValorCatastral('valor_merito_demerito', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="motivo_merito_demerito_de_construccion"
                  label="Motivo del mérito"
                  rules={rules.required}
                >
                  <Select
                    dataSource={meritoDemerito}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="valor_catastral_de_la_construccion_calculado"
                  label="Valor calculado"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item label="Utilizar valor" style={{ justifyContent: 'center', textAlign: 'center' }}>
                  <Button
                    icon={<RightOutlined />}
                    onClick={() => {
                      form.setFieldsValue({ valor_catastral_de_la_construccion: form.getFieldValue('valor_catastral_de_la_construccion_calculado') });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="valor_catastral_de_la_construccion"
                  label="Valor de construcción"
                  rules={rules.required}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="observaciones"
                  label="Observaciones"
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 3 }} maxLength={250} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                {/* <Form.Item
                  name="anio_de_construccion"
                  label="Año de construcción"
                >
                  <Input />
                </Form.Item> */}
                <NumericInput
                  decimal
                  name="anio_de_construccion"
                  label="Año de construcción"
                  rules={rules.required}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="edad_de_la_construccion"
                  label="Edad de la construcción"
                >
                  <Select
                    dataSource={edadesConstruccion}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tipo_de_la_construccion"
                  label="Tipo de construcción"
                  rules={rules.required}
                >
                  <Select
                    dataSource={tiposConstruccion}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="calidad_de_la_construccion"
                  label="Calidad de la construcción"
                >
                  <Select
                    dataSource={calidadesConstruccion}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nivel_de_construccion"
                  label="Nivel de la construcción"
                >
                  <Select
                    dataSource={nivelesConstruccion}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="estado_de_la_construccion"
                  label="Estado de la construcción"
                >
                  <Select
                    dataSource={estadosConstruccion}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={(
          <>
            <Text style={{ fontSize: 16 }}>
              {`¿Desea eliminar la construcción con clave ${claveValuacion.find((e) => e.id === form.getFieldValue('uso_tipologia_construccion'))?.clave}?`}
            </Text>
            <Text>
              Solo será posible si no ha sido utilizado en otros procesos
            </Text>
          </>
        )}
      />
    </Spin>
  );
};

Construcciones.propTypes = {
  selectedRowKey: PropTypes.number,
  callback: PropTypes.func.isRequired,
};

Construcciones.defaultProps = {
  selectedRowKey: undefined,
};

export default Construcciones;
