/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  DashboardOutlined,
} from '@ant-design/icons';

// import Blank from './blank';
import configuracion from './configuracion';
import catalogos from './catalogos';
import predio from './predio';
// import Dashboard from '../views/Dashboard';
import Blank from './blank';

export const initialRoutes = [
  {
    title: 'Dashboard',
    icon: () => <DashboardOutlined />,
    showInSidebar: true,
    component: Blank,
    type: 'private',
  },
  predio,
  catalogos,
  configuracion,
];

const addPath = (_routes) => _routes.map((r) => {
  const path = (`/${r.title}`).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-').toLowerCase();
  if (r.children) {
    return {
      ...r,
      path,
      children: [...addPath(r.children)],
    };
  }
  return {
    ...r,
    path,
  };
});

export const routes = addPath(initialRoutes);

const flatFirstLevel = (_routes, parentPath = '') => _routes.map((r) => {
  if (r.children) {
    return flatFirstLevel(r.children, parentPath + r.path);
  }
  return {
    ...r,
    path: parentPath + r.path,
  };
});

export const flatRoutes = (_routes = []) => {
  let flattedRoutes = flatFirstLevel(_routes);
  while (flattedRoutes.find((r) => Array.isArray(r))) {
    flattedRoutes = flattedRoutes.flat();
  }
  return flattedRoutes;
};
