/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  Form,
  Input,
  Spin,
} from 'antd';

import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const ModalChangeStatus = ({
  onSubmit,
  onCancel,
  visible,
  authorize,
  onCancelText,
  onError,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (authorize) {
        await onSubmit();
      } else {
        await onSubmit(values);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const ModalControls = () => (
    <Row className="form-submit-controls">
      <Col xs={24} sm={24} md={0}>
        <Row>
          <Button
            type="link"
            onClick={onCancel}
          >
            <ArrowLeftOutlined />
          </Button>
          <Button
            type="link"
            onClick={() => onFinish()}
            className={`no-color ${authorize ? 'success' : 'danger'}`}
          >
            {authorize ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          </Button>
        </Row>
      </Col>
      <Col xs={0} sm={0} md={24}>
        <Row>
          <Button
            type="link"
            onClick={onCancel}
          >
            <ArrowLeftOutlined />
            {onCancelText}
          </Button>
          <Button
            type="link"
            onClick={() => onFinish()}
            style={{ marginLeft: 10 }}
            className={`no-color ${authorize ? 'success' : 'danger'}`}
          >
            {authorize ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
            {authorize ? 'Autorizar' : 'Cancelar'}
          </Button>
        </Row>
      </Col>
    </Row>
  );

  const rules = {
    observacion_de_la_cancelacion: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Modal
      title={(
        <Row justify="space-between">
          <Text>
            {authorize ? 'Autorización' : 'Cancelación'}
          </Text>
          <ModalControls />
        </Row>
        )}
      className="modal-delete"
      visible={visible}
      footer={null}
      closable={false}
    >
      <Spin
        tip="Cargando..."
        spinning={loading}
        style={{ width: '100%', height: '100%' }}
      >
        {authorize ? (
          <Typography.Text style={{ fontSize: 16 }}>
            ¿Desea Autorizar el registro?
          </Typography.Text>
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={() => onFinish()}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="observacion_de_la_cancelacion"
                  rules={rules.observacion_de_la_cancelacion}
                  hasFeedback
                  label="Observaciones"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item>
                  <Button htmlType="submit" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalChangeStatus;
