import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route,
} from 'react-router';

const AuthRoute = ({ type, path, children }) => {
  const auth = useSelector((store) => store.auth);
  if (type === 'guest' && (auth.isAuthenticated)) {
    return <Redirect to="/dashboard" />;
  } if (type === 'private' && (!auth.isAuthenticated)) {
    return <Redirect to="/inicio-de-sesion" />;
  }
  return (
    <Route path={path}>
      { children }
    </Route>
  );
};

AuthRoute.propTypes = {
  type: PropTypes.oneOf(['private', 'guest']),
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

AuthRoute.defaultProps = {
  type: 'guest',
};

export default AuthRoute;
