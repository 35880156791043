/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  SettingOutlined,
} from '@ant-design/icons';
import blank from './blank';
import Entidad from '../views/Configuracion/Entidad';
import Puestos from '../views/Configuracion/Puestos';
import Funcionarios from '../views/Configuracion/Funcionarios';
import Usuarios from '../views/Configuracion/Usuarios';
import Justificaciones from '../views/Configuracion/Justificaciones';
import Funciones from '../views/Configuracion/Funciones';
import Roles from '../views/Configuracion/Roles';

export default {
  title: 'Configuración',
  icon: () => <SettingOutlined />,
  showInSidebar: true,
  component: blank,
  type: 'private',
  children: [
    {
      title: 'Entidad',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Entidad',
          icon: null,
          showInSidebar: true,
          component: Entidad,
          type: 'private',
        },
        {
          title: 'Funcionarios',
          icon: null,
          showInSidebar: true,
          component: Funcionarios,
          type: 'private',
        },
        {
          title: 'Puestos',
          icon: null,
          showInSidebar: true,
          component: Puestos,
          type: 'private',
        },
      ],
    },
    {
      title: 'Accesos',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Usuarios',
          icon: null,
          showInSidebar: true,
          component: Usuarios,
          type: 'private',
        },
        {
          title: 'Roles',
          icon: null,
          showInSidebar: true,
          component: Roles,
          type: 'private',
        },
        {
          title: 'Justificaciones',
          icon: null,
          showInSidebar: true,
          component: Justificaciones,
          type: 'private',
        },
        {
          title: 'Funciones',
          icon: null,
          showInSidebar: true,
          component: Funciones,
          type: 'private',
        },
      ],
    },
  ],
};
