import { store } from 'store';

const { NODE_ENV } = process.env;

export const Debugger = () => {
  if (NODE_ENV === 'development') {
    // eslint-disable-next-line
    // debugger;
  }
};

export const logger = (...args) => {
  const isStaffUser = /sigob|migob/.test(store.getState().auth.user?.email);
  if (NODE_ENV === 'development' || isStaffUser) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};
